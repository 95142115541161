import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  header?: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
};

const CustomCard = (props: Props) => {
  const { header, body, footer, style = {} } = props;

  return (
    <View style={[styles.container, style]}>
      {header && <CardHeader header={header} />}
      {body && <CardBody body={body} />}
      {footer && <CardFooter footer={footer} />}
    </View>
  );
};

const CardHeader = ({ header }: { header: JSX.Element }) => {
  return <View style={styles.header}>{header}</View>;
};

const CardBody = ({ body }: { body: JSX.Element }) => {
  return <View style={styles.body}>{body}</View>;
};

const CardFooter = ({ footer }: { footer: JSX.Element }) => {
  return <View style={styles.footer}>{footer}</View>;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflow: 'hidden',
  },
  header: {},
  body: {
    // padding: 5,
  },
  footer: {
    marginTop: 5,
  },
  darkMode: {
    shadowColor: '#FFFFFF',
  },
  lightMode: {
    shadowColor: '#000000',
  },
});

export default CustomCard;
