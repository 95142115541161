import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Image } from 'react-native';
import styled from 'styled-components/native';
import * as yup from 'yup';
import { useLoginFromAuthControllerMutation } from '../api/auth';
import CustomContainer from '../components/Basic/CustomContainer';
import PasswordInput from '../components/PasswordInput';
import {
  ThemedButton,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { usePopup } from '../hooks/usePopup';
import useTheme from '../hooks/useTheme';
import { percentageHeight } from '../utils';

interface LoginCredentials {
  username: string;
  password: string;
  person: string;
}

const LoginMainSection = styled.View`
  height: ${percentageHeight(90)}px;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  display: flex;
  padding: ${percentageHeight(5)}px;
  background-color: ${(props) => props.theme.mainContainer};
  overflow: auto;
`;

const LoginButton = styled(ThemedButton)`
  margin-top: ${percentageHeight(5)}px;
  margin-bottom: ${percentageHeight(10)}px;
`;

const LoginForm = styled.View`
  /* margin-top: 5vh; */
`;

const LoginFooter = styled.View`
  height: ${percentageHeight(10)}px;
  padding: ${percentageHeight(5)}px;
  align-items: center;
  justify-content: center;
`;

const SignUpButton = styled(Link)`
  color: white;
`;

const LogoSection = styled.View`
  margin-top: ${percentageHeight(2)}px;
  display: flex;
  align-items: center;
`;

const ForgotPassword = styled.View`
  display: flex;
  align-items: flex-end;
  margin-top: ${percentageHeight(2)}px;
`;

const LoginUsernameInput = styled(ThemedTextInput).attrs((props) => ({
  containerStyle: {
    // @ts-ignore
    ...props.containerStyle,
    marginBottom: 20,
    marginTop: 20,
  },
}))`` as typeof ThemedTextInput;

const LoginPasswordInput = styled(PasswordInput).attrs((props) => ({
  containerStyle: {
    // @ts-ignore
    ...props.containerStyle,
    marginTop: 20,
  },
}))`` as typeof PasswordInput;

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export default function Login() {
  const { palette } = useTheme();
  const { showToast } = usePopup();

  const [loginUser, { error }] = useLoginFromAuthControllerMutation();

  useEffect(() => {
    if (error) {
      showToast({
        text: 'Username or password is invalid',
        duration: 5 * 1000,
        buttonText: 'Ok',
      });
    }
  }, [error]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>({
    defaultValues: {
      username: '',
      password: '',
      person: '',
    },
    resolver: yupResolver(schema),
  });

  const doLogin = async (values: LoginCredentials) => {
    loginUser({
      username: values.username,
      password: values.password,
    });
  };

  return (
    <CustomContainer style={{ backgroundColor: palette.primary }}>
      <LoginMainSection>
        <LogoSection>
          <Image
            style={{
              height: 200,
              width: 200,
              borderRadius: 40,
              overflow: 'hidden',
              // border: '1px solid white',
            }}
            source={require('../assets/images/logo.png')}
          />
        </LogoSection>
        <LoginForm>
          <LoginUsernameInput
            label={'Username'}
            name={'username'}
            control={control}
            testId={'usernameLoginField'}
            error={errors.username?.message}
          />

          <LoginPasswordInput
            label={'Password'}
            name={'password'}
            control={control}
            testId={'passwordLoginField'}
            error={errors.password?.message}
          />
          <ForgotPassword>
            <ThemedText note>
              <Link to={{ screen: 'Auth', params: { screen: 'Register' } }}>
                Forgot your password?
              </Link>
            </ThemedText>
          </ForgotPassword>
          <LoginButton testId={'loginButton'} onPress={handleSubmit(doLogin)}>
            <ThemedText>Login</ThemedText>
          </LoginButton>
        </LoginForm>
      </LoginMainSection>

      <LoginFooter>
        <ThemedText color={'whitesmoke'}>
          Don&apos;t have an account?{' '}
          <SignUpButton
            testID={'goTosignUpButton'}
            to={{ screen: 'Auth', params: { screen: 'Register' } }}
          >
            Sign Up
          </SignUpButton>
        </ThemedText>
      </LoginFooter>
    </CustomContainer>
  );
}
