import moment from 'moment';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { PostDto } from '../../api/post';
import CustomIcon from '../Basic/CustomIcon';
import { ThemedImage, ThemedText } from '../Themed';

const Container = styled.View`
  padding: 10px;
  background-color: transparent;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SenderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  background: transparent;
`;

const SenderAvatar = styled(ThemedImage).attrs((props) => ({
  containerStyle: {
    marginRight: 10,
  },
}))``;

const TransparentView = styled.View`
  background: transparent;
`;

const OptionsIcon = styled(CustomIcon)`
  color: gray;
`;

export default function PostHeader({
  post,
  onAvatarClick,
  onShowPostActions,
}: {
  post: PostDto;
  onAvatarClick: () => void;
  onShowPostActions: () => void;
}) {
  return (
    <Container>
      <SenderContainer>
        {post.sender.avatar ? (
          <TouchableOpacity onPress={onAvatarClick}>
            <SenderAvatar
              avatar
              dimensions={{ height: 50, width: 50 }}
              uri={post.sender.avatar}
            />
          </TouchableOpacity>
        ) : null}
        <TransparentView>
          <ThemedText>{post.sender.username}</ThemedText>
          <ThemedText note>
            {moment(new Date(post.date!).getTime()).fromNow()}
          </ThemedText>
        </TransparentView>
      </SenderContainer>
      <TransparentView>
        <TouchableOpacity onPress={onShowPostActions}>
          <OptionsIcon type="Entypo" size={15} name="dots-three-vertical" />
        </TouchableOpacity>
      </TransparentView>
    </Container>
  );
}
