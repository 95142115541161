import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationApi, NotificationDto } from '../../api/notification';

const initialState: NotificationDto[] = [];

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotifications() {
      return initialState;
    },
    addNotification(state, action: PayloadAction<NotificationDto>) {
      state.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationApi.endpoints.findAllFromNotificationController
        .matchFulfilled,
      (state, { payload }) => {
        return payload;
      }
    );
    builder.addMatcher(
      notificationApi.endpoints.markAsReadFromNotificationController
        .matchFulfilled,
      (state, { payload }) => {
        const notification = state.find(
          (notification) => notification.uuid === payload.uuid
        );
        if (!notification) {
          return;
        }

        notification.read = true;
      }
    );
    builder.addMatcher(
      notificationApi.endpoints.markAsUnreadFromNotificationController
        .matchFulfilled,
      (state, { payload }) => {
        const notification = state.find(
          (notification) => notification.uuid === payload.uuid
        );
        if (!notification) {
          return;
        }

        notification.read = false;
      }
    );
  },
});

export const { addNotification, clearNotifications } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
