import { useState, useEffect } from 'react';
import * as Location from 'expo-location';

export function useGeolocation() {
  const [location, setLocation] = useState<
    Location.LocationObject | undefined
  >();

  useEffect(() => {
    (async () => {
      const { status } = await Location.requestBackgroundPermissionsAsync();
      if (status !== 'granted') {
        console.log('Permission to access location was denied');
        return;
      }

      const location = await Location.getCurrentPositionAsync({});

      setLocation(location);
    })();
  }, []);

  return {
    location,
  };
}
