import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import {
  Actions,
  ActionsProps,
  Bubble,
  BubbleProps,
  ComposerProps,
  GiftedChat,
  IMessage,
  InputToolbar,
  InputToolbarProps,
  MessageImage,
  MessageImageProps,
  MessageVideoProps,
  SendProps,
  Time,
  TimeProps,
  User as RNGifterChatUser,
} from 'react-native-gifted-chat';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { MessageDto } from '../api/chat';
import { CommentDto } from '../api/comment';
import { useLazyFindOneFromUserControllerQuery } from '../api/user';
import { useAppSelector } from '../hooks/store';
import { usePopup } from '../hooks/usePopup';
import useTheme from '../hooks/useTheme';
import ActionSheet, { ActionSheetAction } from './Basic/ActionSheet';
import Giphy from './Giphy';
import {
  ThemedButton,
  ThemedIcon,
  ThemedImage,
  ThemedTextInput,
} from './Themed';
import VideoPlayer from './VideoPlayer';

export interface MessageDetailsDto {
  text?: string;
  photo?: object;
  video?: object;
}

export type ChatParams = {
  messages: Array<MessageDto | CommentDto>;
  onMessageSend: (message: MessageDetailsDto) => void;
};

interface ImageDetails {
  uri: string;
  name: string;
  type: string;
}

const ImageView = styled.View`
  position: relative;
`;

const VideoView = styled.View`
  position: relative;
`;

const CloseIcon = styled(ThemedButton).attrs(() => ({
  style: { borderRadius: 20, overflow: 'hidden' },
}))`
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 10px;
`;

export const Chat = ({ messages, onMessageSend }: ChatParams) => {
  const [giftedChatMessages, setGiftedChatMessages] = useState<IMessage[]>([]);
  const [image, setImage] = useState<ImageDetails | null>(null);
  const [video, setVideo] = useState<ImageDetails | null>(null);
  const { showPopup, showToast, hidePopup } = usePopup();
  const { uuid: selfUuid } = useAppSelector((state) => state.Auth);
  const [getUser] = useLazyFindOneFromUserControllerQuery();
  const navigation = useNavigation();

  const { palette } = useTheme();

  useEffect(() => {
    if (Platform.OS === 'web') {
      const gcLoadingContaineEl = document.querySelectorAll(
        '[data-testid="GC_LOADING_CONTAINER"]'
      )[0] as HTMLElement;
      if (gcLoadingContaineEl) {
        gcLoadingContaineEl.style.display = 'none';
        setTimeout(() => {
          gcLoadingContaineEl.style.display = 'flex';
        }, 500);
      }
    }

    // Add this somewhere in your code, for example when fetching messages or `useEffect` with 10 secs delayed
  }, []);

  useEffect(() => {
    const giftedChatMessages: IMessage[] = messages.map((message) => ({
      _id: message.uuid,
      text: message.text ?? '',
      createdAt: new Date(message.date),
      image: message.photo ?? undefined,
      video: message.video ?? undefined,
      user: {
        _id: message.sender.uuid,
        name: message.sender.username,
        avatar: message.sender.avatar,
      },
    }));

    setGiftedChatMessages(giftedChatMessages);
  }, [messages]);

  const onSend = useCallback(
    (chatMessages: IMessage[]) => {
      const [{ text }] = chatMessages;

      const newMessage: MessageDetailsDto = {
        photo: image ?? undefined,
        video: video ?? undefined,
        text: text,
      };

      onMessageSend(newMessage);

      setImage(null);
      setVideo(null);
    },
    [messages, image, video]
  );

  const renderBubble = (
    props: Readonly<BubbleProps<IMessage>> &
      Readonly<{ children?: React.ReactNode }>
  ) => {
    return (
      <Bubble
        {...props}
        textStyle={{
          left: {
            fontFamily: 'BalsamiqSans_400Regular',
            fontSize: 13,
            textAlign: 'left',
            marginBottom: 0,
          },
          right: {
            fontFamily: 'BalsamiqSans_400Regular',
            fontSize: 13,
            color: 'whitesmoke',
            textAlign: 'right',
            marginBottom: 0,
            marginRight: 5,
          },
        }}
        wrapperStyle={{
          left: {
            backgroundColor: '#ffffff',
            borderRadius: 15,
            padding: 3,
          },
          right: {
            backgroundColor: palette.primary,
            borderRadius: 15,
            padding: 3,
          },
        }}
        renderTime={(
          timeProps: Readonly<TimeProps<IMessage>> &
            Readonly<{ children?: React.ReactNode }>
        ) => (
          <Time
            {...timeProps}
            timeTextStyle={{
              right: { color: 'lightgrey' },
            }}
          />
        )}
      />
    );
  };

  const renderMessageImage = (
    props: Readonly<MessageImageProps<IMessage>> &
      Readonly<{ children?: React.ReactNode }>
  ) => {
    return <MessageImage {...props} />;
  };

  const showActions = () => {
    const actions: ActionSheetAction[] = [
      {
        text: 'Photo',
        onPress: chooseImage,
      },
      {
        text: 'Video',
        onPress: chooseVideo,
      },
      {
        text: 'Gif',
        onPress: chooseGif,
      },
    ];

    showPopup({
      style: { inverted: true, width: '100%' },
      Component: <ActionSheet showCancel actions={actions} />,
      animated: true,
      animation: 'slide',
    });
  };

  const handleAvatarPress = (user: RNGifterChatUser) => {
    const isSelfUser = user._id === selfUuid;
    if (isSelfUser) {
      return;
    }

    getUser(user._id as string).then(({ data: user }) => {
      if (!user) {
        showToast({ type: 'danger', text: 'User not found' });
        return;
      }

      navigation.navigate('LoggedIn', {
        screen: 'UserProfile',
        params: {
          user,
        },
      });
    });
  };

  const renderActions = (
    props: Readonly<ActionsProps> & Readonly<{ children?: ReactNode }>
  ) => {
    return (
      <Actions
        {...props}
        containerStyle={{
          width: 50,
          flexDirection: 'row',
          justifyContent: 'center',
          margin: 0,
        }}
        icon={() => <ThemedIcon type="FontAwesome" name={'photo'} size={20} />}
        onPressActionButton={showActions}
      />
    );
  };

  const chooseImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled && result.assets[0].uri) {
      setImage({
        uri: result.assets[0].uri,
        name: 'SomeImageName.jpg',
        type: 'image/jpg',
      });
    }

    hidePopup();
  };

  const chooseVideo = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
    });

    if (!result.canceled && result.assets[0].uri) {
      if (!result.assets[0].uri.startsWith('data:video/mp4')) {
        showToast({ type: 'danger', text: 'Only .mp4 video is supported' });
        return;
      }

      setVideo({
        uri: result.assets[0].uri,
        name: 'SomeImageName.mp4',
        type: 'video/mp4',
      });
    }
    hidePopup();
  };

  const chooseGif = () => {
    showPopup({
      Component: (
        <Giphy
          onSelect={(gif) => {
            setImage({
              type: 'image/gif',
              name: 'mygif',
              uri: gif.images.original.url,
            });
            hidePopup();
          }}
        />
      ),
      style: {
        inverted: true,
        height: '50%',
      },
      animated: true,
      animation: 'slide',
    });
  };

  const removePhoto = () => {
    setImage(null);
  };

  const removeVideo = () => {
    setVideo(null);
  };

  const renderComposer = (
    props: Readonly<ComposerProps> &
      Readonly<{
        children?: React.ReactNode;
      }>
  ): ReactNode => {
    const { palette } = useTheme();
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: palette.inputContainer,
          margin: 5,
          padding: 5,
          borderRadius: 15,
        }}
      >
        <ThemedTextInput
          standalone
          multiline={true}
          value={props.text ?? ''}
          onChange={props.onTextChanged}
          placeholder={'Type a message'}
        />
        {/* <Composer
          {...props}
          textInputStyle={{
            color: palette.text,
            fontFamily: palette.fontFamily,
            marginHorizontal: 0,
            marginVertical: 5,
            paddingVertical: 5,
          }}
        /> */}

        {image && (
          <ImageView>
            <ThemedImage uri={image.uri} dimensions={{ maxHeight: 100 }} />
            <CloseIcon full={false} onPress={removePhoto}>
              <Ionicons name={'close'} color={'white'} />
            </CloseIcon>
          </ImageView>
        )}
        {video && (
          <VideoView>
            <VideoPlayer
              uri={video.uri}
              containerStyle={{ borderRadius: 15, overflow: 'hidden' }}
            />
            <CloseIcon full={false} onPress={removeVideo}>
              <Ionicons name={'close'} color={'white'} />
            </CloseIcon>
          </VideoView>
        )}
      </View>
    );
  };

  const renderSend = (
    props: Readonly<SendProps<IMessage>> &
      Readonly<{
        children?: React.ReactNode;
      }>
  ) => {
    const disabled = !image && !props.text && !video;

    return (
      <ThemedButton
        onPress={() =>
          props.onSend && props.onSend([{ text: props.text }], true)
        }
        full={false}
        transparent
        disabled={disabled}
      >
        <ThemedIcon name={'send'} type={'FontAwesome'} />
      </ThemedButton>
    );
  };

  const renderFooter = () => {
    return <View style={{ marginBottom: 50 }}></View>;
  };

  const renderInputToolbar = (props: InputToolbarProps<any>) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          backgroundColor: palette.mainContainer,
          justifyContent: 'center',
          paddingVertical: 5,
          margin: 0,
        }}
        primaryStyle={{
          alignItems: 'center',
        }}
      />
    );
  };

  const renderMessageVideo = (
    props: Readonly<MessageVideoProps<IMessage>> &
      Readonly<{ children?: React.ReactNode }>
  ) => {
    if (!props.currentMessage?.video) {
      return null;
    }
    return (
      <VideoPlayer
        containerStyle={{ borderRadius: 15, overflow: 'hidden' }}
        dimensions={{ width: 300 }}
        uri={props.currentMessage?.video}
      />
    );
  };

  return (
    <SafeAreaView edges={['bottom']} mode={'padding'} style={{ flex: 1 }}>
      <GiftedChat
        lightboxProps={{ backgroundColor: '#222222ed' }}
        onPressAvatar={handleAvatarPress}
        renderInputToolbar={renderInputToolbar}
        renderActions={renderActions}
        renderFooter={renderFooter}
        renderMessageImage={renderMessageImage}
        renderMessageVideo={renderMessageVideo}
        renderBubble={renderBubble}
        messagesContainerStyle={{
          borderTopWidth: 0,
        }}
        renderComposer={renderComposer}
        alwaysShowSend={true}
        scrollToBottom
        showUserAvatar
        renderSend={renderSend}
        messages={giftedChatMessages}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: selfUuid,
        }}
      />
    </SafeAreaView>
  );
};

export default Chat;
