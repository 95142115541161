import { createSlice } from '@reduxjs/toolkit';
import { followerApi, UserFollows } from '../../api/follower';

const initialState: UserFollows = {
  followers: [],
  following: [],
};

const FollowerSlice = createSlice({
  name: 'followers',
  initialState: initialState,
  reducers: {
    clearFollowers() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      followerApi.endpoints.findAllFromFollowerController.matchFulfilled,
      (state, { payload }) => {
        return payload;
      }
    );
    builder.addMatcher(
      followerApi.endpoints.findAllFollowersFromFollowerController
        .matchFulfilled,
      (state, { payload }) => {
        state.followers = payload;
      }
    );
    builder.addMatcher(
      followerApi.endpoints.findAllFollowingFromFollowerController
        .matchFulfilled,
      (state, { payload }) => {
        state.following = payload;
      }
    );
    builder.addMatcher(
      followerApi.endpoints.createFromFollowerController.matchFulfilled,
      (state, { payload }) => {
        state.following = [payload, ...state.following];
      }
    );
    builder.addMatcher(
      followerApi.endpoints.removeFollowerFromFollowerController.matchFulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { originalArgs },
          },
        }
      ) => {
        const followerIndex = state.following.findIndex(
          (f) => f.uuid === originalArgs
        );
        state.followers.splice(followerIndex, 1);
      }
    );
    builder.addMatcher(
      followerApi.endpoints.unfollowFromFollowerController.matchFulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { originalArgs },
          },
        }
      ) => {
        const followeeIndex = state.following.findIndex(
          (f) => f.uuid === originalArgs
        );
        state.following.splice(followeeIndex, 1);
      }
    );
  },
});

export const { clearFollowers } = FollowerSlice.actions;
export default FollowerSlice.reducer;
