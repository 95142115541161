import React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import CustomText from './CustomText';

export interface CustomButtonProps {
  disabled?: boolean;
  transparent?: boolean;
  rounded?: boolean;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
  full?: boolean;
  outline?: boolean;
  activeOpacity?: number;
  children?: React.ReactChild | React.ReactChild[];
  title?: string;
  loading?: boolean;
  leftIcon?: () => void;
  rightIcon?: () => void;
  testId?: string;
}

export default function CustomButton(props: CustomButtonProps) {
  const {
    children,
    disabled = false,
    title,
    rounded = false,
    transparent = false,
    outline = false,
    style = {},
    testId,
    titleStyle,
    onPress,
    full = false,
    activeOpacity = 0.8,
    loading = false,
  } = props;

  return (
    <TouchableOpacity
      testID={testId}
      activeOpacity={activeOpacity}
      onPress={onPress}
      disabled={disabled || loading}
      style={[
        disabled || loading ? styles.disabled : null,
        styles.default,
        rounded ? styles.rounded : null,
        full ? styles.full : null,
        outline ? styles.outline : null,
        style,
        transparent ? styles.transparent : null,
      ]}
    >
      <>
        {props.leftIcon && props.leftIcon()}
        {title && <CustomText style={titleStyle}>{title}</CustomText>}
        {children && children}
        {loading && (
          <ActivityIndicator
            style={styles.spinner}
            size={'small'}
            color={'grey'}
          />
        )}
        {props.rightIcon && props.rightIcon()}
      </>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  default: {
    backgroundColor: '#9C27B0',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  spinner: { marginLeft: 10 },
  rounded: { borderRadius: 5 },
  outline: { borderWidth: 0.5 },
  full: { width: '100%' },
  transparent: { backgroundColor: 'transparent' },
  disabled: { opacity: 0.5 },
});
