import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Notifications from '../components/Notifications';
import { ThemedIcon } from '../components/Themed';

export default function HeaderRight() {
  const navigation = useNavigation();

  const goToSetings = () => {
    navigation.navigate('LoggedIn', { screen: 'Settings' });
  };

  const goToBlockedUsers = () => {
    navigation.navigate('LoggedIn', { screen: 'BlockedUsers' });
  };

  const goToAi = () => {
    navigation.navigate('LoggedIn', { screen: 'AI' });
  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Notifications />
      <TouchableOpacity onPress={goToAi}>
        <ThemedIcon
          style={{ marginRight: 15 }}
          size={20}
          type="Fontisto"
          name="electronjs"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={goToBlockedUsers}>
        <ThemedIcon
          style={{ marginRight: 15 }}
          size={20}
          type="MaterialIcons"
          name="block"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={goToSetings}>
        <ThemedIcon
          style={{ marginRight: 15 }}
          size={20}
          name="settings"
          type={'Feather'}
        />
      </TouchableOpacity>
    </View>
  );
}
