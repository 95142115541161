import React, { Fragment, useEffect, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Modal,
  Platform,
  StyleProp,
  Text,
  TextInput,
  TouchableHighlight,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import CustomView from './CustomView';
import CustomText from './CustomText';
import CustomIcon from './CustomIcon';
import CustomButton from './CustomButton';

interface BasicSelectProps<T> {
  fieldName: keyof T;
  data: T[];
  valueField: keyof T;
  style?: StyleProp<ViewStyle>;
  renderButton?: (showModal: () => void) => void;
  modalTitle?: string;
  search?: boolean;
  darkMode?: boolean;
}

interface SingleSelectProps<T> extends BasicSelectProps<T> {
  multiple?: false;
  onSelect: (val: T) => void;
  selected?: T;
}

interface MultiSelectProps<T> extends BasicSelectProps<T> {
  multiple: true;
  onSelect: (val: T[]) => void;
  selected?: T[];
}

type SelectProps<T> = SingleSelectProps<T> | MultiSelectProps<T>;

export default function CustomSelect<T>(props: SelectProps<T>) {
  const {
    multiple,
    fieldName,
    data,
    valueField,
    onSelect,
    selected,
    style,
    renderButton,
    modalTitle,
    search,
    darkMode,
  } = props;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
  const [options, setOptions] = useState<T[]>([]);

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  const closeModal = () => {
    if (props.multiple) {
      props.onSelect(selectedOptions);
    }
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
    setOptions(data);
  };

  const selectItem = (item: any) => {
    onSelect(item);
    closeModal();
  };

  const filterData = (letter: string) => {
    if (letter == '') setOptions(data);
    else {
      const newOptions = data.filter((option) => {
        return (
          (option[fieldName] as any)
            .toLowerCase()
            .indexOf(letter.toLowerCase()) != -1
        );
      });
      setOptions(newOptions);
    }
  };

  const checkOption = (option: T) => {
    const selected = [...selectedOptions];
    const optionSelectedIndex = selected.findIndex(
      (s) => s[valueField] === option[valueField]
    );
    if (optionSelectedIndex !== -1) {
      selected.splice(optionSelectedIndex, 1);
    } else {
      selected.push(option);
    }
    setSelectedOptions(selected);
  };

  const renderItem = (row: ListRenderItemInfo<T>) => {
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        style={{
          flexDirection: 'row',
          padding: 10,
          alignItems: 'center',
          flex: 1,
          borderBottomWidth: 0.2,
          borderBottomColor: 'lightgrey',
        }}
        onPress={() => {
          if (!multiple) selectItem(row.item);
          else checkOption(row.item);
        }}
      >
        <CustomView style={{ padding: 15 }}>
          {!props.multiple &&
          props.selected &&
          row.item[valueField] == props.selected[valueField] ? (
            <Text style={{ color: 'purple', fontWeight: 'bold' }}>
              {/* TODO: fix me */}
              {/* @ts-ignore */}
              {row.item[fieldName]}
            </Text>
          ) : (
            <CustomText>{row.item[fieldName] as unknown as string}</CustomText>
          )}
        </CustomView>
      </TouchableOpacity>
    );
  };

  return (
    <View style={[style]}>
      <>
        {renderButton && renderButton(showModal)}
        <View
          style={{
            backgroundColor: 'transparent',
            flex: 1,
            position: 'absolute',
          }}
        >
          <Modal transparent onRequestClose={closeModal} visible={modalVisible}>
            <TouchableHighlight
              onPress={closeModal}
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#111111cc',
              }}
            >
              <TouchableWithoutFeedback onPress={() => {}}>
                <View
                  style={{
                    width: '80%',
                    height: '80%',
                    overflow: 'hidden',
                    borderRadius: 10,
                  }}
                >
                  <CustomView
                    style={{
                      height: !search ? 120 : 100,
                      flexDirection: 'row',
                      padding: !search ? 20 : 15,
                      alignItems: 'center',
                      paddingVertical: 10,
                      justifyContent: 'space-between',
                    }}
                  >
                    {search ? (
                      <Fragment>
                        <View
                          style={{
                            borderColor: 'lightgrey',
                            borderWidth: 0.2,
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderRadius: 10,
                            flex: 1,
                          }}
                        >
                          <CustomButton
                            style={{ alignItems: 'center', width: '15%' }}
                            transparent
                            onPress={() => {}}
                          >
                            <CustomIcon
                              type="Fontisto"
                              size={25}
                              name="search"
                            />
                          </CustomButton>
                          <TextInput
                            onChangeText={filterData}
                            placeholderTextColor="darkgrey"
                            placeholder="Search"
                            style={[
                              {
                                flex: 1,
                                paddingLeft: 10,
                                padding: 10,
                                color: darkMode ? '#eeeeee' : '#222222',
                              },
                              // @ts-ignore
                              Platform.OS === 'web' && { outlineWidth: 0 },
                            ]}
                          />
                        </View>
                        {multiple && (
                          <CustomButton
                            style={{ alignItems: 'center', width: '15%' }}
                            transparent
                            onPress={closeModal}
                          >
                            <CustomIcon
                              type="FontAwesome"
                              size={25}
                              name="check"
                            />
                          </CustomButton>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <CustomText style={{ maxWidth: 200 }} numberOfLines={2}>
                          {modalTitle}
                        </CustomText>
                        {!search ? (
                          <CustomButton transparent onPress={closeModal}>
                            <CustomIcon
                              type="FontAwesome"
                              size={30}
                              name="check"
                            />
                          </CustomButton>
                        ) : null}
                      </Fragment>
                    )}
                  </CustomView>
                  <CustomView style={{ flex: 1 }}>
                    <FlatList
                      keyExtractor={(item, index) => index.toString()}
                      data={options}
                      renderItem={renderItem}
                    />
                  </CustomView>
                </View>
              </TouchableWithoutFeedback>
            </TouchableHighlight>
          </Modal>
        </View>
      </>
    </View>
  );
}
