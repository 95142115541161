import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useFocusEffect, useNavigation } from '@react-navigation/core';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback, useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { useFindAllFromFollowerControllerQuery } from '../api/follower';
import {
  UpdateFromUserControllerApiArg,
  UpdateUserRequestDto,
  useUpdateFromUserControllerMutation,
} from '../api/user';
import ActionSheet, {
  ActionSheetAction,
} from '../components/Basic/ActionSheet';
import CustomImage from '../components/Basic/CustomImage';
import PageLoading from '../components/Basic/PageLoading';
import {
  ThemedButton,
  ThemedContainer,
  ThemedIcon,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import { usePopup } from '../hooks/usePopup';
import { clearStore } from '../store';
import { objectToFormData, percentageHeight } from '../utils';

const AvatarContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileInput = styled(ThemedTextInput).attrs((attrs) => ({
  ...attrs,
  containerStyle: {
    ...(attrs.containerStyle as object),
    marginTop: 20,
  },
}))`` as typeof ThemedTextInput;

const LogoutButton = styled(ThemedButton).attrs(() => ({
  transparent: true,
}))`
  margin-top: ${percentageHeight(5)};
`;

export default function Profile() {
  const navigation = useNavigation();
  const { showPopup } = usePopup();

  const authUser = useAppSelector((state) => state.Auth);

  const { followers, following } = useAppSelector((state) => state.Follows);

  const [updateUserApi, { isSuccess: profileUpdated }] =
    useUpdateFromUserControllerMutation();

  const { refetch: fetchFollowers } = useFindAllFromFollowerControllerQuery();

  const { control, handleSubmit, watch, setValue, reset } =
    useForm<UpdateFromUserControllerApiArg>({
      defaultValues: {
        description: authUser?.description,
        avatar: authUser?.avatar as unknown as object,
      },
    });
  const { isDirty } = useFormState({ control });
  const { avatar } = watch();

  const chooseImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled && result.assets[0]?.uri) {
      setValue('avatar', result.assets[0]?.uri as unknown as object, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    if (isDirty) {
      navigation.setOptions({
        headerRight: () => (
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <ThemedButton transparent full={false} onPress={() => reset()}>
              <ThemedIcon
                size={24}
                name={'close-circle-outline'}
                type="Ionicons"
              />
            </ThemedButton>
            <ThemedButton
              full={false}
              transparent
              onPress={handleSubmit(submitProfile)}
              style={{ backgroundColor: '#e0e7ff' }}
              rounded
            >
              <ThemedIcon size={24} type="Ionicons" name="checkmark-circle" />
            </ThemedButton>
          </View>
        ),
      });
    } else {
      navigation.setOptions({
        headerRight: null,
      });
    }
  }, [isDirty]);

  useEffect(() => {
    if (profileUpdated) {
      navigation.setOptions({
        headerRight: null,
      });
    }
  }, [profileUpdated]);

  const viewFollowers = () => {
    if (!authUser) {
      return;
    }
    navigation.navigate('LoggedIn', {
      screen: 'FollowersList',
      params: {
        userUuid: authUser?.uuid,
        type: 'followers',
      },
    });
  };

  const viewFollowees = () => {
    if (!authUser) {
      return;
    }
    navigation.navigate('LoggedIn', {
      screen: 'FollowersList',
      params: {
        userUuid: authUser?.uuid,
        type: 'following',
      },
    });
  };

  const getPhoto = async (uri: string): Promise<Blob> => {
    const data = await fetch(uri);
    const blob = await data.blob();
    return blob;
  };

  useFocusEffect(
    useCallback(() => {
      fetchFollowers();
      reset();
    }, [])
  );

  const submitProfile = async (values: UpdateUserRequestDto) => {
    const profileData: UpdateUserRequestDto = {
      avatar: await getPhoto(values.avatar as unknown as string),
      description: values.description,
    };

    // @ts-ignore
    updateUserApi(objectToFormData(profileData));
  };

  const logout = () => {
    const logoutActions: ActionSheetAction[] = [
      {
        text: 'Logout',
        onPress: clearStore,
        onPressClose: true,
      },
    ];

    showPopup({
      style: { inverted: true, width: '100%' },
      Component: <ActionSheet actions={logoutActions} />,
      animated: true,
      animation: 'slide',
    });
    // clearStore();
  };

  const tabBarHeight = useBottomTabBarHeight();

  if (!authUser) {
    return <PageLoading />;
  }

  const { username, email } = authUser;

  return (
    <ThemedContainer style={{ paddingBottom: tabBarHeight }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TouchableOpacity
          onPress={viewFollowers}
          style={{ alignItems: 'center', padding: 10 }}
        >
          <ThemedText>Followers</ThemedText>
          <ThemedText note>{followers.length}</ThemedText>
        </TouchableOpacity>
        {avatar ? (
          <AvatarContainer>
            <CustomImage avatar large uri={avatar as unknown as string} />
            <TouchableOpacity onPress={chooseImage} style={{ marginTop: 10 }}>
              <ThemedText note>Edit avatar</ThemedText>
            </TouchableOpacity>
          </AvatarContainer>
        ) : null}
        <TouchableOpacity
          onPress={viewFollowees}
          style={{ alignItems: 'center', padding: 10 }}
        >
          <ThemedText>Following</ThemedText>
          <ThemedText note>{following.length}</ThemedText>
        </TouchableOpacity>
      </View>

      <ProfileInput label={'Username'} standalone value={username} disabled />
      <ProfileInput label={'Email'} standalone value={email} disabled />

      <ProfileInput
        label={'Description'}
        placeholder={'Description'}
        name={'description'}
        control={control}
        numberOfLines={5}
      />

      <LogoutButton onPress={logout}>
        <ThemedText>Logout</ThemedText>
      </LogoutButton>
    </ThemedContainer>
  );
}
