import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFromChatController: build.mutation<
      CreateFromChatControllerApiResponse,
      CreateFromChatControllerApiArg
    >({
      query: (queryArg) => ({ url: `/chat`, method: 'POST', body: queryArg }),
    }),
    findByUserIdFromChatController: build.query<
      FindByUserIdFromChatControllerApiResponse,
      FindByUserIdFromChatControllerApiArg
    >({
      query: () => ({ url: `/chat` }),
    }),
    createMessageFromChatController: build.mutation<
      CreateMessageFromChatControllerApiResponse,
      CreateMessageFromChatControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/message`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    findByChatUuidFromChatController: build.query<
      FindByChatUuidFromChatControllerApiResponse,
      FindByChatUuidFromChatControllerApiArg
    >({
      query: (queryArg) => ({ url: `/chat/message/${queryArg}` }),
    }),
    blockUserFromChatController: build.mutation<
      BlockUserFromChatControllerApiResponse,
      BlockUserFromChatControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/block-user`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    unBlockUserFromChatController: build.mutation<
      UnBlockUserFromChatControllerApiResponse,
      UnBlockUserFromChatControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/unblock-user`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateLastOpenedFromChatController: build.mutation<
      UpdateLastOpenedFromChatControllerApiResponse,
      UpdateLastOpenedFromChatControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/last-opened/${queryArg}`,
        method: 'PUT',
      }),
    }),
    deleteChatFromChatController: build.mutation<
      DeleteChatFromChatControllerApiResponse,
      DeleteChatFromChatControllerApiArg
    >({
      query: (queryArg) => ({ url: `/chat/${queryArg}`, method: 'DELETE' }),
    }),
    setChatNicknameFromChatController: build.mutation<
      SetChatNicknameFromChatControllerApiResponse,
      SetChatNicknameFromChatControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/${queryArg.chatUuid}`,
        method: 'PATCH',
        body: queryArg.chatSetNicknameRequestDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as chatApi };
export type CreateFromChatControllerApiResponse = /** status 200  */
  | ChatDto
  | /** status 201  */ ChatDto;
export type CreateFromChatControllerApiArg = CreateChatRequestDto;
export type FindByUserIdFromChatControllerApiResponse =
  /** status 200  */ ChatDto[];
export type FindByUserIdFromChatControllerApiArg = void;
export type CreateMessageFromChatControllerApiResponse = /** status 200  */
  | MessageDto
  | /** status 201  */ MessageDto;
export type CreateMessageFromChatControllerApiArg = CreateMessageRequestDto;
export type FindByChatUuidFromChatControllerApiResponse =
  /** status 200  */ MessageDto[];
export type FindByChatUuidFromChatControllerApiArg = string;
export type BlockUserFromChatControllerApiResponse = unknown;
export type BlockUserFromChatControllerApiArg = ChatBlockUserRequestDto;
export type UnBlockUserFromChatControllerApiResponse = unknown;
export type UnBlockUserFromChatControllerApiArg = ChatBlockUserRequestDto;
export type UpdateLastOpenedFromChatControllerApiResponse =
  /** status 200  */ string;
export type UpdateLastOpenedFromChatControllerApiArg = string;
export type DeleteChatFromChatControllerApiResponse = unknown;
export type DeleteChatFromChatControllerApiArg = string;
export type SetChatNicknameFromChatControllerApiResponse = unknown;
export type SetChatNicknameFromChatControllerApiArg = {
  chatUuid: string;
  chatSetNicknameRequestDto: ChatSetNicknameRequestDto;
};
export type ChatUserDetailsDto = {
  username: string;
  avatar: string;
  uuid: string;
  isOnline?: boolean;
  lastOnline?: string;
};
export type MessageDto = {
  uuid: string;
  chatUuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  sender: ChatUserDetailsDto;
};
export type ChatUserDto = {
  lastOpened: string | null;
  chatUuid: string;
  user: ChatUserDetailsDto;
};
export type ChatDto = {
  uuid: string;
  nickname?: string;
  date: string;
  messages: MessageDto[];
  users: ChatUserDto[];
  lastOpened?: string;
};
export type CreateChatRequestDto = {
  text?: string | null;
  photo?: object;
  video?: object;
  recipientsUuid: string[];
};
export type CreateMessageRequestDto = {
  text?: string | null;
  photo?: object;
  video?: object;
  chatUuid: string;
};
export type ChatBlockUserRequestDto = {
  userUuid: string;
};
export type ChatSetNicknameRequestDto = {
  nickname: string;
};
export const {
  useCreateFromChatControllerMutation,
  useFindByUserIdFromChatControllerQuery,
  useLazyFindByUserIdFromChatControllerQuery,
  useCreateMessageFromChatControllerMutation,
  useFindByChatUuidFromChatControllerQuery,
  useLazyFindByChatUuidFromChatControllerQuery,
  useBlockUserFromChatControllerMutation,
  useUnBlockUserFromChatControllerMutation,
  useUpdateLastOpenedFromChatControllerMutation,
  useDeleteChatFromChatControllerMutation,
  useSetChatNicknameFromChatControllerMutation,
} = injectedRtkApi;
