import React, { createContext, useCallback, useContext, useState } from 'react';
import { DimensionValue } from 'react-native';

export interface ICustomModal {
  Component: JSX.Element;
  style?: {
    height?: DimensionValue;
    width?: DimensionValue;
    left?: DimensionValue;
    top?: DimensionValue;
    inverted?: boolean;
  };
  centered?: boolean;
  animated?: boolean;
  extraProps?: { [key: string]: any };
  fullScreen?: boolean;
  onAnimationEnded?: () => void;
  animation?: 'slide' | 'fade';
}

export interface ICustomToast {
  text: string;
  type?: 'danger' | 'info' | 'success' | 'warning';
  duration?: number;
  buttonText?: string;
  position?: 'top' | 'bottom';
}

export interface PopupInterface {
  activePopup?: ICustomModal;
  activeToast?: ICustomToast;
  showToast: (toast: ICustomToast) => void;
  showPopup: (popup: ICustomModal) => void;
  hidePopup: () => void;
  hideToast: () => void;
}

const DefaultPopupContext: PopupInterface = {
  showPopup: (popup: ICustomModal) => {},
  showToast: (toast: ICustomToast) => {},
  hidePopup: () => {},
  hideToast: () => {},
};

const PopupContext = createContext<PopupInterface>(DefaultPopupContext);

export const CustomPopupProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [popupList, setPopupList] = useState<ICustomModal[]>([]);
  const [toastList, setToastList] = useState<ICustomToast[]>([]);

  const showPopup = useCallback(
    (p: ICustomModal) => {
      setPopupList((rest) => [p, ...rest]);
    },
    [popupList, setPopupList]
  );

  const showToast = useCallback(
    (p: ICustomToast) => {
      setToastList([p, ...toastList]);
    },
    [toastList, setToastList]
  );

  const hidePopup = useCallback(() => {
    setPopupList((r) => r.slice(1));
  }, [popupList, setPopupList]);

  const hideToast = useCallback(() => {
    setToastList((r) => r.slice(1));
  }, [toastList, setToastList]);

  return (
    <PopupContext.Provider
      value={{
        activeToast: toastList[0],
        showToast,
        hideToast,
        showPopup,
        hidePopup,
        activePopup: popupList[0],
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => useContext(PopupContext);
