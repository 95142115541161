import Constants from 'expo-constants';

// comment
export const SERVER_URL =
  Constants.manifest?.extra?.API_URL ||
  process.env.REACT_APP_API_URL ||
  'https://social-network.thedevbar.com/api';

export const SOCKET_URL =
  Constants.manifest?.extra?.SOCKET_URL ||
  process.env.REACT_APP_SOCKET_URL ||
  'https://social-network.thedevbar.com';

export const GIPHY_API_KEY =
  Constants.manifest?.extra?.GIPHY_API_KEY ||
  process.env.GIPHY_API_KEY ||
  'hY6NJTzMPuP6l7SdqLPGIqIUQ0aA54rZ';
