import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import { NotificationDto } from '../../api/notification';
import { NotificationService } from './notification';
import store from '../../store';
import { userApi } from '../../api/user';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyD19EjyCFuAyPhvk8FWbKNioKMdxy2OVkk',
  authDomain: 'social-network-364913.firebaseapp.com',
  projectId: 'social-network-364913',
  storageBucket: 'social-network-364913.appspot.com',
  messagingSenderId: '594506802699',
  appId: '1:594506802699:web:d02ecbc1b151f0a58fc90e',
  measurementId: 'G-JX2HEPNBXQ',
};

const vapidKey =
  // eslint-disable-next-line max-len
  'BIFCose4dECWPleq31qcyIW57b5WM8VhLRRie4RIarnNTaLFHbQtNOkkG1uouJqUdB7AkSg-CMFMLJuJaXxIpAk';

export default class FirebaseService extends NotificationService {
  private app: FirebaseApp;
  private messaging: Messaging;

  private token: string | null = null;

  constructor() {
    super();
    this.app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(this.app);

    this.init();
  }

  async init() {
    this.fetchFirebaseToken().then((token) => {
      if (!token) {
        return;
      }

      store.dispatch(
        userApi.endpoints.updateFromUserController.initiate({
          deviceToken: token,
        })
      );
    });
    onMessage(this.messaging, this.handleMessage.bind(this));
  }

  private handleMessage(message: MessagePayload) {
    const data = message.data;
    if (!data) {
      return;
    }

    const notification = JSON.parse(data.notification) as NotificationDto;

    this.handleNotification(notification);
  }

  public async getfirebaseToken(): Promise<string | null> {
    if (!this.token) {
      this.token = await this.fetchFirebaseToken();
    }

    return this.token;
  }

  private async fetchFirebaseToken(): Promise<string | null> {
    try {
      const token = await getToken(this.messaging, { vapidKey: vapidKey });

      if (!token) {
        console.debug(
          'No registration token available. Request permission to generate one.'
        );

        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            return this.fetchFirebaseToken();
          }
        });

        return null;
      }

      console.debug('Fetched firebase token', token);

      return token;
    } catch (e) {
      console.debug(
        'No registration token available. Request permission to generate one.',
        e
      );

      return null;
    }
  }
}
