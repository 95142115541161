import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFromLikeController: build.mutation<
      CreateFromLikeControllerApiResponse,
      CreateFromLikeControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/post/like`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    findByPostUuidFromLikeController: build.query<
      FindByPostUuidFromLikeControllerApiResponse,
      FindByPostUuidFromLikeControllerApiArg
    >({
      query: (queryArg) => ({ url: `/post/like/${queryArg}` }),
    }),
    unlikeFromLikeController: build.mutation<
      UnlikeFromLikeControllerApiResponse,
      UnlikeFromLikeControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/post/like/${queryArg}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as likeApi };
export type CreateFromLikeControllerApiResponse = /** status 200  */
  | LikeDto
  | /** status 201  */ LikeDto;
export type CreateFromLikeControllerApiArg = LikeCreateRequestDto;
export type FindByPostUuidFromLikeControllerApiResponse =
  /** status 200  */ LikeDto[];
export type FindByPostUuidFromLikeControllerApiArg = string;
export type UnlikeFromLikeControllerApiResponse = unknown;
export type UnlikeFromLikeControllerApiArg = string;
export type LikeUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type LikeDto = {
  uuid: string;
  date: string;
  userId: number;
  postUuid: string;
  user: LikeUserDto;
};
export type LikeCreateRequestDto = {
  postUuid: string;
};
export const {
  useCreateFromLikeControllerMutation,
  useFindByPostUuidFromLikeControllerQuery,
  useLazyFindByPostUuidFromLikeControllerQuery,
  useUnlikeFromLikeControllerMutation,
} = injectedRtkApi;
