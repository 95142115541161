import { useNavigation, useScrollToTop } from '@react-navigation/native';
import Checkbox from 'expo-checkbox';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { useFindByUserIdFromChatControllerQuery } from '../api/chat';
import {
  useLazyFindLatestByGeolocationFromUserControllerQuery,
  useLazyFindLatestFromUserControllerQuery,
  UserDto,
} from '../api/user';
import CustomButton from '../components/Basic/CustomButton';
import CustomIcon from '../components/Basic/CustomIcon';
import CustomView from '../components/Basic/CustomView';
import PageLoading from '../components/Basic/PageLoading';
import {
  ThemedFlatlist,
  ThemedFloatingButton,
  ThemedIcon,
  ThemedImage,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import { useGeolocation } from '../hooks/useGeolocation';
import useTheme from '../hooks/useTheme';

const ListHeaderView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function CreateChat() {
  const [isLocationSearchEnabled, setIsLocationSearchEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const ref = useRef(null);
  const { refetch: fetchChats } = useFindByUserIdFromChatControllerQuery();
  const navigation = useNavigation();

  const { location } = useGeolocation();

  const [fetchPeople, { isLoading }] =
    useLazyFindLatestFromUserControllerQuery();

  const [fetchByGeolocation] =
    useLazyFindLatestByGeolocationFromUserControllerQuery();

  const { uuid: userUuid } = useAppSelector((state) => state.Auth);

  const chats = useAppSelector((state) => state.Chat);
  const people = useAppSelector((state) => state.User);
  const [results, setResults] = useState<UserDto[]>(people || []);
  const [search, setSearch] = useState<string>('');

  useScrollToTop(ref);

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    if (isLocationSearchEnabled && location) {
      fetchByGeolocation({ page });
    } else {
      fetchPeople({});
    }
  }, [isLocationSearchEnabled, page]);

  const goToChat = () => {
    const chat = chats.find((chat) => {
      return (
        chat.users.length === selectedPeople.length + 1 &&
        chat.users.every(
          (cu) =>
            cu.user.uuid === userUuid || selectedPeople.includes(cu.user.uuid)
        )
      );
    });

    navigation.navigate('LoggedIn', {
      screen: 'Chat',
      params: {
        users: selectedPeople.map((uuid) => {
          const person = people.find((p) => p.uuid === uuid)!;
          return {
            avatar: person.avatar,
            username: person.username,
            uuid: person.uuid,
          };
        }),
        chatUuid: chat?.uuid ?? undefined,
      },
    });
  };

  useEffect(() => {
    if (search) {
      const foundChats = people?.filter((chat) =>
        chat.username?.toLowerCase().includes(search.toLowerCase())
      );
      setResults(foundChats || []);
    } else {
      setResults(people || []);
    }
  }, [search, people]);

  const RenderSeparator = useCallback(
    () => <View style={{ marginVertical: 5 }} />,
    []
  );

  const loadMore = useCallback(() => {
    if (isLocationSearchEnabled) {
      return;
      // setPage(page + 1);
    } else {
      const lastUser = results[results.length - 1];
      if (lastUser) {
        fetchPeople({ lastUserUuid: lastUser.uuid });
      }
    }
  }, [results]);

  const onToggleCheck = (userUuid: string) => {
    const index = selectedPeople.findIndex((p) => p === userUuid);
    if (index === -1) {
      setSelectedPeople([...selectedPeople, userUuid]);
    } else {
      const newSelectedPeople = [...selectedPeople];
      newSelectedPeople.splice(index, 1);
      setSelectedPeople(newSelectedPeople);
    }
  };

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <ThemedFlatlist
        ref={ref}
        onEndReachedThreshold={0.2}
        onEndReached={loadMore}
        data={results}
        ItemSeparatorComponent={RenderSeparator}
        ListHeaderComponent={
          <ListHeader
            isLocationAvailable={!!location}
            isLocationEnabled={!!isLocationSearchEnabled}
            handleSearch={handleSearch}
            search={search}
            onClick={() => setIsLocationSearchEnabled(!isLocationSearchEnabled)}
          />
        }
        ListHeaderComponentStyle={{ marginBottom: 50 }}
        renderItem={(item) => (
          <RenderPersonResult
            onToggleCheck={() => onToggleCheck(item.item.uuid)}
            checked={selectedPeople.includes(item.item.uuid)}
            key={`chat${item.item.uuid}`}
            user={item.item}
          />
        )}
      />
      <ThemedFloatingButton
        disabled={selectedPeople.length === 0}
        onPress={() => goToChat()}
        icon={<ThemedIcon name={'plus'} type={'FontAwesome5'} />}
      />
    </>
  );
}

const ListHeader = ({
  search,
  handleSearch,
  isLocationEnabled,
  isLocationAvailable,
  onClick,
}: {
  handleSearch: (val: string) => void;
  search: string;
  isLocationAvailable: boolean;
  isLocationEnabled: boolean;
  onClick: () => void;
}) => {
  const { palette } = useTheme();
  return (
    <ListHeaderView>
      <View style={{ flex: 1 }}>
        <ThemedTextInput
          placeholder={'Search people here..'}
          standalone
          value={search}
          onChange={handleSearch}
        />
      </View>
      {isLocationAvailable && (
        <CustomButton onPress={onClick} transparent>
          <ThemedIcon
            type={'Entypo'}
            name={'location-pin'}
            color={isLocationEnabled ? '#ffffff' : palette.icon}
          />
        </CustomButton>
      )}
    </ListHeaderView>
  );
};

const RenderPersonResult = ({
  user,
  checked = true,
  onToggleCheck,
}: {
  user: UserDto;
  checked: boolean;
  onToggleCheck: () => void;
}) => {
  const { palette } = useTheme();

  return (
    <TouchableOpacity onPress={onToggleCheck}>
      <CustomView
        testID={`user-${user.uuid}`}
        style={[
          {
            padding: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: palette.listItem,
            flex: 1,
            borderRadius: 10,
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {user.avatar && (
            <CustomView>
              <ThemedImage avatar uri={user.avatar} />
            </CustomView>
          )}
          <CustomView style={{ marginLeft: 10, maxWidth: 150 }}>
            <CustomView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ThemedText
                numberOfLines={1}
                style={{
                  fontSize: 15,
                }}
              >
                {user.username}
              </ThemedText>
            </CustomView>
          </CustomView>
        </View>
        {user.distance && (
          <View>
            <ThemedText note>{user.distance.value}</ThemedText>
            <ThemedText note>{user.distance.unit}</ThemedText>
          </View>
        )}

        <Checkbox
          color={palette.primary}
          style={{ padding: 10 }}
          value={checked}
        />
      </CustomView>
    </TouchableOpacity>
  );
};
