import { useNavigation, useRoute } from '@react-navigation/core';
import { RouteProp } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import { LoggedInTabParamList } from '../../types';
import {
  useCreateFromFollowerControllerMutation,
  useRemoveFollowerFromFollowerControllerMutation,
  useUnfollowFromFollowerControllerMutation,
} from '../api/follower';
import { useFindUserPostsFromPostControllerQuery } from '../api/post';
import { useFindOneFromUserControllerQuery } from '../api/user';
import CustomImage from '../components/Basic/CustomImage';
import PageLoading from '../components/Basic/PageLoading';
import Post from '../components/Post';
import {
  ThemedButton,
  ThemedContainer,
  ThemedText,
} from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import { useTabbarToggle } from '../hooks/useTabbarToggle';

const AvatarContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacing = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const OutsideContainer = styled.View`
  background: ${(props) => props.theme.primary};
`;

const MainContainer = styled.View`
  flex: 1;
`;

export default function UserProfile() {
  const navigation = useNavigation();
  const {
    params: {
      user: { uuid: userUuid },
    },
  } = useRoute<RouteProp<LoggedInTabParamList, 'UserProfile'>>();

  const { followers, following } = useAppSelector((state) => state.Follows);
  const chats = useAppSelector((state) => state.Chat);

  const {
    data: user,
    isLoading,
    refetch,
  } = useFindOneFromUserControllerQuery(userUuid, { refetchOnFocus: true });

  const { handleScroll } = useTabbarToggle();

  const [unfollowUser] = useUnfollowFromFollowerControllerMutation();
  const [followUser] = useCreateFromFollowerControllerMutation();
  const [removeFollower] = useRemoveFollowerFromFollowerControllerMutation();
  const {
    data: posts,
    isLoading: postsLoading,
    refetch: fetchPosts,
  } = useFindUserPostsFromPostControllerQuery({
    userUuid,
  });

  const userFollows = following.some((f) => f.uuid === user?.uuid);

  const goToChat = () => {
    if (!user) {
      return;
    }

    const chat = chats.find((chat) => {
      return chat.users.length === 1 && chat.users[0].user.uuid === user.uuid;
    });

    navigation.navigate('LoggedIn', {
      screen: 'Chat',
      params: {
        users: [
          {
            avatar: user.avatar,
            uuid: user.uuid,
            username: user.username,
          },
        ],
        chatUuid: chat?.uuid ?? undefined,
      },
    });
  };

  useEffect(() => {
    navigation.setOptions({
      headerTitle: '',
    });
  }, []);

  const toggleFollow = () => {
    if (!user) {
      return;
    }

    if (userFollows) {
      unfollowUser(user.uuid).then(refetch).then(fetchPosts);
    } else {
      followUser({ userUuid: user.uuid }).then(refetch).then(fetchPosts);
    }
  };

  const loadMore = useCallback(() => {
    if (posts) {
      const lastPost = posts[posts.length - 1];
      if (lastPost) {
        fetchPosts();
      }
    }
  }, [posts]);

  const EmptyListComponent = () => {
    return <ThemedText>No posts found</ThemedText>;
  };

  if (isLoading || !user) {
    return <PageLoading />;
  }

  const viewFollowers = () => {
    navigation.navigate('LoggedIn', {
      screen: 'FollowersList',
      params: {
        userUuid: user?.uuid,
        type: 'followers',
      },
    });
  };

  const viewFollowees = () => {
    navigation.navigate('LoggedIn', {
      screen: 'FollowersList',
      params: {
        userUuid: user?.uuid,
        type: 'following',
      },
    });
  };

  return (
    <ThemedContainer>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TouchableOpacity
          onPress={viewFollowers}
          style={{ alignItems: 'center', padding: 10 }}
        >
          <ThemedText>Followers</ThemedText>
          <ThemedText note>{user.followersCount}</ThemedText>
        </TouchableOpacity>
        <View
          style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
        >
          {user.avatar ? (
            <AvatarContainer>
              <CustomImage avatar large uri={user.avatar} />
            </AvatarContainer>
          ) : null}
          <ThemedText style={{ marginTop: 10 }}>@{user.username}</ThemedText>
          <ThemedText
            numberOfLines={3}
            note
            style={{ marginTop: 10, textAlign: 'center' }}
          >
            {user.description}
          </ThemedText>
        </View>
        <TouchableOpacity
          onPress={viewFollowees}
          style={{ alignItems: 'center', padding: 10 }}
        >
          <ThemedText>Following</ThemedText>
          <ThemedText note>{user.followingCount}</ThemedText>
        </TouchableOpacity>
      </View>
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <ThemedButton
          onPress={toggleFollow}
          full={false}
          style={{ width: 100 }}
        >
          <ThemedText> {userFollows ? 'Unfollow' : 'Follow'}</ThemedText>
        </ThemedButton>
        <ThemedButton
          outline
          transparent
          full={false}
          style={{ left: 10, width: 100 }}
          onPress={() => goToChat()}
        >
          <ThemedText>Message</ThemedText>
        </ThemedButton>
      </View>

      <Spacing />

      <ThemedText>Posts</ThemedText>

      <Spacing />

      {posts?.map((p) => (
        <>
          <Post key={`post${p.uuid}`} post={p} />
          <Spacing />
        </>
      ))}

      <Spacing />
    </ThemedContainer>
  );
}
