import React, { useState } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { usePopup } from '../../hooks/usePopup';
import useTheme from '../../hooks/useTheme';
import {
  ThemedButton,
  ThemedIcon,
  ThemedText,
  ThemedTextInput,
  ThemedView,
} from '../Themed';
import CustomCard from './CustomCard';

interface CustomModalInputProps {
  label?: string;
  placeholder?: string;
  showCloseButton?: boolean;
  onSubmit?: (val?: string) => void;
}

export default function CustomModalInput({
  label,
  placeholder,
  onSubmit,
  showCloseButton = true,
}: CustomModalInputProps) {
  const { hidePopup } = usePopup();
  const { palette } = useTheme();
  const [input, setInput] = useState('');
  return (
    <ThemedView style={{ flex: 1, borderRadius: 10, padding: 5 }}>
      <CustomCard
        header={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 10,
              borderBottomColor: '#5959592e',
              borderBottomWidth: 1,
            }}
          >
            <View>
              <ThemedText>{label}</ThemedText>
            </View>
            {showCloseButton && (
              <TouchableOpacity onPress={hidePopup}>
                <ThemedIcon name={'close'} type="AntDesign" />
              </TouchableOpacity>
            )}
          </View>
        }
        body={
          <View
            style={{
              borderBottomColor: '#5959592e',
              borderBottomWidth: 1,
              paddingVertical: 5,
            }}
          >
            <ThemedTextInput
              placeholder={placeholder}
              standalone
              value={input}
              onChange={(val) => setInput(val)}
            />
          </View>
        }
        footer={
          <ThemedButton transparent onPress={() => onSubmit && onSubmit(input)}>
            <ThemedText>Submit</ThemedText>
          </ThemedButton>
        }
      />
      {/* {actions.map((action) => (
          <Action key={`action-${generateRandomUUid()}`} action={action} />
        ))}
        {showCancel && (
          <Action
            key={`action-${generateRandomUUid()}`}
            action={{ text: 'Cancel', onPress: () => {}, onPressClose: true }}
          />
        )} */}
    </ThemedView>
  );
}
