import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFromCommentController: build.mutation<
      CreateFromCommentControllerApiResponse,
      CreateFromCommentControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/post/comment`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    findByPostUuidFromCommentController: build.query<
      FindByPostUuidFromCommentControllerApiResponse,
      FindByPostUuidFromCommentControllerApiArg
    >({
      query: (queryArg) => ({ url: `/post/comment/${queryArg}` }),
    }),
    removeFromCommentController: build.mutation<
      RemoveFromCommentControllerApiResponse,
      RemoveFromCommentControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/post/comment/${queryArg}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as commentApi };
export type CreateFromCommentControllerApiResponse = /** status 200  */
  | CommentDto
  | /** status 201  */ CommentDto;
export type CreateFromCommentControllerApiArg = CommentCreateRequestDto;
export type FindByPostUuidFromCommentControllerApiResponse =
  /** status 200  */ CommentDto[];
export type FindByPostUuidFromCommentControllerApiArg = string;
export type RemoveFromCommentControllerApiResponse = unknown;
export type RemoveFromCommentControllerApiArg = string;
export type CommentUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type CommentDto = {
  uuid: string;
  postUuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  sender: CommentUserDto;
};
export type CommentCreateRequestDto = {
  text?: string | null;
  photo?: object;
  video?: object;
  postUuid: string;
};
export const {
  useCreateFromCommentControllerMutation,
  useFindByPostUuidFromCommentControllerQuery,
  useLazyFindByPostUuidFromCommentControllerQuery,
  useRemoveFromCommentControllerMutation,
} = injectedRtkApi;
