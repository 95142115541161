import { useEffect } from 'react';
import { NotificationDto } from '../api/notification';
import { NotificationManager } from '../services/notification';
import { NotificationSubscriber } from '../services/notification/notification';

export const useNotification = (
  callback: (message: NotificationDto) => void,
  notificationTypes?: NotificationDto['type'][]
) => {
  useEffect(() => {
    const subscriber: NotificationSubscriber = {
      callback,
      type: notificationTypes,
    };
    NotificationManager.getInstance().subscribe(subscriber);

    return () => NotificationManager.getInstance().unsubscribe(subscriber);
  }, [callback]);
};
