import { DefaultTheme } from 'styled-components';

const light: DefaultTheme = {
  button: '#394056',
  icon: '#394056',
  background: '#fff',
  lightBackground: '#ccc',
  tabIconDefault: '#ddd',
  mainContainer: '#f5f5f5',
  listItem: '#ffffff',
  header: '#f5f5f5',
  primary: '#394056',
  text: '#394056',
  note: '#828dac',
  inputContainer: '#fff',
  inputPlaceholder: '#828dac',
  inputText: '#333333',
  inputLabel: '#828dac',
  fontFamily: 'BalsamiqSans_400Regular',
  headerText: '#394056',
  tabBar: '#1C211E',
  tabIconSelected: '#fff',
  tint: 'red',
};

const dark: DefaultTheme = {
  ...light,
  icon: '#cccccc',
  inputText: '#F4F7FB',
  inputLabel: '#bbbbbb',
  inputContainer: '#292E3D',
  inputPlaceholder: '#999',
  text: '#e2e5ec',
  note: '#cfd3df',
  mainContainer: '#212531',
  header: '#212531',
  headerText: '#dddddd',
  listItem: '#292E3D',
  button: '#394056',
  background: '#000',
  primary: '#394056',
  tabIconDefault: '#ccc',
  tabIconSelected: '#fff',
  tabBar: '#1C211E',
};

export default {
  light,
  dark,
};
