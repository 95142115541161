import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    findLatestFromUserController: build.query<
      FindLatestFromUserControllerApiResponse,
      FindLatestFromUserControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/user/latest`,
        params: {
          lastUserUuid: queryArg.lastUserUuid,
          search: queryArg.search,
        },
      }),
    }),
    findLatestByGeolocationFromUserController: build.query<
      FindLatestByGeolocationFromUserControllerApiResponse,
      FindLatestByGeolocationFromUserControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/user/geo/latest`,
        params: {
          search: queryArg.search,
          page: queryArg.page,
          pageLimit: queryArg.pageLimit,
        },
      }),
    }),
    findLatestBotsFromUserController: build.query<
      FindLatestBotsFromUserControllerApiResponse,
      FindLatestBotsFromUserControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/user/latest-bots`,
        params: {
          lastUserUuid: queryArg.lastUserUuid,
          search: queryArg.search,
        },
      }),
    }),
    findBlockedUsersFromUserController: build.query<
      FindBlockedUsersFromUserControllerApiResponse,
      FindBlockedUsersFromUserControllerApiArg
    >({
      query: () => ({ url: `/user/blocked-users` }),
    }),
    userExistsFromUserController: build.query<
      UserExistsFromUserControllerApiResponse,
      UserExistsFromUserControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/user/exists`,
        params: { username: queryArg },
      }),
    }),
    findOneFromUserController: build.query<
      FindOneFromUserControllerApiResponse,
      FindOneFromUserControllerApiArg
    >({
      query: (queryArg) => ({ url: `/user/${queryArg}` }),
    }),
    deleteDeviceTokenFromUserController: build.mutation<
      DeleteDeviceTokenFromUserControllerApiResponse,
      DeleteDeviceTokenFromUserControllerApiArg
    >({
      query: (queryArg) => ({ url: `/user`, method: 'PUT', body: queryArg }),
    }),
    updateFromUserController: build.mutation<
      UpdateFromUserControllerApiResponse,
      UpdateFromUserControllerApiArg
    >({
      query: (queryArg) => ({ url: `/user`, method: 'PATCH', body: queryArg }),
    }),
    createBotFromUserController: build.mutation<
      CreateBotFromUserControllerApiResponse,
      CreateBotFromUserControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/user/create-bot`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateCoordinatesFromUserController: build.mutation<
      UpdateCoordinatesFromUserControllerApiResponse,
      UpdateCoordinatesFromUserControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/user/coordinates`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userApi };
export type FindLatestFromUserControllerApiResponse =
  /** status 200  */ UserDto[];
export type FindLatestFromUserControllerApiArg = {
  lastUserUuid?: string;
  search?: string;
};
export type FindLatestByGeolocationFromUserControllerApiResponse =
  /** status 200  */ UserDto[];
export type FindLatestByGeolocationFromUserControllerApiArg = {
  search?: string;
  page?: number;
  pageLimit?: number;
};
export type FindLatestBotsFromUserControllerApiResponse =
  /** status 200  */ BotUserDto[];
export type FindLatestBotsFromUserControllerApiArg = {
  lastUserUuid?: string;
  search?: string;
};
export type FindBlockedUsersFromUserControllerApiResponse =
  /** status 200  */ BlockedUserDto[];
export type FindBlockedUsersFromUserControllerApiArg = void;
export type UserExistsFromUserControllerApiResponse =
  /** status 200  */ boolean;
export type UserExistsFromUserControllerApiArg = string;
export type FindOneFromUserControllerApiResponse = /** status 200  */ UserDto;
export type FindOneFromUserControllerApiArg = string;
export type DeleteDeviceTokenFromUserControllerApiResponse = unknown;
export type DeleteDeviceTokenFromUserControllerApiArg =
  RemoveDeviceTokenRequestDto;
export type UpdateFromUserControllerApiResponse =
  /** status 200  */ AuthUserDto;
export type UpdateFromUserControllerApiArg = UpdateUserRequestDto;
export type CreateBotFromUserControllerApiResponse = unknown;
export type CreateBotFromUserControllerApiArg = CreateBotUserRequestDto;
export type UpdateCoordinatesFromUserControllerApiResponse = unknown;
export type UpdateCoordinatesFromUserControllerApiArg =
  UpdateUserCoordinatesRequestDto;
export type NearbyUserLocationDto = {
  value: number;
  unit: string;
};
export type UserDto = {
  avatar: string;
  dob: string;
  email: string;
  gender: string;
  uuid: string;
  username: string;
  description: string;
  distance?: NearbyUserLocationDto;
  followersCount: number;
  followingCount: number;
  postsCount: number;
};
export type BotUserDto = {
  avatar: string;
  uuid: string;
  username: string;
  description: string;
};
export type BlockedUserDto = {
  uuid: string;
  username: string;
  avatar: string;
  blockedDate: string;
};
export type RemoveDeviceTokenRequestDto = {
  token: string;
};
export type AuthUserDto = {
  uuid: string;
  username: string;
  description: string;
  email: string;
  dob: string;
  gender: string;
  avatar: string;
  accessToken: string;
  refreshToken: string | null;
  features: 'CREATE_BOT'[];
  followersCount: number;
  followingCount: number;
};
export type UpdateUserRequestDto = {
  description?: string;
  deviceToken?: string;
  avatar?: object;
};
export type UserLocationCoordinatesDto = {
  longitude: number;
  latitude: number;
};
export type CreateBotUserRequestDto = {
  username: string;
  description: string;
  systemPrompt: string;
  gender: Gender;
  avatar?: object;
  externalUrlAvatar?: string;
  location?: UserLocationCoordinatesDto;
};
export type LocationObjectCoordsDto = {
  latitude: number;
  longitude: number;
  altitude: number | null;
  accuracy: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  speed: number | null;
};
export type UpdateUserCoordinatesRequestDto = {
  coordinates: LocationObjectCoordsDto;
};
export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Na = 'NA',
}
export const {
  useFindLatestFromUserControllerQuery,
  useLazyFindLatestFromUserControllerQuery,
  useFindLatestByGeolocationFromUserControllerQuery,
  useLazyFindLatestByGeolocationFromUserControllerQuery,
  useFindLatestBotsFromUserControllerQuery,
  useLazyFindLatestBotsFromUserControllerQuery,
  useFindBlockedUsersFromUserControllerQuery,
  useLazyFindBlockedUsersFromUserControllerQuery,
  useUserExistsFromUserControllerQuery,
  useLazyUserExistsFromUserControllerQuery,
  useFindOneFromUserControllerQuery,
  useLazyFindOneFromUserControllerQuery,
  useDeleteDeviceTokenFromUserControllerMutation,
  useUpdateFromUserControllerMutation,
  useCreateBotFromUserControllerMutation,
  useUpdateCoordinatesFromUserControllerMutation,
} = injectedRtkApi;
