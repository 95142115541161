import { useFocusEffect, useScrollToTop } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import { ListRenderItemInfo, TouchableOpacity, View } from 'react-native';
import { useUnBlockUserFromChatControllerMutation } from '../api/chat';
import {
  BlockedUserDto,
  useFindBlockedUsersFromUserControllerQuery,
} from '../api/user';
import CustomView from '../components/Basic/CustomView';
import PageLoading from '../components/Basic/PageLoading';
import {
  ThemedButton,
  ThemedFlatlist,
  ThemedImage,
  ThemedText,
  ThemedView,
} from '../components/Themed';
import { usePopup } from '../hooks/usePopup';
import useTheme from '../hooks/useTheme';

export default function BlockedUsers() {
  const { showToast } = usePopup();
  const listRef = useRef(null);
  const {
    refetch: fetchBlockedUsers,
    data: blockedUsers,
    isLoading,
  } = useFindBlockedUsersFromUserControllerQuery();

  const [unblockUser] = useUnBlockUserFromChatControllerMutation();

  useScrollToTop(listRef);

  useFocusEffect(useCallback(() => fetchBlockedUsers, []));

  useEffect(() => {
    fetchBlockedUsers();
  }, []);

  const RenderSeparator = useCallback(
    () => <View style={{ marginVertical: 5 }} />,
    []
  );

  const unblockUserHandle = useCallback(async (userUuid: string) => {
    try {
      await unblockUser({ userUuid }).unwrap();
      fetchBlockedUsers();
    } catch (error) {
      showToast({ type: 'success', text: 'User unblocked!' });
    }
  }, []);

  if (isLoading || !blockedUsers) {
    return <PageLoading />;
  }

  const EmptyListComponent = () => {
    return <ThemedText>No blocked users found</ThemedText>;
  };

  return (
    <ThemedFlatlist
      ref={listRef}
      data={blockedUsers}
      ListEmptyComponent={EmptyListComponent}
      ItemSeparatorComponent={RenderSeparator}
      ListHeaderComponentStyle={{ marginBottom: 50 }}
      renderItem={(item: ListRenderItemInfo<BlockedUserDto>) => (
        <RenderBlockedUserItem
          key={`blockedUser${item.item.uuid}`}
          blockedUser={item.item}
          unblockUser={() => unblockUserHandle(item.item.uuid)}
        />
      )}
    />
  );
}

const RenderBlockedUserItem = ({
  blockedUser,
  unblockUser,
}: {
  blockedUser: BlockedUserDto;
  unblockUser: () => void;
}) => {
  const { palette } = useTheme();

  return (
    <TouchableOpacity activeOpacity={1}>
      <ThemedView
        style={{
          backgroundColor: palette.listItem,
          padding: 20,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <CustomView>
          {blockedUser.avatar && (
            <ThemedImage avatar uri={blockedUser.avatar} />
          )}
        </CustomView>
        <CustomView style={{ marginLeft: 10, maxWidth: 150 }}>
          <CustomView>
            <ThemedText
              style={{
                fontSize: 15,
              }}
            >
              {blockedUser.username}
            </ThemedText>
            <ThemedText note>
              {moment(blockedUser.blockedDate).fromNow()}
            </ThemedText>
          </CustomView>
        </CustomView>
        <CustomView
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            right: 25,
            top: 25,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ThemedButton onPress={unblockUser} full={false}>
            <ThemedText>Unblock</ThemedText>
          </ThemedButton>
        </CustomView>
      </ThemedView>
    </TouchableOpacity>
  );
};
