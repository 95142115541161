import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import { mainReducer } from './reducers';
import { clearAppData } from './reducers/AppData';
import { clearChats } from './reducers/Chat';
import { clearNotifications } from './reducers/Notification';
import { clearPosts } from './reducers/Post';

import { api } from '../api/baseApi';
import { clearAuth } from './actions/auth';
import { clearFollowers } from './reducers/Follower';

export const clearStore = () => {
  store.dispatch(clearAuth());
  store.dispatch(clearPosts());
  store.dispatch(clearChats());
  store.dispatch(clearAppData());
  store.dispatch(clearNotifications());
  store.dispatch(clearFollowers());
};

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: AsyncStorage,
    blacklist: [
      'user-api',
      'auth-api',
      'post-api',
      'notification-api',
      'chat-api',
      'appData.showBottomNav',
    ],
  },
  mainReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
  devTools: true,
});
export type RootState = ReturnType<typeof mainReducer>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);

export default store;
