import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { SERVER_URL } from '../config';
import { clearAuth, refreshToken } from '../store/actions/auth';

// create a new mutex
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({ baseUrl: '/' });

export const customFetchBase: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  args.url = SERVER_URL + args.url;
  args.headers = {
    ...args.headers,
    // @ts-ignore
    Authorization: `Bearer ${api.getState().Auth.accessToken}`,
  };

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: `${SERVER_URL}/auth/refreshToken`,
            method: 'POST',
            headers: {
              // @ts-ignore
              Authorization: `Bearer ${api.getState().Auth.accessToken}`,
            },
            body: {
              // @ts-ignore
              refreshToken: api.getState().Auth.refreshToken,
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(
            refreshToken(
              refreshResult.data as unknown as {
                accessToken: string;
                refreshToken: string;
              }
            )
          );

          args.headers = {
            ...args.headers,
            // @ts-ignore
            Authorization: `Bearer ${refreshResult.data.accessToken}`,
          };

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(clearAuth());
          // api.dispatch(clearUser());
          // api.dispatch(clearPosts());
          // api.dispatch(clearChats());
          // api.dispatch(clearAppData());
          // api.dispatch(clearNotifications());
        }
      } catch {
        api.dispatch(clearAuth());
        // api.dispatch(clearPosts());
        // api.dispatch(clearChats());
        // api.dispatch(clearAppData());
        // api.dispatch(clearNotifications());
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}
