import { createSlice } from '@reduxjs/toolkit';
import { BotUserDto, userApi } from '../../api/user';

const initialState: BotUserDto[] = [];

const BotSlice = createSlice({
  name: 'bot',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.findLatestBotsFromUserController.matchFulfilled,
      (state, { payload, meta }) => {
        if (meta.arg.originalArgs) {
          return [...state, ...payload];
        } else {
          return payload;
        }
      }
    );
  },
});

export const {} = BotSlice.actions;
export default BotSlice.reducer;
