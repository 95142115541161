import { useNavigation } from '@react-navigation/core';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import {
  ChatDto,
  useBlockUserFromChatControllerMutation,
  useDeleteChatFromChatControllerMutation,
  useUnBlockUserFromChatControllerMutation,
} from '../../api/chat';
import { useFindBlockedUsersFromUserControllerQuery } from '../../api/user';
import { usePopup } from '../../hooks/usePopup';
import ActionSheet, { ActionSheetAction } from '../Basic/ActionSheet';
import CustomIcon from '../Basic/CustomIcon';
import CustomView from '../Basic/CustomView';
import { ChatMembersList } from '../ChatMembers';
import PlusAvatar from '../PlusAvatar';
import { ThemedImage, ThemedListItem, ThemedText } from '../Themed';

const MAX_USERS_TO_RENDER = 3;

const UnreadBubble = styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.primary};
  margin-left: 10px;
`;

const ItemContainer = styled(ThemedListItem)``;

const OnlineBadge = styled.View`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: green;
  margin-left: 10px;
`;

const ChatComponent = React.memo(({ chat }: { chat: ChatDto }) => {
  const { showToast, showPopup, hidePopup } = usePopup();
  const navigation = useNavigation();

  const { refetch: fetchBlockedUsers } =
    useFindBlockedUsersFromUserControllerQuery();
  const [blockUser] = useBlockUserFromChatControllerMutation();
  const [unblockUser] = useUnBlockUserFromChatControllerMutation();
  const [deleteChat] = useDeleteChatFromChatControllerMutation();

  const { data: blockedUsers } = useFindBlockedUsersFromUserControllerQuery();

  const isChatOnline = chat.users.some((cu) => cu.user.isOnline === true);
  const isChatBlockable = chat.users.length === 1;

  const isChatUnread = useMemo(
    () => !chat.lastOpened || chat.messages[0].date > chat.lastOpened,
    [chat]
  );

  const goToChat = () => {
    navigation.navigate('LoggedIn', {
      screen: 'Chat',
      params: {
        chatUuid: chat.uuid,
        users: chat.users.map((cu) => cu.user),
      },
    });
  };

  const showChatActions = () => {
    const actions: ActionSheetAction[] = [
      {
        text: 'View members',
        onPress: () =>
          showPopup({
            Component: (
              <ChatMembersList members={chat!.users.map((cu) => cu.user)} />
            ),
            animated: true,
            style: {
              left: '20%',
              width: '60%',
              top: '20%',
              height: '60%',
            },
          }),
      },
      {
        text: 'Delete Chat',
        onPress: () => {
          deleteChatHandle();
        },
      },
    ];

    if (isChatBlockable) {
      actions.unshift({
        text: isUserBlocked ? 'Unblock User' : 'Block User',
        onPress: () => {
          hidePopup();
          isUserBlocked ? unblockUserHandle() : blockUserHandle();
        },
      });
    }

    showPopup({
      style: { inverted: true, width: '100%' },
      Component: <ActionSheet showCancel actions={actions} />,
      animated: true,
      animation: 'slide',
    });
  };

  const isUserBlocked =
    (chat.users.length === 1 &&
      blockedUsers?.some((bu) =>
        chat.users.some((cu) => cu.user.uuid === bu.uuid)
      )) ??
    false;

  const blockUserHandle = useCallback(async () => {
    const action: ActionSheetAction = {
      text: 'Block User',
      onPress: () => {
        (async () => {
          const userUuid = chat.users[0].user.uuid;
          try {
            await blockUser({ userUuid: userUuid }).unwrap();
            fetchBlockedUsers();
          } catch (error) {
            showToast({ text: 'Something went wrong' });
          } finally {
          }
        })();
        hidePopup();
      },
    };

    showPopup({
      style: { inverted: true, width: '100%' },
      Component: <ActionSheet showCancel actions={[action]} />,
      animated: true,
      animation: 'slide',
    });
  }, [chat.users]);

  const unblockUserHandle = useCallback(async () => {
    const userUuid = chat.users[0].user.uuid;
    try {
      await unblockUser({ userUuid: userUuid }).unwrap();
      fetchBlockedUsers();
    } catch (error) {
      showToast({ text: 'Something went wrong' });
    } finally {
    }
  }, [chat.users]);

  const deleteChatHandle = useCallback(async () => {
    try {
      await deleteChat(chat.uuid).unwrap();
      fetchBlockedUsers();
    } catch (error) {
      showToast({ text: 'Something went wrong' });
    } finally {
    }
  }, [chat]);

  const moreThanMaxToRenderUsers = chat.users.length > MAX_USERS_TO_RENDER;
  const numberOfUsersToRender =
    chat.users.length > MAX_USERS_TO_RENDER
      ? MAX_USERS_TO_RENDER
      : chat.users.length;

  return (
    <ThemedListItem
      onPress={goToChat}
      onLongPress={showChatActions}
      leftComponent={() => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CustomView
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'absolute',
            }}
          >
            {chat.users.slice(0, numberOfUsersToRender).map((u, index) => (
              <ThemedImage
                containerStyle={{
                  left: index > 0 ? -(25 * index) : undefined,
                }}
                avatar
                key={`avatar${index}`}
                uri={u.user.avatar}
              />
            ))}
            {moreThanMaxToRenderUsers && (
              <PlusAvatar style={{ left: -(25 * numberOfUsersToRender) }} />
            )}
          </CustomView>
          <CustomView
            style={{
              left:
                (moreThanMaxToRenderUsers
                  ? MAX_USERS_TO_RENDER + 1
                  : chat.users.length) *
                  25 +
                35,
              maxWidth: 200,
            }}
          >
            <CustomView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 2,
              }}
            >
              <ThemedText
                style={{
                  fontSize: 15,
                  maxWidth: '100%',
                }}
                numberOfLines={1}
              >
                {chat.nickname ??
                  chat.users.map((cu) => cu.user.username).join(', ')}
              </ThemedText>
              {isChatOnline && <OnlineBadge />}
              {isUserBlocked && (
                <CustomIcon
                  type="MaterialIcons"
                  style={{ marginLeft: 10, fontSize: 20, color: '#9C27B0' }}
                  name="block"
                />
              )}
            </CustomView>
            <ThemedText note numberOfLines={2} style={{ maxWidth: '80%' }}>
              {chat.messages[chat.messages.length - 1].text}
            </ThemedText>
          </CustomView>
        </View>
      )}
      rightComponent={() => (
        <ThemedText note>
          {moment(new Date(chat.messages[0].date).getTime()).fromNow()}
        </ThemedText>
      )}
    />
  );
});

export default ChatComponent;
