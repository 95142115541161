import {
  useFocusEffect,
  useNavigation,
  useScrollToTop,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import {
  BotUserDto,
  useLazyFindLatestBotsFromUserControllerQuery,
} from '../api/user';
import CustomView from '../components/Basic/CustomView';
import PageLoading from '../components/Basic/PageLoading';
import {
  ThemedButton,
  ThemedFlatlist,
  ThemedImage,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import useTheme from '../hooks/useTheme';

export default function AI() {
  const navigation = useNavigation();
  const ref = useRef(null);
  const [fetchBots, { isLoading }] =
    useLazyFindLatestBotsFromUserControllerQuery();

  const chats = useAppSelector((state) => state.Chat);
  const authFeatures = useAppSelector((state) => state.Auth?.features);
  const bots = useAppSelector((state) => state.Bot);
  const [results, setResults] = useState<BotUserDto[]>(bots || []);
  const [search, setSearch] = useState<string>('');

  useScrollToTop(ref);

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  useFocusEffect(
    useCallback(() => {
      fetchBots({ lastUserUuid: '' });
    }, [])
  );

  const goToChat = (user: BotUserDto) => {
    const chat = chats.find((chat) => {
      return (
        chat.users.length === 2 &&
        (chat.users[1].user.uuid === user.uuid ||
          chat.users[0].user.uuid === user.uuid)
      );
    });

    navigation.navigate('LoggedIn', {
      screen: 'Chat',
      params: {
        users: [
          {
            username: user.username,
            uuid: user.uuid,
            avatar: user.avatar,
          },
        ],
        chatUuid: chat?.uuid ?? undefined,
      },
    });
  };

  useEffect(() => {
    if (search) {
      const foundChats = bots?.filter((chat) =>
        chat.username?.toLowerCase().includes(search.toLowerCase())
      );
      setResults(foundChats || []);
    } else {
      setResults(bots || []);
    }
  }, [search, bots]);

  const RenderSeparator = useCallback(
    () => <View style={{ marginVertical: 5 }} />,
    []
  );

  const loadMore = useCallback(() => {
    const lastPost = results[results.length - 1];
    if (lastPost) {
      fetchBots({ lastUserUuid: lastPost.uuid });
    }
  }, [results]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <ThemedFlatlist
      ref={ref}
      onEndReachedThreshold={0.2}
      onEndReached={loadMore}
      data={results}
      ItemSeparatorComponent={RenderSeparator}
      ListHeaderComponent={
        <ListHeader
          canAddBot={authFeatures.includes('CREATE_BOT')}
          handleSearch={handleSearch}
          onAddNewBotClick={() =>
            navigation.navigate('LoggedIn', { screen: 'AddBot' })
          }
          search={search}
        />
      }
      ListHeaderComponentStyle={{ marginBottom: 50 }}
      renderItem={(item) => (
        <RenderPersonResult
          key={`chat${item.item.uuid}`}
          user={item.item}
          goToChat={goToChat}
        />
      )}
    />
  );
}

const ListHeader = ({
  canAddBot,
  search,
  handleSearch,
  onAddNewBotClick,
}: {
  canAddBot: boolean;
  handleSearch: (val: string) => void;
  onAddNewBotClick: () => void;
  search: string;
}) => (
  <>
    {canAddBot && (
      <ThemedButton onPress={onAddNewBotClick}>
        <ThemedText>Add bot</ThemedText>
      </ThemedButton>
    )}
    <ThemedTextInput
      containerStyle={{ marginTop: 10 }}
      placeholder={'Search bots here..'}
      standalone
      value={search}
      onChange={handleSearch}
    />
  </>
);

const RenderPersonResult = ({
  user,
  goToChat,
}: {
  user: BotUserDto;
  goToChat: (user: BotUserDto) => void;
}) => {
  const { palette } = useTheme();

  const goToUser = () => {
    goToChat(user);
  };

  return (
    <>
      <TouchableOpacity onPress={goToUser} activeOpacity={1}>
        <CustomView
          testID={`user-${user.uuid}`}
          style={[
            {
              padding: 20,
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: palette.listItem,
            },
          ]}
        >
          {user.avatar && (
            <CustomView>
              <ThemedImage avatar uri={user.avatar} />
            </CustomView>
          )}
          <CustomView style={{ marginLeft: 10, maxWidth: 150 }}>
            <CustomView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ThemedText
                style={{
                  fontSize: 15,
                }}
              >
                {user.username}
              </ThemedText>
            </CustomView>
          </CustomView>
        </CustomView>
      </TouchableOpacity>
    </>
  );
};
