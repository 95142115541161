import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';
import * as yup from 'yup';
import {
  ThemedButton,
  ThemedContainer,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { usePopup } from '../hooks/usePopup';
import { percentageHeight } from '../utils';

import {
  CreateBotUserRequestDto,
  Gender,
  useCreateBotFromUserControllerMutation,
  useLazyUserExistsFromUserControllerQuery,
} from '../api/user';

const RegisterMainSection = styled(ThemedContainer)`
  background: ${(props) => props.theme.mainContainer};
  display: flex;
  flex: 1;
  padding: ${percentageHeight(2)}px ${percentageHeight(3)}px;
`;

const RegisterButton = styled(ThemedButton)`
  margin-top: ${percentageHeight(5)}px;
`;

const LoginForm = styled.View``;

const RegisterInput = styled(ThemedTextInput).attrs((props) => ({
  containerStyle: {
    marginBottom: 20,
    // @ts-ignore
    ...props.containerStyle,
  },
}))`` as typeof ThemedTextInput;

const schema = yup.object().shape({
  username: yup.string().required(),
  description: yup.string().required(),
  systemPrompt: yup.string().required(),
  //   avatar: yup.object(),
});

export default function AddBot() {
  const [createBot, { isSuccess, isLoading, error }] =
    useCreateBotFromUserControllerMutation();

  const [userExists] = useLazyUserExistsFromUserControllerQuery();

  const navigation = useNavigation();

  const { showToast } = usePopup();

  const {
    formState: { errors },
    control,
    handleSubmit,
    setError,
  } = useForm<CreateBotUserRequestDto>({
    defaultValues: {
      username: '',
      description: '',
      systemPrompt: '',
      externalUrlAvatar:
        'https://cdn-icons-png.flaticon.com/512/6134/6134346.png',
    },
    resolver: yupResolver(schema),
  });

  //   const getAvatar = async (imageUri: string): Promise<Blob> => {
  //     const data = await fetch(imageUri);
  //     const blob = await data.blob();
  //     return blob;
  //   };

  const doRegister = async (values: CreateBotUserRequestDto) => {
    const { data: exists } = await userExists(values.username);
    if (exists) {
      setError('username', { message: 'Username already exists' });
      return;
    }

    createBot({
      username: values.username,
      description: values.description,
      systemPrompt: values.systemPrompt,
      gender: Gender.Na,
      externalUrlAvatar: values.externalUrlAvatar,
    });

    // if (image) {
    //   registerDataFormData.append(
    //     'avatar',
    //     await getAvatar((image as ImageDetails).uri)
    //   );
    // } else {
    //   registerDataFormData.append(
    //     'avatar',
    //     await getAvatar(
    //       'https://avatars.dicebear.com/api/open-peeps/stefan.svg'
    //     )
    //   );
    // }

    // @ts-ignore
    createBot(registerDataFormData);
  };

  useEffect(() => {
    if (isSuccess) {
      showToast({
        text: 'Bot Created',
        type: 'success',
      });
      navigation.goBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      showToast({
        text: 'Something went wrong',
        type: 'danger',
      });
    }
  }, [error]);

  //   const image = watch().avatar;

  return (
    <RegisterMainSection>
      <LoginForm>
        <RegisterInput
          testId={'usernameRegisterField'}
          label={'Username'}
          name={'username'}
          control={control}
          error={errors.username?.message}
        />

        <RegisterInput
          testId={'systemPromptRegisterField'}
          label={'System Prompt'}
          name={'systemPrompt'}
          control={control}
          type="text"
          numberOfLines={5}
          error={errors.systemPrompt?.message}
        />

        <RegisterInput
          testId={'descriptionRegisterField'}
          label={'Description'}
          name={'description'}
          control={control}
          error={errors.description?.message}
        />
      </LoginForm>
      <RegisterButton
        testId={'registerButton'}
        loading={isLoading}
        onPress={handleSubmit(doRegister)}
      >
        <ThemedText>Create Bot</ThemedText>
      </RegisterButton>
    </RegisterMainSection>
  );
}
