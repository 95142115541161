import React from 'react';
import { ActivityIndicator } from 'react-native';
import CustomView from './CustomView';

export default function PageLoading() {
  return (
    <CustomView
      style={{ flex: 1, alignItems: 'center', alignContent: 'center' }}
    >
      <ActivityIndicator size={'large'}></ActivityIndicator>
    </CustomView>
  );
}
