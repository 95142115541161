import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { PostDto, useLazyFindOneFromPostControllerQuery } from '../../api/post';
import {
  useFindOneFromUserControllerQuery,
  useLazyFindOneFromUserControllerQuery,
} from '../../api/user';
import { useAppSelector } from '../../hooks/store';
import { usePopup } from '../../hooks/usePopup';
import ActionSheet, { ActionSheetAction } from '../Basic/ActionSheet';
import CustomModalInput from '../Basic/CustomModalInput';
import ImageViewer from '../ImageViewer';
import PostActions from '../PostActions';
import { ThemedCard, ThemedView } from '../Themed';
import PostBody from './Body';
import PostFooter from './Footer';
import PostHeader from './Header';

interface PostProps {
  post: PostDto;
}

const Post = React.memo(({ post }: PostProps) => {
  const { showPopup, showToast } = usePopup();
  const navigation = useNavigation();

  const authUser = useAppSelector((state) => state.Auth);

  const isSelfPost = post.sender.uuid === authUser?.uuid;

  const [getUser] = useLazyFindOneFromUserControllerQuery();

  const showPostActions = () => {
    const actions: ActionSheetAction[] = [
      {
        text: 'Report Post',
        onPress: () => {},
      },
      {
        text: 'Hide Post',
        onPress: () => {},
      },
    ];

    showPopup({
      style: { inverted: true, width: '100%' },
      Component: <ActionSheet showCancel actions={actions} />,
      animated: true,
      animation: 'slide',
    });
  };

  const viewUser = () => {
    if (isSelfPost) {
      return;
    }
    getUser(post.sender.uuid).then(({ data: user }) => {
      if (!user) {
        showToast({ type: 'danger', text: 'User not found' });
        return;
      }

      navigation.navigate('LoggedIn', {
        screen: 'UserProfile',
        params: {
          user,
        },
      });
    });
  };

  const onImageClick = () => {
    if (!post.photo) {
      return;
    }
    showPopup({
      Component: <ImageViewer url={post.photo} />,
      animated: true,
      centered: true,
      style: {
        width: '100%',
        height: 'auto',
      },
    });
  };

  return (
    <ThemedView>
      <ThemedCard
        header={
          <PostHeader
            onAvatarClick={viewUser}
            post={post}
            onShowPostActions={showPostActions}
          />
        }
        body={<PostBody onImageClick={onImageClick} post={post} />}
        footer={<PostFooter post={post} />}
      />
    </ThemedView>
  );
});

export default Post;
