import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { useAppSelector } from '../hooks/store';
import CustomText from './Basic/CustomText';

const BubbleContainer = styled.View`
  position: absolute;
  top: -5px;
  right: -5px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${(props) => props.theme.primary};
`;

const BubbleText = styled(CustomText)`
  color: #ffffff;
`;

export default function ChatsBubble() {
  const chats = useAppSelector((state) => state.Chat);

  const totalUnreadchats = useMemo(() => {
    let unread = 0;
    for (const chat of chats) {
      const lastChatMessageDate = chat.messages[0].date;

      if (!chat.lastOpened || lastChatMessageDate > chat.lastOpened) {
        unread++;
      }
    }

    return unread;
  }, [chats]);

  if (totalUnreadchats === 0) {
    return null;
  }

  return (
    <BubbleContainer>
      <BubbleText testID={'unreadChatsNotification'} note>
        {totalUnreadchats}
      </BubbleText>
    </BubbleContainer>
  );
}
