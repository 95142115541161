import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { LoggedInTabParamList } from '../../types';
import {
  CreateChatRequestDto,
  CreateMessageRequestDto,
  useCreateFromChatControllerMutation,
  useCreateMessageFromChatControllerMutation,
  useLazyFindByChatUuidFromChatControllerQuery,
  useSetChatNicknameFromChatControllerMutation,
  useUpdateLastOpenedFromChatControllerMutation,
} from '../api/chat';
import { useLazyFindOneFromUserControllerQuery } from '../api/user';
import ActionSheet, {
  ActionSheetAction,
} from '../components/Basic/ActionSheet';
import CustomModalInput from '../components/Basic/CustomModalInput';
import CustomView from '../components/Basic/CustomView';
import Chat, { MessageDetailsDto } from '../components/Chat';
import { ChatMembersList } from '../components/ChatMembers';
import PlusAvatar from '../components/PlusAvatar';
import { ThemedIcon, ThemedImage, ThemedText } from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import { usePopup } from '../hooks/usePopup';
import useTheme from '../hooks/useTheme';
import { NotificationManager } from '../services/notification';
import { objectToFormData } from '../utils';

export type ChatScreenParams = {
  chatId: number;
};

interface ImageDetails {
  uri: string;
  name: string;
  type: string;
}

const MAX_USERS_TO_RENDER = 3;

export default function ContactChat() {
  const navigation = useNavigation();
  const { showToast, showPopup, hidePopup } = usePopup();
  const { palette } = useTheme();

  const {
    params: { chatUuid: existingChatId, users },
  } = useRoute<RouteProp<LoggedInTabParamList, 'Chat'>>();

  const [sendMessage, { error: messageSendError }] =
    useCreateMessageFromChatControllerMutation();
  const [updateLastOpened] = useUpdateLastOpenedFromChatControllerMutation();
  const [createNewChat, { data: newChatData }] =
    useCreateFromChatControllerMutation();
  const [getUser] = useLazyFindOneFromUserControllerQuery();

  const [setNickname] = useSetChatNicknameFromChatControllerMutation();

  const [chatUuid, setChatId] = useState<string | undefined>(existingChatId);

  const chats = useAppSelector((state) => state.Chat);
  const chat = useMemo(
    () => Object.values(chats).find((c) => c.uuid === chatUuid),
    [chats, chatUuid]
  );

  const [fetchMessages] = useLazyFindByChatUuidFromChatControllerQuery({
    refetchOnFocus: true,
  });

  const onlineTextStatus = users.some((u) => u.isOnline)
    ? 'Active now'
    : users.length === 1
    ? users[0].lastOnline
      ? moment(new Date(users[0].lastOnline).getTime()).fromNow()
      : chatUuid
      ? 'Offline'
      : null
    : null;

  useEffect(() => {
    if (messageSendError) {
      showToast({ text: 'Something went wrong', buttonText: 'Ok' });
    }
  }, [messageSendError]);

  const viewUser = () => {
    if (chat?.users.length === 1) {
      getUser(chat.users[0].user.uuid).then(({ data: user }) => {
        if (!user) {
          showToast({ type: 'danger', text: 'User not found' });
          return;
        }

        navigation.navigate('LoggedIn', {
          screen: 'UserProfile',
          params: {
            user,
          },
        });
      });
    }
  };

  useEffect(() => {
    if (!users.length) {
      return;
    }

    const moreThanMaxToRenderUsers = users.length > MAX_USERS_TO_RENDER;
    const numberOfUsersToRender =
      users.length > MAX_USERS_TO_RENDER ? MAX_USERS_TO_RENDER : users.length;

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            margin: 10,
          }}
        >
          <CustomView
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'absolute',
            }}
          >
            {users.slice(0, numberOfUsersToRender).map((u, index) => (
              <ThemedImage
                containerStyle={{
                  left: index > 0 ? -(25 * (index + 1)) : -25,
                }}
                onClick={viewUser}
                avatar
                key={`avatar${index}`}
                uri={u.avatar}
              />
            ))}
            {moreThanMaxToRenderUsers && (
              <PlusAvatar
                style={{ left: -(25 * (numberOfUsersToRender + 1)) }}
              />
            )}
          </CustomView>
          <CustomView
            style={{
              left:
                (moreThanMaxToRenderUsers
                  ? MAX_USERS_TO_RENDER + 1
                  : users.length) *
                  25 +
                15,
              maxWidth: 200,
            }}
          >
            <CustomView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 2,
              }}
            >
              <ThemedText
                style={{
                  fontSize: 15,
                  maxWidth: '80%',
                }}
                numberOfLines={2}
              >
                {chat?.nickname ?? users.map((cu) => cu.username).join(', ')}
              </ThemedText>
            </CustomView>
          </CustomView>
        </View>
      ),

      headerRight: () =>
        chatUuid && (
          <TouchableOpacity
            style={{ marginRight: 10 }}
            onPress={handleChangeNickname}
          >
            <ThemedIcon type="Entypo" name={'dots-three-vertical'} />
          </TouchableOpacity>
        ),
    });

    return () => {
      if (chatUuid) {
        updateLastOpened(chatUuid);
      }
    };
  }, [chat?.nickname]);

  const showChatMembersList = () => {
    showPopup({
      Component: <ChatMembersList members={chat!.users.map((cu) => cu.user)} />,
      animated: true,
      style: {
        left: '10%',
        width: '80%',
        top: '20%',
        height: '60%',
      },
    });
  };

  const handleChangeNickname = () => {
    if (!chatUuid) {
      return;
    }

    const actions: ActionSheetAction[] = [
      {
        text: 'Set nickname',
        onPress: () => {
          (() => {
            showPopup({
              Component: (
                <CustomModalInput
                  onSubmit={(val) => {
                    val &&
                      setNickname({
                        chatSetNicknameRequestDto: { nickname: val },
                        chatUuid: chatUuid,
                      });
                    hidePopup();
                  }}
                  label="Set nickname"
                  placeholder="Nickname"
                />
              ),
              animated: true,
              style: {
                left: '20%',
                width: '60%',
                top: '40%',
              },
            });
          })();
        },
      },
      {
        text: 'View members',
        onPress: showChatMembersList,
      },
    ];

    showPopup({
      style: { inverted: true, width: '100%' },
      Component: <ActionSheet showCancel actions={actions} />,
      animated: true,
      animation: 'slide',
    });
  };

  useEffect(() => {
    if (chatUuid && chat) {
      console.log('fetching');
      fetchMessages(chat.uuid);

      NotificationManager.getInstance().socket.joinRoom(chatUuid);

      return () => {
        NotificationManager.getInstance().socket.leaveRoom(chatUuid);
      };
    }
  }, [chatUuid]);

  useEffect(() => {
    if (newChatData && newChatData.uuid) {
      setChatId(newChatData.uuid);
    }
  }, [newChatData]);

  const getPhoto = async (image: ImageDetails): Promise<Blob> => {
    const data = await fetch(image.uri);
    const blob = await data.blob();
    return blob;
  };

  const onSend = useCallback(
    async (message: MessageDetailsDto) => {
      if (chatUuid) {
        const messageData: CreateMessageRequestDto = {
          chatUuid,
          text: message.text ?? '',
        };

        if (message.photo) {
          messageData.photo = await getPhoto(message.photo as ImageDetails);
        }

        if (message.video) {
          messageData.video = await getPhoto(message.video as ImageDetails);
        }

        // @ts-ignore
        sendMessage(objectToFormData(messageData));
      } else {
        const newChatData: CreateChatRequestDto = {
          text: message.text ?? '',
          recipientsUuid: users.map((u) => u.uuid),
        };

        if (message.photo) {
          newChatData.photo = await getPhoto(message.photo as ImageDetails);
        }

        if (message.video) {
          newChatData.video = await getPhoto(message.video as ImageDetails);
        }

        // @ts-ignore
        createNewChat(objectToFormData(newChatData));
      }
    },
    [chatUuid]
  );

  return <Chat messages={chat?.messages ?? []} onMessageSend={onSend} />;
}
