import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { postApi, PostDto } from '../../api/post';
import { commentApi } from '../../api/comment';
import { likeApi } from '../../api/like';

const initialState: PostDto[] = [];

const PostsSlice = createSlice({
  name: 'post',
  initialState: initialState,
  reducers: {
    clearPosts() {
      return initialState;
    },
    unlikePost(
      state,
      action: PayloadAction<{ postUuid: string; userUuid: string }>
    ) {
      const post = state.find((post) => post.uuid === action.payload.postUuid);

      if (post) {
        const likeIndex = post.likes.findIndex(
          (like) => like.user.uuid === action.payload.userUuid
        );

        post.likes.splice(likeIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      postApi.endpoints.findLatestFromPostController.matchFulfilled,
      (state, { payload, meta }) => {
        if (meta.arg.originalArgs) {
          return [...state, ...payload];
        } else {
          return payload;
        }
      }
    );
    builder.addMatcher(
      postApi.endpoints.createFromPostController.matchFulfilled,
      (state, { payload }) => {
        // state.unshift(payload);
      }
    );
    builder.addMatcher(
      likeApi.endpoints.createFromLikeController.matchFulfilled,
      (state, { payload }) => {
        const post = state.find((post) => post.uuid === payload.postUuid);

        if (post) {
          post.likes.unshift(payload);
        }
      }
    );
    builder.addMatcher(
      commentApi.endpoints.findByPostUuidFromCommentController.matchFulfilled,
      (state, { payload }) => {
        if (payload.length === 0) {
          return;
        }
        const postUuid = payload[0].postUuid;
        const post = state.find((post) => post.uuid === postUuid);

        if (post) {
          post.comments = payload;
        }
      }
    );
    builder.addMatcher(
      commentApi.endpoints.createFromCommentController.matchFulfilled,
      (state, { payload }) => {
        const post = state.find((post) => post.uuid === payload.postUuid);

        if (post) {
          post.comments.unshift(payload);
        }
      }
    );
  },
});

export const { unlikePost, clearPosts } = PostsSlice.actions;
export default PostsSlice.reducer;
