import React from 'react';
import CustomButton, { CustomButtonProps } from './CustomButton';
import styled from 'styled-components/native';

interface FloatingButtonProps extends CustomButtonProps {
  icon?: JSX.Element;
  position?:
    | 'BOTTOM-RIGHT'
    | 'BOTTOM-CENTER'
    | 'BOTTOM-LEFT'
    | 'TOP-LEFT'
    | 'TOP-CENTER'
    | 'TOP-RIGHT';
}

const FloatingButton = styled<React.ComponentType<FloatingButtonProps>>(
  CustomButton
)`
  border-radius: 25px;
  position: absolute;
  bottom: ${(props) =>
    props.position?.startsWith('BOTTOM') ? 100 : undefined}px;
  top: ${(props) => (props.position?.startsWith('TOP') ? 100 : undefined)}px;
  right: ${(props) => (props.position?.endsWith('RIGHT') ? 50 : undefined)}px;
  left: ${(props) => (props.position?.endsWith('LEFT') ? 50 : undefined)}px;
  width: 50px;
  height: 50px;
  z-index: 1000;
`;

export default function CustomFloatingButton(props: FloatingButtonProps) {
  return (
    <FloatingButton position={'BOTTOM-RIGHT'} {...props}>
      {props.icon && props.icon}
    </FloatingButton>
  );
}
