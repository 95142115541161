import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFromPostController: build.mutation<
      CreateFromPostControllerApiResponse,
      CreateFromPostControllerApiArg
    >({
      query: (queryArg) => ({ url: `/post`, method: 'POST', body: queryArg }),
    }),
    findUserPostsFromPostController: build.query<
      FindUserPostsFromPostControllerApiResponse,
      FindUserPostsFromPostControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/post/user/${queryArg.userUuid}`,
        params: { lastPostUuid: queryArg.lastPostUuid },
      }),
    }),
    findLatestFromPostController: build.query<
      FindLatestFromPostControllerApiResponse,
      FindLatestFromPostControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/post/latest`,
        params: { lastPostUuid: queryArg },
      }),
    }),
    findOneFromPostController: build.query<
      FindOneFromPostControllerApiResponse,
      FindOneFromPostControllerApiArg
    >({
      query: (queryArg) => ({ url: `/post/${queryArg}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as postApi };
export type CreateFromPostControllerApiResponse = /** status 200  */
  | PostDto
  | /** status 201  */ PostDto;
export type CreateFromPostControllerApiArg = PostCreateRequestDto;
export type FindUserPostsFromPostControllerApiResponse =
  /** status 200  */ PostDto[];
export type FindUserPostsFromPostControllerApiArg = {
  lastPostUuid?: string;
  userUuid: string;
};
export type FindLatestFromPostControllerApiResponse =
  /** status 200  */ PostDto[];
export type FindLatestFromPostControllerApiArg = string;
export type FindOneFromPostControllerApiResponse = /** status 200  */ PostDto;
export type FindOneFromPostControllerApiArg = string;
export type PostUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type LikeUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type LikeDto = {
  uuid: string;
  date: string;
  userId: number;
  postUuid: string;
  user: LikeUserDto;
};
export type CommentUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type CommentDto = {
  uuid: string;
  postUuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  sender: CommentUserDto;
};
export type PostDto = {
  uuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  senderId: number;
  sender: PostUserDto;
  likes: LikeDto[];
  comments: CommentDto[];
};
export type PostCreateRequestDto = {
  text?: string;
  photo?: object;
  video?: object;
  externalUrlPost?: string;
};
export const {
  useCreateFromPostControllerMutation,
  useFindUserPostsFromPostControllerQuery,
  useLazyFindUserPostsFromPostControllerQuery,
  useFindLatestFromPostControllerQuery,
  useLazyFindLatestFromPostControllerQuery,
  useFindOneFromPostControllerQuery,
  useLazyFindOneFromPostControllerQuery,
} = injectedRtkApi;
