import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    findAllFromNotificationController: build.query<
      FindAllFromNotificationControllerApiResponse,
      FindAllFromNotificationControllerApiArg
    >({
      query: () => ({ url: `/notification` }),
    }),
    markAsReadFromNotificationController: build.mutation<
      MarkAsReadFromNotificationControllerApiResponse,
      MarkAsReadFromNotificationControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/notification/${queryArg}/read`,
        method: 'PUT',
      }),
    }),
    markAsUnreadFromNotificationController: build.mutation<
      MarkAsUnreadFromNotificationControllerApiResponse,
      MarkAsUnreadFromNotificationControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/notification/${queryArg}/unread`,
        method: 'PUT',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as notificationApi };
export type FindAllFromNotificationControllerApiResponse =
  /** status 200  */ NotificationDto[];
export type FindAllFromNotificationControllerApiArg = void;
export type MarkAsReadFromNotificationControllerApiResponse =
  /** status 200  */ NotificationDto;
export type MarkAsReadFromNotificationControllerApiArg = string;
export type MarkAsUnreadFromNotificationControllerApiResponse =
  /** status 200  */ NotificationDto;
export type MarkAsUnreadFromNotificationControllerApiArg = string;
export type ChatUserDetailsDto = {
  username: string;
  avatar: string;
  uuid: string;
  isOnline?: boolean;
  lastOnline?: string;
};
export type MessageDto = {
  uuid: string;
  chatUuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  sender: ChatUserDetailsDto;
};
export type ChatUserDto = {
  lastOpened: string | null;
  chatUuid: string;
  user: ChatUserDetailsDto;
};
export type ChatDto = {
  uuid: string;
  nickname?: string;
  date: string;
  messages: MessageDto[];
  users: ChatUserDto[];
  lastOpened?: string;
};
export type PostUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type LikeUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type LikeDto = {
  uuid: string;
  date: string;
  userId: number;
  postUuid: string;
  user: LikeUserDto;
};
export type CommentUserDto = {
  uuid: string;
  username: string;
  avatar: string;
};
export type CommentDto = {
  uuid: string;
  postUuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  sender: CommentUserDto;
};
export type PostDto = {
  uuid: string;
  text: string | null;
  photo: string | null;
  video: string | null;
  date: string;
  senderId: number;
  sender: PostUserDto;
  likes: LikeDto[];
  comments: CommentDto[];
};
export type NotificationDto = {
  uuid: string;
  type: Type;
  userUuid: string;
  read: boolean;
  description: string | null;
  date: string;
  chat?: ChatDto;
  message?: MessageDto;
  post?: PostDto;
  comment?: CommentDto;
  like?: LikeDto;
};
export enum Type {
  Post = 'POST',
  Comment = 'COMMENT',
  Like = 'LIKE',
  Chat = 'CHAT',
  Message = 'MESSAGE',
  User = 'USER',
  System = 'SYSTEM',
}
export const {
  useFindAllFromNotificationControllerQuery,
  useLazyFindAllFromNotificationControllerQuery,
  useMarkAsReadFromNotificationControllerMutation,
  useMarkAsUnreadFromNotificationControllerMutation,
} = injectedRtkApi;
