/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import {
  HomeTabParamList,
  LoggedInTabParamList,
  LoggedOutTabParamList,
  RootStackParamList,
} from '../../types';
import CustomModal from '../components/Basic/CustomModal';
import CustomToast from '../components/Basic/CustomToast';
import FollowersList from '../components/FollowersList';
import { useAppSelector } from '../hooks/store';
import useColorScheme from '../hooks/useColorScheme';
import { usePopup } from '../hooks/usePopup';
import useTheme, { Theme } from '../hooks/useTheme';
import NewPost from '../modals/NewPost';
import AddBot from '../screens/AddBot';
import AI from '../screens/AI';
import BlockedUsers from '../screens/BlockedUsers';
import ContactChat from '../screens/Chat';
import Chats from '../screens/Chats';
import Comments from '../screens/Comments';
import CreateChat from '../screens/CreateChat';
import FindPeople from '../screens/FindPeople';
import Home from '../screens/Home';
import Login from '../screens/Login';
import NotificationsScreen from '../screens/Notifications';
import SinglePost from '../screens/Post';
import Profile from '../screens/Profile';
import Register from '../screens/Register';
import Settings from '../screens/Settings';
import UserProfile from '../screens/UserProfile';
import HeaderRight from './HeaderRight';
import LinkingConfiguration from './LinkingConfiguration';
import TabBar from './TabBar';

export default function Navigation({}: {}) {
  const { currentTheme } = useTheme();
  const { activePopup, activeToast, hidePopup, hideToast } = usePopup();

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={currentTheme === Theme.DARK ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
      <CustomModal modal={activePopup} hideModal={hidePopup} />
      <CustomToast toast={activeToast} hideToast={hideToast} />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const user = useAppSelector((state) => state.Auth);

  const loggedIn = user.accessToken || false;

  return (
    <Stack.Navigator
      initialRouteName={'Auth'}
      screenOptions={{ headerShown: false }}
    >
      {loggedIn && (
        <Stack.Screen name="LoggedIn" component={LoggedInTabNavigator} />
      )}
      {!loggedIn && <Stack.Screen name="Auth" component={AuthNavigator} />}
    </Stack.Navigator>
  );
}

const LoggedInTab = createStackNavigator<LoggedInTabParamList>();

function LoggedInTabNavigator() {
  const { palette } = useTheme();
  return (
    <LoggedInTab.Navigator
      initialRouteName={'Home'}
      screenOptions={{
        animationEnabled: true,
        cardStyle: { backgroundColor: palette.mainContainer },
        headerShown: true,
        headerShadowVisible: false,
        headerBackgroundContainerStyle: {
          backgroundColor: 'transparent',
          shadowOpacity: 0,
          borderBottomWidth: 0,
        },
        headerStyle: {
          backgroundColor: palette.header,
          borderBottomColor: 'transparent',
        },
        headerTitleStyle: {
          fontSize: 20,
          fontFamily: 'BalsamiqSans_400Regular',
        },
        headerTintColor: palette.headerText,
      }}
    >
      <LoggedInTab.Screen
        options={{
          headerShown: false,
          headerShadowVisible: false,
          headerBackTitleVisible: false,
        }}
        name="Home"
        component={BottomTabNavigator}
      />
      <LoggedInTab.Screen
        name="NewPost"
        options={{
          presentation: 'modal',
        }}
        component={NewPost}
      />
      <LoggedInTab.Screen
        name="UserProfile"
        options={{
          presentation: 'modal',
        }}
        component={UserProfile}
      />
      <LoggedInTab.Screen
        name="FollowersList"
        options={{
          presentation: 'modal',
        }}
        component={FollowersList}
      />
      <LoggedInTab.Screen
        name="BlockedUsers"
        options={{
          presentation: 'modal',
        }}
        component={BlockedUsers}
      />
      <LoggedInTab.Screen
        name="CreateChat"
        options={{
          presentation: 'modal',
        }}
        component={CreateChat}
      />
      <LoggedInTab.Screen
        name="Settings"
        options={{
          presentation: 'modal',
        }}
        component={Settings}
      />
      <LoggedInTab.Screen
        name="Notifications"
        options={{
          presentation: 'modal',
        }}
        component={NotificationsScreen}
      />
      <LoggedInTab.Screen
        name="Post"
        options={{
          presentation: 'modal',
        }}
        component={SinglePost}
      />
      <LoggedInTab.Screen
        name="Comments"
        options={{
          presentation: 'modal',
        }}
        component={Comments}
      />
      <LoggedInTab.Screen
        name="AI"
        options={{
          presentation: 'modal',
        }}
        component={AI}
      />
      <LoggedInTab.Screen name="AddBot" component={AddBot} />
      <LoggedInTab.Screen name="Chat" component={ContactChat} />
    </LoggedInTab.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<HomeTabParamList>();

function BottomTabNavigator() {
  const { palette } = useTheme();

  return (
    <BottomTab.Navigator
      safeAreaInsets={{ bottom: 0 }}
      initialRouteName="NewsFeed"
      sceneContainerStyle={{
        backgroundColor: palette.mainContainer,
      }}
      tabBar={(props) => <TabBar {...props} />}
      screenOptions={{
        headerRight: HeaderRight,
        headerShadowVisible: false,
        headerBackgroundContainerStyle: {
          backgroundColor: 'transparent',
          shadowOpacity: 0,
          borderBottomWidth: 0,
        },
        headerStyle: {
          backgroundColor: palette.header,
          borderBottomColor: 'transparent',
        },
        headerTitleStyle: {
          fontSize: 20,
          fontFamily: 'BalsamiqSans_400Regular',
        },
        headerTintColor: palette.headerText,
      }}
    >
      <BottomTab.Screen name="NewsFeed" component={Home} />
      <BottomTab.Screen name="Chats" component={Chats} />
      <BottomTab.Screen name="FindPeople" component={FindPeople} />
      <BottomTab.Screen name="Profile" component={Profile} />
    </BottomTab.Navigator>
  );
}

const AuthTab = createNativeStackNavigator<LoggedOutTabParamList>();

function AuthNavigator() {
  const colorScheme = useColorScheme();

  return (
    <AuthTab.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="Login"
    >
      <AuthTab.Screen name="Login" component={Login} />
      <AuthTab.Screen name="Register" component={Register} />
    </AuthTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
