import { io, Socket } from 'socket.io-client';
import { NotificationDto } from '../../api/notification';
import { SOCKET_URL } from '../../config';
import store from '../../store';

import { NotificationService } from './notification';

interface ServerToClientEvents {
  newNotification: (notification: NotificationDto) => void;
  noArg: () => void;
  basicEmit: (a: number, b: string, c: Buffer) => void;
  withAck: (d: string, callback: (e: number) => void) => void;
  hello: () => void;
}

interface ClientToServerEvents {
  hello: () => void;
  joinRoom: (chatUuid: string) => void;
  leaveRoom: (chatUuid: string) => void;
}

export default class SocketService extends NotificationService {
  private socket?: Socket<ServerToClientEvents, ClientToServerEvents>;

  constructor() {
    super();

    this.init();
  }

  init() {
    if (!this.socket) {
      const token = store.getState().Auth.accessToken;
      this.socket = io(`${SOCKET_URL}`, {
        extraHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });

      this.socket.on('newNotification', this.handleEvent.bind(this));
      this.socket.on('connect', () => console.log('Connected to Socket'));
      this.socket.on('connect_error', (e) =>
        console.error('Error Connecting to Socket', e)
      );
    }
  }

  public joinRoom(roomId: string) {
    this.socket?.emit('joinRoom', roomId);
  }

  public leaveRoom(roomId: string) {
    this.socket?.emit('leaveRoom', roomId);
  }

  private handleEvent(message: NotificationDto) {
    console.debug('Received notification  in socket.io', message);

    this.handleNotification(message);
  }
}
