import React from 'react';
import { View } from 'react-native';
import { usePopup } from '../hooks/usePopup';
import CustomView from './Basic/CustomView';
import { ThemedImage } from './Themed';

export default function ImageViewer({ url }: { url: string }) {
  const { hidePopup } = usePopup();
  return (
    <CustomView
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
      }}
    >
      <ThemedImage uri={url} />
    </CustomView>
  );
}
