import { StatusBar } from 'expo-status-bar';
import React, { useEffect } from 'react';
import 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import useCachedResources from './src/hooks/useCachedResources';
import { CustomPopupProvider } from './src/hooks/usePopup';
import { CustomThemeProvider } from './src/hooks/useTheme';
import Navigation from './src/navigation';
import store, { persistor } from './src/store';

export default function App() {
  const isLoadingComplete = useCachedResources();

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overscrollBehaviorY = 'none';
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Provider store={store}>
          <CustomPopupProvider>
            <CustomThemeProvider>
              <PersistGate loading={null} persistor={persistor}>
                <Navigation />
                <StatusBar />
              </PersistGate>
            </CustomThemeProvider>
          </CustomPopupProvider>
        </Provider>
      </SafeAreaProvider>
    );
  }
}
