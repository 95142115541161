import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import { LoggedInTabParamList } from '../../types';
import {
  FollowerDto,
  useFindUserFollowersFromFollowerControllerQuery,
  useFindUserFollowingFromFollowerControllerQuery,
} from '../api/follower';
import { useLazyFindOneFromUserControllerQuery } from '../api/user';
import { usePopup } from '../hooks/usePopup';
import useTheme from '../hooks/useTheme';
import CustomView from './Basic/CustomView';
import PageLoading from './Basic/PageLoading';
import { ThemedFlatlist, ThemedImage, ThemedText } from './Themed';

const Separator = styled.View`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default function FollowersList() {
  const {
    params: { userUuid, type },
  } = useRoute<RouteProp<LoggedInTabParamList, 'FollowersList'>>();

  const { showToast } = usePopup();
  const navigation = useNavigation();

  const { data: followers, isLoading } =
    type === 'followers'
      ? useFindUserFollowersFromFollowerControllerQuery(userUuid, {
          refetchOnMountOrArgChange: true,
        })
      : useFindUserFollowingFromFollowerControllerQuery(userUuid, {
          refetchOnMountOrArgChange: true,
        });

  const [getUser] = useLazyFindOneFromUserControllerQuery();

  const viewUser = (user: FollowerDto) => {
    getUser(user.uuid).then(({ data: user }) => {
      if (!user) {
        showToast({ type: 'danger', text: 'User not found' });
        return;
      }

      navigation.navigate('LoggedIn', {
        screen: 'UserProfile',
        params: {
          user,
        },
      });
    });
  };

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <ThemedFlatlist
      data={followers}
      ItemSeparatorComponent={Separator}
      ListHeaderComponentStyle={{ marginBottom: 50 }}
      renderItem={(item) => (
        <RenderPersonResult
          onClick={() => viewUser(item.item)}
          key={`chat${item.item.uuid}`}
          user={item.item}
        />
      )}
    />
  );
}

const RenderPersonResult = ({
  user,
  onClick,
}: {
  user: FollowerDto;
  onClick: () => void;
}) => {
  const { palette } = useTheme();
  return (
    <TouchableOpacity onPress={onClick}>
      <CustomView
        testID={`user-${user.uuid}`}
        style={[
          {
            padding: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: palette.listItem,
            flex: 1,
            borderRadius: 10,
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {user.avatar && (
            <CustomView>
              <ThemedImage avatar uri={user.avatar} />
            </CustomView>
          )}
          <CustomView style={{ marginLeft: 10, maxWidth: 150 }}>
            <CustomView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ThemedText
                numberOfLines={1}
                style={{
                  fontSize: 15,
                }}
              >
                {user.username}
              </ThemedText>
            </CustomView>
          </CustomView>
        </View>
      </CustomView>
    </TouchableOpacity>
  );
};
