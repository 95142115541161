import React from 'react';
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { RefreshControl } from 'react-native-gesture-handler';

type Props = {
  style?: StyleProp<ViewStyle>;
  children?: any;
  scrollable?: boolean;
};

const CustomContainer = (props: Props) => {
  const { children, style = {}, scrollable = true } = props;

  if (scrollable) {
    return (
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        refreshControl={<RefreshControl refreshing={false} />}
        style={[basicStyles.default, style]}
      >
        {children}
      </ScrollView>
    );
  }
  return <View style={[basicStyles.default, style]}>{children}</View>;
};

const basicStyles = StyleSheet.create({
  default: { display: 'flex', flex: 1 },
});

export default CustomContainer;
