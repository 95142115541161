import { NotificationDto } from '../../api/notification';

export type NotificationType = NotificationDto['type'];

export interface NotificationSubscriber {
  type?: NotificationType[];
  callback: (notification: NotificationDto) => void;
}

export abstract class NotificationService {
  subscribers: NotificationSubscriber[] = [];

  constructor() {}

  protected abstract init(): void;

  public subscribe(subscriber: NotificationSubscriber) {
    this.subscribers.push(subscriber);
  }

  public unsubscribe(subscriber: NotificationSubscriber) {
    const subToRemoveIndex = this.subscribers.findIndex(
      (s) => s === subscriber
    );

    if (subToRemoveIndex !== -1) {
      this.subscribers.splice(subToRemoveIndex, 1);
    }
  }

  protected handleNotification(notification: NotificationDto) {
    const subscribersToNotify = this.subscribers.filter(
      (s) => s.type === undefined || s.type.includes(notification.type || '')
    );

    for (const subscriber of subscribersToNotify) {
      subscriber.callback(notification);
    }
  }
}
