import { useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { useDispatch } from 'react-redux';
import { toggleBottomNav } from '../store/reducers/AppData';
import { useAppSelector } from './store';
import { useThrottle } from './useThrottle';

export const useTabbarToggle = () => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const debouncedOffset = useThrottle(offset);
  const tabBarShown = useAppSelector((state) => state.AppData.showBottomNav);

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    return;

    const direction =
      event.nativeEvent.contentOffset.y > debouncedOffset ? 'down' : 'up';

    setOffset(event.nativeEvent.contentOffset.y);

    if (direction === 'down' && tabBarShown) {
      console.debug('hiding tabbar');
      dispatch(toggleBottomNav(false));
    }

    if (direction === 'up' && !tabBarShown) {
      console.debug('showing tabbar');
      dispatch(toggleBottomNav(true));
    }
  };

  return {
    handleScroll,
  };
};
