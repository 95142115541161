import React, { useCallback, useState } from 'react';
import useTheme from '../hooks/useTheme';
import { CustomInputProps } from './Basic/CustomInput';
import { ThemedIcon, ThemedTextInput } from './Themed';

export default function PasswordInput<T>(props: CustomInputProps<T>) {
  const { palette } = useTheme();
  const [visible, setVisible] = useState(false);

  const RenderEye = useCallback(() => {
    return (
      <ThemedIcon
        color={palette.icon}
        onPress={() => setVisible(!visible)}
        type="FontAwesome"
        name={visible ? 'eye' : 'eye-slash'}
        style={{ marginRight: 5 }}
      />
    );
  }, [visible, palette]);

  return (
    <ThemedTextInput
      type="text"
      secure={!visible}
      {...props}
      rightIcon={RenderEye}
    />
  );
}
