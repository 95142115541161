import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import styled from 'styled-components/native';
import * as yup from 'yup';
import {
  CreateUserRequestDto,
  useLoginFromAuthControllerMutation,
  useRegisterFromAuthControllerMutation,
} from '../api/auth';
import CustomContainer from '../components/Basic/CustomContainer';
import {
  ThemedButton,
  ThemedIcon,
  ThemedImage,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { usePopup } from '../hooks/usePopup';
import { percentageHeight } from '../utils';

import { NotificationManager } from '../services/notification/index';
import { Gender, useLazyUserExistsFromUserControllerQuery } from '../api/user';

const RegisterMainSection = styled(CustomContainer)`
  min-height: ${percentageHeight(90)}px;
  background: ${(props) => props.theme.mainContainer};
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  margin-top: ${percentageHeight(5)}px;
  display: flex;
  padding: ${percentageHeight(2)}px ${percentageHeight(3)}px;
  flex: 1;
`;

const RegisterButton = styled(ThemedButton)`
  margin-top: ${percentageHeight(5)}px;
`;

const LoginForm = styled.View``;

const LoginFooter = styled.View`
  margin-top: ${percentageHeight(5)}px;
  align-items: center;
  justify-content: center;
`;

const SignUpButton = styled(Link)``;

const OutsideContainer = styled(CustomContainer)`
  background-color: ${(props) => props.theme.primary};
`;

interface ImageDetails {
  uri: string;
  name: string;
  type: string;
}

type RegisterCredentials = CreateUserRequestDto & {
  confirmPassword: string;
};

const RegisterInput = styled(ThemedTextInput).attrs((props) => ({
  containerStyle: {
    marginBottom: 20,
    // @ts-ignore
    ...props.containerStyle,
  },
}))`` as typeof ThemedTextInput;

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'passwords does not match'),
  email: yup.string().email().required(),
  description: yup.string().required(),
  gender: yup.string().required(),
  dob: yup.string().required(),
  avatar: yup.object(),
});

export default function Register() {
  const [registerUser, { isSuccess, isLoading, error }] =
    useRegisterFromAuthControllerMutation();
  const [loginUser] = useLoginFromAuthControllerMutation();
  const { showToast } = usePopup();

  const [userExists] = useLazyUserExistsFromUserControllerQuery();

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    getValues,
    resetField,
    setError,
  } = useForm<RegisterCredentials>({
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
      description: 'test',
      gender: Gender.Na,
      email: '',
      dob: new Date().toUTCString(),
    },
    resolver: yupResolver(schema),
  });

  const getAvatar = async (imageUri: string): Promise<Blob> => {
    const data = await fetch(imageUri);
    const blob = await data.blob();
    return blob;
  };

  const doRegister = async (values: RegisterCredentials) => {
    const { data: exists } = await userExists(values.username);
    if (exists) {
      setError('username', { message: 'Username already exists' });
      return;
    }

    const registerDataFormData = new FormData();
    registerDataFormData.append('username', values.username);
    registerDataFormData.append('password', values.password);
    registerDataFormData.append('email', values.email);
    registerDataFormData.append('dob', new Date().toISOString());
    registerDataFormData.append('gender', values.gender);
    registerDataFormData.append('description', 'test');

    const deviceToken =
      await NotificationManager.getInstance().firebase.getfirebaseToken();

    registerDataFormData.append('deviceToken', deviceToken ?? '');

    if (image) {
      registerDataFormData.append(
        'avatar',
        await getAvatar((image as ImageDetails).uri)
      );
    } else {
      registerDataFormData.append(
        'avatar',
        await getAvatar('https://api.dicebear.com/9.x/pixel-art/svg')
      );
    }

    // @ts-ignore
    registerUser(registerDataFormData);
  };

  useEffect(() => {
    if (isSuccess) {
      const { username, password } = getValues();
      loginUser({ username, password });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      showToast({
        text: 'Something went wrong',
        type: 'danger',
      });
    }
  }, [error]);

  const image = watch().avatar;

  return (
    <OutsideContainer scrollable={false}>
      <RegisterMainSection>
        <LoginForm>
          <RegisterInput
            testId={'usernameRegisterField'}
            label={'Username'}
            name={'username'}
            control={control}
            error={errors.username?.message}
          />
          <RegisterInput
            testId={'passwordRegisterField'}
            label={'Password'}
            name={'password'}
            control={control}
            secure={true}
            error={errors.password?.message}
          />

          <RegisterInput
            testId={'confirmPasswordRegisterField'}
            label={'Confirm Password'}
            name={'confirmPassword'}
            secure={true}
            control={control}
            error={errors.confirmPassword?.message}
          />

          <RegisterInput
            testId={'emailRegisterField'}
            label={'Email'}
            name={'email'}
            control={control}
            error={errors.email?.message}
          />

          <RegisterInput
            label={'Avatar'}
            name={'avatar'}
            type={'file'}
            control={control}
            renderFileButton={(chooseFile) => (
              <ThemedButton onPress={chooseFile} title="Select avatar" />
            )}
            renderSelectedFile={(value: ImageDetails) => (
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 10,
                  position: 'relative',
                }}
              >
                <ThemedButton
                  onPress={() => resetField('avatar', { keepDirty: false })}
                  transparent
                  style={{ position: 'absolute', right: -50, top: -10 }}
                >
                  <ThemedIcon name={'close'} type="FontAwesome" />
                </ThemedButton>
                <ThemedImage large avatar uri={value.uri} />
              </View>
            )}
          />
        </LoginForm>
        <RegisterButton
          testId={'registerButton'}
          loading={isLoading}
          onPress={handleSubmit(doRegister)}
        >
          <ThemedText>Register</ThemedText>
        </RegisterButton>
        <LoginFooter>
          <ThemedText>
            Already have an account?{' '}
            <SignUpButton to={{ screen: 'Auth', params: { screen: 'Login' } }}>
              Sign in
            </SignUpButton>
          </ThemedText>
        </LoginFooter>
      </RegisterMainSection>
    </OutsideContainer>
  );
}
