import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import {
  useIsFocused,
  useNavigation,
  useScrollToTop,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { api } from '../api/baseApi';
import { useFindByUserIdFromChatControllerQuery } from '../api/chat';
import {
  useLazyFindLatestByGeolocationFromUserControllerQuery,
  useLazyFindLatestFromUserControllerQuery,
  UserDto,
} from '../api/user';
import CustomButton from '../components/Basic/CustomButton';
import CustomView from '../components/Basic/CustomView';
import PageLoading from '../components/Basic/PageLoading';
import {
  ThemedFlatlist,
  ThemedIcon,
  ThemedImage,
  ThemedListItem,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import { useGeolocation } from '../hooks/useGeolocation';
import { useTabbarToggle } from '../hooks/useTabbarToggle';
import useTheme from '../hooks/useTheme';
import { useThrottle } from '../hooks/useThrottle';

const ListHeaderView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PAGE_SIZE = 10;

export default function FindPeople() {
  const [isLocationSearchEnabled, setIsLocationSearchEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { refetch: fetchChats } = useFindByUserIdFromChatControllerQuery();
  const { followers, following } = useAppSelector((state) => state.Follows);
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const { handleScroll } = useTabbarToggle();

  const { location } = useGeolocation();

  const [fetchPeople, { isLoading, data: lastDataResult }] =
    useLazyFindLatestFromUserControllerQuery({ refetchOnFocus: true });

  const [fetchByGeolocation] =
    useLazyFindLatestByGeolocationFromUserControllerQuery();

  const chats = useAppSelector((state) => state.Chat);
  const people = useAppSelector((state) => state.User);
  const [results, setResults] = useState<UserDto[]>(people || []);
  const [search, setSearch] = useState<string>('');
  const searchValue = useThrottle(search, 1000);

  useScrollToTop(ref);

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  useEffect(() => {
    if (isFocused) {
      dispatch(api.util.resetApiState());
      fetchChats();
      fetchPeople({});
    }
  }, [isFocused]);

  useEffect(() => {
    if (isLocationSearchEnabled && location) {
      fetchByGeolocation({ page });
    } else {
      fetchPeople({ search: searchValue });
    }
  }, [isLocationSearchEnabled, page, searchValue]);

  const goToProfile = (user: UserDto) => {
    navigation.navigate('LoggedIn', {
      screen: 'UserProfile',
      params: {
        user,
      },
    });
  };

  useEffect(() => {
    if (search) {
      const foundChats = people?.filter((chat) =>
        chat.username?.toLowerCase().includes(search.toLowerCase())
      );
      setResults(foundChats || []);
    } else {
      setResults(people || []);
    }
  }, [search, people]);

  const tabBarHeight = useBottomTabBarHeight();

  const RenderSeparator = useCallback(
    () => <View style={{ marginVertical: 5 }} />,
    []
  );

  const loadMore = useCallback(() => {
    if (isLocationSearchEnabled) {
      return;
      // setPage(page + 1);
    } else {
      const lastUser = results[results.length - 1];
      if (lastUser && lastDataResult && lastDataResult.length === PAGE_SIZE) {
        fetchPeople({ lastUserUuid: lastUser.uuid, search: searchValue });
      }
    }
  }, [results]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <ThemedFlatlist
      ref={ref}
      onScroll={handleScroll}
      onEndReachedThreshold={0.5}
      onEndReached={loadMore}
      contentContainerStyle={{ paddingBottom: tabBarHeight + 20 }}
      data={results}
      ItemSeparatorComponent={RenderSeparator}
      ListHeaderComponent={
        <ListHeader
          isLocationAvailable={!!location}
          isLocationEnabled={!!isLocationSearchEnabled}
          handleSearch={handleSearch}
          search={search}
          onClick={() => setIsLocationSearchEnabled(!isLocationSearchEnabled)}
        />
      }
      ListHeaderComponentStyle={{ marginBottom: 50 }}
      renderItem={(item) => (
        <RenderPersonResult
          key={`chat${item.item.uuid}`}
          user={item.item}
          goToProfile={goToProfile}
        />
      )}
    />
  );
}

const ListHeader = ({
  search,
  handleSearch,
  isLocationEnabled,
  isLocationAvailable,
  onClick,
}: {
  handleSearch: (val: string) => void;
  search: string;
  isLocationAvailable: boolean;
  isLocationEnabled: boolean;
  onClick: () => void;
}) => {
  const { palette } = useTheme();
  return (
    <ListHeaderView>
      <View style={{ flex: 1 }}>
        <ThemedTextInput
          placeholder={'Search people here..'}
          standalone
          value={search}
          onChange={handleSearch}
        />
      </View>
      {isLocationAvailable && (
        <CustomButton onPress={onClick} transparent>
          <ThemedIcon
            type={'Entypo'}
            name={'location-pin'}
            color={isLocationEnabled ? '#ffffff' : palette.icon}
          />
        </CustomButton>
      )}
    </ListHeaderView>
  );
};

const RenderPersonResult = ({
  user,
  goToProfile,
}: {
  user: UserDto;
  goToProfile: (user: UserDto) => void;
}) => {
  const { palette } = useTheme();

  const goToUser = () => {
    goToProfile(user);
  };

  return (
    <ThemedListItem
      onPress={goToUser}
      rightComponent={
        user.distance
          ? () => (
              <View>
                <ThemedText note>{user.distance!.value}</ThemedText>
                <ThemedText note>{user.distance!.unit}</ThemedText>
              </View>
            )
          : undefined
      }
      leftComponent={() => (
        <>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {user.avatar && (
              <CustomView>
                <ThemedImage avatar uri={user.avatar} />
              </CustomView>
            )}
            <CustomView style={{ marginLeft: 10, maxWidth: 150 }}>
              <CustomView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <ThemedText
                  style={{
                    fontSize: 15,
                  }}
                >
                  {user.username}
                </ThemedText>
              </CustomView>
            </CustomView>
          </View>
        </>
      )}
    />
  );
};
