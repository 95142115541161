import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginFromAuthController: build.mutation<
      LoginFromAuthControllerApiResponse,
      LoginFromAuthControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    registerFromAuthController: build.mutation<
      RegisterFromAuthControllerApiResponse,
      RegisterFromAuthControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/register`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    refreshTokenFromAuthController: build.mutation<
      RefreshTokenFromAuthControllerApiResponse,
      RefreshTokenFromAuthControllerApiArg
    >({
      query: () => ({ url: `/auth/refreshToken`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authApi };
export type LoginFromAuthControllerApiResponse = /** status 200  */
  | AuthUserDto
  | /** status 201  */ AuthUserDto;
export type LoginFromAuthControllerApiArg = AuthLoginUserRequestDto;
export type RegisterFromAuthControllerApiResponse =
  /** status 201  */ AuthUserDto;
export type RegisterFromAuthControllerApiArg = CreateUserRequestDto;
export type RefreshTokenFromAuthControllerApiResponse =
  /** status 200  */ RefreshTokenDto;
export type RefreshTokenFromAuthControllerApiArg = void;
export type AuthUserDto = {
  uuid: string;
  username: string;
  description: string;
  email: string;
  dob: string;
  gender: string;
  avatar: string;
  accessToken: string;
  refreshToken: string | null;
  features: 'CREATE_BOT'[];
  followersCount: number;
  followingCount: number;
};
export type AuthLoginUserRequestDto = {
  username: string;
  password: string;
};
export type CreateUserRequestDto = {
  username: string;
  password: string;
  description: string;
  email: string;
  dob: string;
  gender: Gender;
  externalUrlAvatar?: string;
  deviceToken?: string;
  avatar?: object;
  isBot?: boolean;
};
export type RefreshTokenDto = {
  accessToken: string;
  refreshToken: string;
};
export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Na = 'NA',
}
export const {
  useLoginFromAuthControllerMutation,
  useRegisterFromAuthControllerMutation,
  useRefreshTokenFromAuthControllerMutation,
} = injectedRtkApi;
