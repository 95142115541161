import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import {
  PostCreateRequestDto,
  useCreateFromPostControllerMutation,
} from '../api/post';
import CustomContainer from '../components/Basic/CustomContainer';
import CustomView from '../components/Basic/CustomView';
import {
  ThemedButton,
  ThemedIcon,
  ThemedImage,
  ThemedTextInput,
} from '../components/Themed';
import VideoPlayer from '../components/VideoPlayer';
import { usePopup } from '../hooks/usePopup';
import { objectToFormData } from '../utils';

const NewPostContainer = styled(CustomContainer)`
  margin-bottom: 10px;
  padding: 10px 10px;
  flex: 1;
`;

const NewPostHeader = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const NewPostFooter = styled.View`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FooterButtons = styled(CustomView)`
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FooterIcon = styled(ThemedIcon)`
  margin-right: 20px;
`;

const ImageView = styled.View`
  position: relative;
`;

const CloseIcon = styled(ThemedButton).attrs(() => ({
  full: false,
  style: { borderRadius: 20, overflow: 'hidden' },
}))`
  position: absolute;
  right: 20px;
  top: 20px;
`;

interface MediaDetails {
  uri: string;
  name: string;
  type: string;
}

export default function NewPost() {
  const navigation = useNavigation();
  const { showToast } = usePopup();
  const [postText, setPostText] = useState('');
  const [media, setMedia] = useState<MediaDetails>();
  const [addPost, { data: newPost }] = useCreateFromPostControllerMutation();

  useEffect(() => {
    if (newPost) {
      navigation.goBack();
    }
  }, [newPost]);

  const getPhoto = async (image: MediaDetails): Promise<Blob> => {
    const data = await fetch(image.uri);
    const blob = await data.blob();
    return blob;
  };

  const addNewPost = async () => {
    const hasPhoto = media?.type.endsWith('jpg');
    const hasVideo = media?.type.endsWith('mp4');

    const postData: PostCreateRequestDto = {
      text: postText,
    };

    if (hasPhoto) {
      postData.photo = await getPhoto(media as MediaDetails);
    }

    if (hasVideo) {
      postData.video = await getPhoto(media as MediaDetails);
    }
    // @ts-ignore
    addPost(objectToFormData(postData));
  };

  const chooseImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled && result.assets[0]?.uri) {
      setMedia({
        uri: result.assets[0].uri,
        name: 'SomeImageName.jpg',
        type: 'image/jpg',
      });
    }
  };

  const chooseVideo = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
    });

    if (!result.canceled && result.assets[0]?.uri) {
      if (!result.assets[0].uri.startsWith('data:video/mp4')) {
        showToast({ type: 'danger', text: 'Only .mp4 video is supported' });
        return;
      }
      setMedia({
        uri: result.assets[0].uri,
        name: 'SomeImageName.mp4',
        type: 'video/mp4',
      });
    }
  };

  const removeMedia = () => {
    setMedia(undefined);
  };

  return (
    <NewPostContainer scrollable>
      <NewPostHeader>
        <ThemedTextInput
          testId={'newPostInput'}
          containerStyle={{ flex: 1 }}
          inputContainerStyle={{ paddingHorizontal: 10 }}
          placeholder="Write something..."
          numberOfLines={5}
          value={postText}
          onChange={setPostText}
          standalone
        />
      </NewPostHeader>
      {media && (
        <ImageView>
          {media.type.endsWith('jpg') && <ThemedImage uri={media.uri} />}
          {media.type.endsWith('mp4') && <VideoPlayer uri={media.uri} />}
          <CloseIcon onPress={removeMedia}>
            <Ionicons name={'close'} />
          </CloseIcon>
        </ImageView>
      )}
      <NewPostFooter>
        <FooterButtons>
          <FooterIcon
            onPress={chooseImage}
            size={25}
            type="SimpleLineIcons"
            name="picture"
          />
          <FooterIcon
            onPress={chooseVideo}
            size={25}
            type="SimpleLineIcons"
            name="social-youtube"
          />
        </FooterButtons>
        <ThemedButton
          testId={'createNewPostButton'}
          full={false}
          onPress={addNewPost}
          rounded
          disabled={!postText && !media}
          title={'Post'}
        />
        {/* <FooterIcon type="Entypo" name='emoji-happy' /> */}
      </NewPostFooter>
    </NewPostContainer>
  );
}
