import React, { useEffect, useState } from 'react';
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { ICustomModal } from '../../hooks/usePopup';
import CustomView from './CustomView';

const CustomModal = ({
  modal,
  hideModal: hidePopup,
}: {
  modal?: ICustomModal;
  hideModal: () => void;
}) => {
  const [modalToShow, setModalToShow] = useState<ICustomModal | null>(null);
  const [offset, setOffset] = useState(
    new Animated.Value(Dimensions.get('screen').height)
  );
  const [opacity, setOpacity] = useState(new Animated.Value(0));

  useEffect(() => {
    if (modalToShow) show();
  }, [modalToShow]);

  useEffect(() => {
    if (modal) {
      setModalToShow(modal);
    } else {
      hideModal();
    }
  }, [modal]);

  const close = () => {
    setModalToShow(null);
    hidePopup();
  };

  const handleBackButton = () => {
    hideModal();
    return true;
  };

  const hideModal = () => {
    hide();
    if (Platform.OS !== 'web') {
      BackHandler.removeEventListener('hardwareBackPress', handleBackButton);
    }
  };

  const show = () => {
    if (animation == 'slide') {
      Animated.timing(offset, {
        duration: 300,
        toValue: 0,
        useNativeDriver: true,
      }).start(onAnimationEnded);
    } else {
      Animated.timing(opacity, {
        duration: 100,
        toValue: 1,
        easing: Easing.in(Easing.poly(2)),
        useNativeDriver: true,
      }).start(onAnimationEnded);
    }
    if (Platform.OS !== 'web') {
      BackHandler.addEventListener('hardwareBackPress', handleBackButton);
    }
  };

  const hide = () => {
    if (animation == 'slide') {
      Animated.timing(offset, {
        duration: 300,
        toValue: Dimensions.get('screen').height,
        useNativeDriver: true,
      }).start(close);
    } else {
      Animated.timing(opacity, {
        duration: 100,
        toValue: 0,
        easing: Easing.out(Easing.poly(2)),
        useNativeDriver: true,
      }).start(close);
    }
  };

  if (!modalToShow) return null;

  const {
    Component,
    animated = false,
    onAnimationEnded = () => {},
    animation = 'fade',
  } = modalToShow;

  if (!animated) {
    // @ts-ignore
    return (
      <View style={[styles.modalContainer]}>
        <TouchableWithoutFeedback onPress={hideModal}>
          <View style={{ flex: 1 }} />
        </TouchableWithoutFeedback>
        <View
          style={[
            {
              position: 'absolute',
              width: modalToShow.style?.width ?? 'auto',
              height: modalToShow.style?.height ?? 'auto',
              top: modalToShow.style?.top ?? undefined,
              left: modalToShow.style?.left ?? undefined,
            },
            modalToShow.style?.inverted ? { bottom: 0 } : null,
          ]}
        >
          <CustomView style={{ flex: 1 }}>{Component}</CustomView>
        </View>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.modalContainer,
        modalToShow.centered ? styles.centeredContainer : undefined,
      ]}
    >
      <TouchableWithoutFeedback
        onPress={hideModal}
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      >
        <View style={{ width: '100%', height: '100%' }} />
      </TouchableWithoutFeedback>
      <Animated.View
        style={[
          {
            transform:
              animation == 'slide' ? [{ translateY: offset }] : undefined,
            opacity: animation == 'fade' ? opacity : 1,
            position: 'absolute',
            width: modalToShow.style?.width ?? 'auto',
            height: modalToShow.style?.height ?? 'auto',
            top: modalToShow.style?.top ?? undefined,
            left: modalToShow.style?.left ?? undefined,
          },
          modalToShow.style?.inverted ? { bottom: 0 } : null,
        ]}
      >
        <CustomView style={{ flex: 1 }}>{Component}</CustomView>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: '#111111cc',
  },
  fullScreenContainer: { width: '100%', height: '100%' },
  centeredContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default CustomModal;
