import { Image } from 'expo-image';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  DimensionValue,
  Image as RNImage,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { percentageWidth } from '../../utils';

interface BaseImageProps {
  containerStyle?: StyleProp<ImageStyle>;
  uri: string;
  avatar?: boolean;
  dimensions?: {
    width?: number;
    height?: number;
    maxHeight?: number;
  };
  large?: boolean;
  loadingIndicatorColor?: string;
  onErrorUrl?: string;
  onClick?: () => void;
}

const CustomImage = (props: BaseImageProps) => {
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState<
    { width: DimensionValue; height: DimensionValue } | undefined
  >();
  const [uri, setUri] = useState(props.uri);

  useEffect(() => {
    if (props.avatar) {
      setSize({
        width: props.large ? 100 : 50,
        height: props.large ? 100 : 50,
      });
    } else {
      RNImage.getSize(props.uri, (w, h) => {
        const desiredWidth = props.dimensions?.width ?? percentageWidth(100);
        setSize({
          height: (desiredWidth / w) * h,
          width: '100%',
        });
      });
    }
  }, [uri]);

  useEffect(() => {
    setUri(props.uri);
  }, [props.uri]);

  if (!size) {
    return null;
  }

  return (
    <TouchableOpacity
      disabled={!props.onClick}
      onPress={props.onClick}
      style={[
        {
          width: size.width,
          height: size.height,
          maxHeight: props.dimensions?.maxHeight,
        },
        props.containerStyle,
      ]}
    >
      <Image
        style={[
          {
            height: size.height,
            width: size.width,
          },
          props.avatar ? { borderRadius: Number(size.width) / 2 } : undefined,
        ]}
        contentFit={'cover'}
        onError={() => {
          if (props.onErrorUrl) {
            setUri(props.onErrorUrl);
          }
        }}
        onLoad={() => setLoading(false)}
        source={{ uri: uri || props.onErrorUrl }}
      />
      {loading ? (
        <View
          style={{
            height: size.height,
            width: size.width,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            position: 'absolute',
          }}
        >
          <ActivityIndicator color={props.loadingIndicatorColor ?? 'black'} />
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

export default CustomImage;
