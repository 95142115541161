import React from 'react';
import { View, ViewStyle } from 'react-native';
import useTheme from '../hooks/useTheme';
import { ThemedText } from './Themed';

export default function PlusAvatar({ style }: { style?: ViewStyle }) {
  const { palette } = useTheme();
  return (
    <View
      style={[
        {
          width: 50,
          height: 50,
          borderRadius: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: palette.primary,
        },
        style,
      ]}
    >
      <ThemedText style={{ fontSize: 20 }}>+</ThemedText>
    </View>
  );
}
