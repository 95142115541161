import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import {
  useCreateFromLikeControllerMutation,
  useUnlikeFromLikeControllerMutation,
} from '../../api/like';
import { PostDto } from '../../api/post';
import { useAppSelector } from '../../hooks/store';
import { unlikePost } from '../../store/reducers/Post';
import CustomIcon from '../Basic/CustomIcon';
import { ThemedIcon, ThemedText } from '../Themed';

const FooterContainer = styled.View`
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 10px;
  background: ${(props) => props.theme.primary};
`;

const LikeContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CommentsContainer = styled(LikeContainer)`
  margin-left: 10px;
`;

const LikeCommentCount = styled(ThemedText)`
  margin-left: 5px;
  margin-right: 5px;
`;

export default function PostFooter({ post }: { post: PostDto }) {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [likePost] = useCreateFromLikeControllerMutation();
  const [unlikePostApi, { isSuccess: postUnliked }] =
    useUnlikeFromLikeControllerMutation();

  const user = useAppSelector((state) => state.Auth);

  const userLiked = useMemo(
    () => post.likes.some((like) => like.user.uuid === user?.uuid),
    [user, post]
  );

  useEffect(() => {
    if (postUnliked) {
      dispatch(unlikePost({ postUuid: post.uuid, userUuid: user.uuid }));
    }
  }, [postUnliked]);

  const showComments = useCallback(() => {
    navigation.navigate('LoggedIn', {
      screen: 'Comments',
      params: { postUuid: post.uuid },
    });
  }, []);

  const like = useCallback(() => {
    if (userLiked) {
      unlikePostApi(post.uuid);
    } else {
      likePost({ postUuid: post.uuid });
    }
  }, [userLiked]);

  return (
    <FooterContainer>
      <LikeContainer testID={`likePostButton-${post.uuid}`} onPress={like}>
        <ThemedIcon
          type="MaterialCommunityIcons"
          name={userLiked ? 'cards-heart' : 'cards-heart-outline'}
        />
        <LikeCommentCount note testID={`likePostCount-${post.uuid}`}>
          {post?.likes?.length || ''}
        </LikeCommentCount>
      </LikeContainer>
      <CommentsContainer
        testID={`commentPostButton-${post.uuid}`}
        onPress={showComments}
      >
        <ThemedIcon type="MaterialCommunityIcons" name={'chat-outline'} />
        <LikeCommentCount note>{post?.comments?.length || ''}</LikeCommentCount>
      </CommentsContainer>
    </FooterContainer>
  );
}
