import React from 'react';
import { usePopup } from '../../hooks/usePopup';
import useTheme from '../../hooks/useTheme';
import { generateRandomUUid } from '../../utils';
import {
  ThemedButton,
  ThemedContainer,
  ThemedText,
  ThemedView,
} from '../Themed';

export interface ActionSheetAction {
  text: string;
  onPress: () => void;
  onPressClose?: boolean;
}

interface ActionSheetProps {
  actions: ActionSheetAction[];
  showCancel?: boolean;
}

export default function ActionSheet({ actions, showCancel }: ActionSheetProps) {
  const { palette } = useTheme();
  return (
    <ThemedContainer
      style={{
        backgroundColor: palette.mainContainer,
        padding: 5,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
      }}
    >
      <ThemedView style={{ alignItems: 'flex-start' }}>
        {actions.map((action) => (
          <Action key={`action-${generateRandomUUid()}`} action={action} />
        ))}
        {showCancel && (
          <Action
            key={`action-${generateRandomUUid()}`}
            action={{ text: 'Cancel', onPress: () => {}, onPressClose: true }}
          />
        )}
      </ThemedView>
    </ThemedContainer>
  );
}

const Action = ({
  action: { onPress, text, onPressClose },
}: {
  action: ActionSheetAction;
}) => {
  const { hidePopup } = usePopup();

  const onActionPress = () => {
    onPress();
    onPressClose && hidePopup();
  };

  return (
    <ThemedButton
      full={false}
      onPress={onActionPress}
      // leftIcon={() => <ThemedIcon type="SimpleLineIcons" name="logout" />}
      transparent
    >
      <ThemedText style={{ padding: 10 }}>{text}</ThemedText>
    </ThemedButton>
  );
};
