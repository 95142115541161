import * as ImagePicker from 'expo-image-picker';
import React, { useMemo } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import {
  KeyboardTypeOptions,
  Platform,
  StyleProp,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import CustomButton from './CustomButton';
import CustomSelect from './CustomSelect';
import CustomText from './CustomText';
import CustomView from './CustomView';

interface SelectData<T> {
  fieldName: keyof T;
  valueField: keyof T;
  data: T[];
  renderButton?: (showModal: () => void) => void;
  multiple?: boolean;
  fullScreen?: boolean;
}

interface BasicInputProps {
  leftIcon?: () => JSX.Element;
  rightIcon?: () => JSX.Element;
  secure?: boolean;
  label?: string;
  containerStyle?: StyleProp<ViewStyle>;
  labelContainerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  placeHolderTextColor?: string;
  inputTextStyle?: StyleProp<TextStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  numberOfLines?: number;
  multiline?: boolean;
  placeholder?: string;
  error?: string;
  errorTextStyle?: StyleProp<TextStyle>;
  standalone?: boolean;
  disabled?: boolean;
  testId?: string;
}

interface TextInputProps extends BasicInputProps {
  type?: 'text' | 'number';
}

interface SelectInputPops<T> extends BasicInputProps {
  type: 'select';
  selectData: SelectData<T>;
  selectButtonStyle?: StyleProp<ViewStyle>;
  selectButtonTextStyle?: StyleProp<TextStyle>;
  search?: boolean;
}

interface FileInputPops extends BasicInputProps {
  type: 'file';
  renderFileButton?: (chooseFile: () => void) => JSX.Element;
  fileButtonStyle?: StyleProp<ViewStyle>;
  fileButtonTextStyle?: StyleProp<TextStyle>;
  renderSelectedFile?: (value: any) => JSX.Element;
}

type TypedInputProps<T> = TextInputProps | SelectInputPops<T> | FileInputPops;

type StandaloneInputProps<T> = TypedInputProps<T> & {
  standalone: true;
  onChange?: (value: T) => void;
  value: T;
};

type ControlledInput<T> = TypedInputProps<T> & {
  // TODO: fix me
  // @ts-ignore
  control: Control<T>;
  // TODO: fix me
  // @ts-ignore
  name: FieldPath<T>;
  standalone?: false;
};

export type CustomInputProps<T> = StandaloneInputProps<T> | ControlledInput<T>;

export default function CustomInput<T>(props: CustomInputProps<T>) {
  const inputProps: Omit<RenderInputProps<T>, 'onChange' | 'value'> = {
    placeholder: props.placeholder,
    label: props.label,
    disabled: props.disabled,
    error: props.error,
    errorTextStyle: props.errorTextStyle,
    leftIcon: props.leftIcon,
    rightIcon: props.rightIcon,
    containerStyle: props.containerStyle,
    labelContainerStyle: props.labelContainerStyle,
    multiline: props.multiline,
    numberOfLines: props.numberOfLines,
    inputContainerStyle: props.inputContainerStyle,
    labelStyle: props.labelStyle,
    placeHolderTextColor: props.placeHolderTextColor,
    inputTextStyle: props.inputTextStyle,
    type: props.type ?? 'text',
    testId: props.testId,
    selectButtonStyle:
      props.type === 'select' ? props.selectButtonStyle : undefined,
    selectButtonTextStyle:
      props.type === 'select' ? props.selectButtonTextStyle : undefined,
    selectData: props.type === 'select' ? props.selectData : undefined,
    search: props.type === 'select' ? props.search : undefined,
    secure: props.secure,
    renderFileButton:
      props.type === 'file' ? props.renderFileButton : undefined,
    renderSelectedFile:
      props.type === 'file' ? props.renderSelectedFile : undefined,
  };

  if (props.standalone) {
    return (
      <Input
        {...inputProps}
        onChange={props.onChange ? props.onChange : () => {}}
        value={props.value}
      />
    );
  }

  return (
    <Controller
      name={props.name}
      // TODO: fix me
      // @ts-ignore
      control={props.control}
      render={({ field }) => (
        <Input {...inputProps} onChange={field.onChange} value={field.value} />
      )}
    />
  );
}

interface RenderInputProps<T> {
  secure?: boolean;
  leftIcon?: () => JSX.Element;
  rightIcon?: () => JSX.Element;
  renderSelectedFile?: (value: any) => JSX.Element;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  error?: string;
  errorTextStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  labelContainerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  inputTextStyle?: StyleProp<TextStyle>;
  placeHolderTextColor?: string;
  selectData?: SelectData<T>;
  selectButtonStyle?: StyleProp<ViewStyle>;
  selectButtonTextStyle?: StyleProp<TextStyle>;
  renderFileButton?: (chooseFile: () => void) => JSX.Element;
  fileButtonStyle?: StyleProp<ViewStyle>;
  fileButtonTextStyle?: StyleProp<TextStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  numberOfLines?: number;
  multiline?: boolean;
  testId?: string;
  search?: boolean;
  type: 'text' | 'number' | 'select' | 'file';
  onChange: (value: any) => void;
  value: any;
}

function Input<T>(props: RenderInputProps<T>) {
  const inputTypeToKeyboardType = useMemo((): KeyboardTypeOptions => {
    switch (props.type) {
      case 'number':
        return 'number-pad';
      case 'text':
        return 'default';
      default:
        return 'default';
    }
  }, []);

  const chooseFile = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.cancelled && result.uri) {
      props.onChange({
        uri: result.uri,
        name: 'SomeImageName.jpg',
        type: 'image/jpg',
      });
    }
  };

  return (
    <CustomView noBackground style={props.containerStyle}>
      {props.label && (
        <InputLabel
          labelContainerStyle={props.labelContainerStyle}
          labelStyle={props.labelStyle}
          label={props.label}
        />
      )}
      {props.type === 'file' && (
        <View>
          <View
            style={[
              props.inputContainerStyle,
              { flexDirection: 'row', alignItems: 'center' },
            ]}
          >
            <View>{props.leftIcon && props.leftIcon()}</View>
            {props.renderFileButton ? (
              props.renderFileButton(chooseFile)
            ) : (
              <SelectButton
                onPress={chooseFile}
                style={props.fileButtonStyle}
                textStyle={props.fileButtonTextStyle}
              />
            )}
            <View>{props.rightIcon && props.rightIcon()}</View>
          </View>
          {props.renderSelectedFile &&
            props.value &&
            props.renderSelectedFile(props.value)}
        </View>
      )}

      {props.type === 'select' && (
        <CustomSelect
          multiple={false}
          onSelect={props.onChange}
          data={props.selectData?.data || []}
          fieldName={props.selectData!.fieldName}
          valueField={props.selectData!.valueField}
          selected={props.value as T | undefined}
          renderButton={(showModal) => (
            <SelectButton
              onPress={showModal}
              style={props.selectButtonStyle}
              textStyle={props.selectButtonTextStyle}
            />
          )}
          style={props.selectButtonStyle}
          modalTitle={props.label}
          search={props.search}
        />
      )}
      {props.type === 'text' && (
        <View
          style={[
            props.inputContainerStyle,
            { flexDirection: 'row', alignItems: 'center' },
          ]}
        >
          <View>{props.leftIcon && props.leftIcon()}</View>
          <TextInput
            testID={props.testId}
            secureTextEntry={props.secure}
            keyboardType={inputTypeToKeyboardType}
            placeholder={props.placeholder}
            onChangeText={props.onChange as unknown as (val: string) => void}
            placeholderTextColor={props.placeHolderTextColor}
            style={[
              props.inputTextStyle as StyleProp<TextStyle>,
              { fontFamily: 'BalsamiqSans_400Regular' },
              { flex: 1 },
              // @ts-ignore
              Platform.OS === 'web' ? { outline: 'none' } : {},
            ]}
            multiline={
              props.multiline ??
              Boolean(props.numberOfLines && props.numberOfLines > 0)
            }
            numberOfLines={
              props.numberOfLines || (props.value as string).split('\n').length
            }
            value={props.value as string}
            editable={!props.disabled}
          />
          <View>{props.rightIcon && props.rightIcon()}</View>
        </View>
      )}
      {props.error && (
        <InputErrorMessage
          labelContainerStyle={props.labelContainerStyle}
          errorTextStyle={props.errorTextStyle}
          error={props.error}
        />
      )}
    </CustomView>
  );
}

function InputLabel(props: {
  labelContainerStyle: StyleProp<ViewStyle>;
  labelStyle: StyleProp<TextStyle>;
  label: string;
}) {
  return (
    <CustomView
      noBackground
      style={[
        {
          marginBottom: 5,
        },
        props.labelContainerStyle,
      ]}
    >
      <CustomText style={props.labelStyle}>{props.label}</CustomText>
    </CustomView>
  );
}

function InputErrorMessage(props: {
  labelContainerStyle: StyleProp<ViewStyle>;
  errorTextStyle: StyleProp<TextStyle>;
  error: string;
}) {
  return (
    <CustomView
      noBackground
      style={[
        {
          marginTop: 5,
        },
        props.labelContainerStyle,
      ]}
    >
      <CustomText style={props.errorTextStyle}>{props.error}</CustomText>
    </CustomView>
  );
}

const SelectButton = ({
  onPress,
  style,
  textStyle,
  text,
}: {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  text?: string;
}) => {
  return (
    <CustomButton full onPress={onPress} style={style}>
      <CustomText style={textStyle}>{text ?? 'Select'}</CustomText>
    </CustomButton>
  );
};
