import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { chatApi, ChatDto, MessageDto } from '../../api/chat';

const initialState: ChatDto[] = [];

const ChatSlice = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    clearChats() {
      return initialState;
    },
    addMessage(state, action: PayloadAction<MessageDto>) {
      const chat = state.find((chat) => chat.uuid === action.payload.chatUuid);
      if (!chat) {
        console.debug('chat not found!');
        return;
      }

      const existingChatMessages = chat.messages;
      if (
        !existingChatMessages.some(
          (message) => message.uuid === action.payload.uuid
        )
      ) {
        console.debug('Message did not exist!, adding it');

        chat.messages.unshift(action.payload);
      } else {
        console.debug('Message already exist!,will not add it');
      }
    },
    updateChat(state, action: PayloadAction<Partial<ChatDto>>) {
      let chat = state.find((chat) => chat.uuid === action.payload.uuid);

      if (!chat) {
        return;
      }

      chat = {
        ...chat,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chatApi.endpoints.findByUserIdFromChatController.matchFulfilled,
      (state, { payload }) => {
        return payload;
      }
    );
    builder.addMatcher(
      chatApi.endpoints.findByChatUuidFromChatController.matchFulfilled,
      (state, { payload }) => {
        if (payload.length === 0) {
          return;
        }

        const chatUuid = payload[0].chatUuid;
        const chat = state.find((chat) => chat.uuid === chatUuid);

        if (chat) {
          chat.messages = payload;
        }
      }
    );
    builder.addMatcher(
      chatApi.endpoints.createFromChatController.matchFulfilled,
      (state, { payload }) => {
        state.push(payload);
      }
    );
    builder.addMatcher(
      chatApi.endpoints.updateLastOpenedFromChatController.matchFulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { originalArgs: chatUuid },
          },
        }
      ) => {
        const chat = state.find((chat) => chat.uuid === chatUuid);

        if (chat) {
          chat.lastOpened = payload;
        }
      }
    );
    builder.addMatcher(
      chatApi.endpoints.createMessageFromChatController.matchFulfilled,
      (state, { payload }) => {
        const chat = state.find((chat) => chat.uuid === payload.chatUuid);
        if (!chat) {
          return;
        }

        const existingChatMessages = chat.messages;
        if (
          !existingChatMessages.some((message) => message.uuid === payload.uuid)
        ) {
          chat.messages.unshift(payload);
        }
      }
    );
    builder.addMatcher(
      chatApi.endpoints.deleteChatFromChatController.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs },
          },
          payload,
        }
      ) => {
        const chatIndex = state.findIndex((chat) => chat.uuid === originalArgs);
        state.splice(chatIndex, 1);
      }
    );
    builder.addMatcher(
      chatApi.endpoints.setChatNicknameFromChatController.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs },
          },
          payload,
        }
      ) => {
        const {
          chatUuid,
          chatSetNicknameRequestDto: { nickname },
        } = originalArgs;
        const chat = state.find((chat) => chat.uuid === chatUuid);
        if (chat) {
          chat.nickname = nickname;
        }
        // state.splice(chatIndex, 1);
      }
    );
  },
});

export const { addMessage, clearChats, updateChat } = ChatSlice.actions;
export default ChatSlice.reducer;
