import { createSlice } from '@reduxjs/toolkit';

export interface AppDataState {
  loading: boolean;
  showBottomNav: boolean;
  darkMode: boolean;
}

const initialState: AppDataState = {
  loading: false,
  darkMode: false,
  showBottomNav: true,
};

const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    clearAppData() {
      return initialState;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    toggleBottomNav: (state, action) => {
      state.showBottomNav = action.payload;
    },
  },
});

export const { showLoading, hideLoading, clearAppData, toggleBottomNav } =
  appDataSlice.actions;
export default appDataSlice.reducer;
