import { createSlice } from '@reduxjs/toolkit';
import { authApi, AuthUserDto } from '../../api/auth';
import { userApi } from '../../api/user';
import { CLEAR_AUTH, refreshToken } from '../actions/auth';

const initialState: AuthUserDto | null = {
  uuid: '',
  username: '',
  description: '',
  email: '',
  dob: new Date().toString(),
  gender: '',
  avatar: '',
  refreshToken: null,
  accessToken: '',
  features: [],
  followersCount: 0,
  followingCount: 0,
};

const AuthSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CLEAR_AUTH, () => {
      return initialState;
    });

    builder.addCase(refreshToken, (state, action) => {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    });
    builder.addMatcher(
      authApi.endpoints.loginFromAuthController.matchFulfilled,
      (state, { payload }) => {
        Object.assign(state, { ...payload, loggedIn: true });
      }
    );
    builder.addMatcher(
      userApi.endpoints.updateFromUserController.matchFulfilled,
      (state, { payload }) => {
        Object.assign(state, { ...payload, loggedIn: true });
      }
    );
  },
});

export default AuthSlice.reducer;
