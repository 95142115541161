import React, { useCallback, useEffect, useRef } from 'react';

import { Animated, Pressable, StyleSheet, View } from 'react-native';
import { useAppSelector } from '../hooks/store';
import useTheme from '../hooks/useTheme';
import NavigationIcon from './NavigationIcon';

const TabBar = ({ state, descriptors, navigation }: any) => {
  const show = useAppSelector((state) => state.AppData.showBottomNav);
  const opacityValue = useRef(new Animated.Value(200)).current;
  const { palette } = useTheme();

  useEffect(() => {
    if (show) {
      fadeIn();
    } else {
      fadeOut();
    }
  }, [show]);

  const fadeIn = useCallback(() => {
    opacityValue.setValue(100);
    Animated.timing(opacityValue, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [opacityValue]);

  const fadeOut = useCallback(() => {
    Animated.timing(opacityValue, {
      toValue: 100,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [opacityValue]);

  return (
    <Animated.View
      style={[
        styles.mainContainer,
        { backgroundColor: palette.tabBar },
        {
          transform: [{ translateY: opacityValue }],
        },
      ]}
    >
      {state.routes.map((route: any, index: number) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        return (
          <View
            key={index}
            style={[
              styles.mainItemContainer,
              { borderRightWidth: label == 'notes' ? 3 : 0 },
            ]}
          >
            <Pressable
              onPress={onPress}
              style={{
                backgroundColor: isFocused ? palette.primary : '#182028',
                borderRadius: 20,
              }}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  padding: 10,
                }}
              >
                <NavigationIcon route={label} isFocused={isFocused} />
              </View>
            </Pressable>
          </View>
        );
      })}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 25,
    borderRadius: 25,
    left: '10%',
    width: '80%',
  },
  mainItemContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
    borderRadius: 1,
    borderColor: '#333B42',
  },
});

export default TabBar;
