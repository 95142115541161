import React from 'react';

const QuickChat = React.memo(() => {
  return <></>;
  // const navigation = useNavigation();

  // const chats = useAppSelector((state) => state.Chat);

  // const goToChat = (chat: Chat) => {
  //     navigation.navigate("LoggedIn", {
  //         screen: "Chat",
  //         params: { chatId: chat.chatId! },
  //     });
  // };

  // const RenderItem = useCallback((item: ListRenderItemInfo<IChat>) => {
  //     return (
  //         <View>
  //             <TouchableOpacity onPress={() => goToChat(item.item)} >
  //                 <View style={{ marginBottom: 5 }}>
  //                     {item.item.contactAvatar &&
  //                         <CustomImage avatar uri={item.item.contactAvatar} />}
  //                     <View style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: 'lightgreen', position: 'absolute', right: 0 }} />
  //                 </View>
  //                 {item.item.contactName && <CustomText note>{item.item.contactName}</CustomText>}
  //             </TouchableOpacity >
  //         </View>
  //     )
  // }, []);

  // const RenderSeparator = useCallback(() => (
  //     <View />
  // ), [])

  // return (
  //     <FlatList ItemSeparatorComponent={RenderSeparator} keyExtractor={(chat, index) => `quick-chat-${index}`} renderItem={RenderItem} data={Object.values(chats)} horizontal />
  // )
});

export default QuickChat;
