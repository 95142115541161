import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';

export type CustomTextProps = {
  note?: boolean;
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
  children?: undefined | string | number | boolean | any;
  onPress?: () => void;
  color?: string;
  testID?: string;
};

const CustomText = (props: CustomTextProps) => {
  const { children, style = {}, onPress, color } = props;

  return (
    <Text
      testID={props.testID}
      onPress={onPress}
      numberOfLines={props.numberOfLines || undefined}
      style={[
        props.note ? styles.note : null,
        style,
        color ? { color: color } : null,
      ]}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  note: { color: '#a7a7a7', fontSize: 12 },
});

export default CustomText;
