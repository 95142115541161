import { ResizeMode, Video, VideoReadyForDisplayEvent } from 'expo-av';
import { ImageStyle } from 'expo-image';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
  View,
} from 'react-native';
import { DimensionValue, StyleProp } from 'react-native';
import { percentageWidth } from '../utils';

interface BaseVideoProps {
  containerStyle?: StyleProp<ImageStyle>;
  uri: string;
  dimensions?: {
    width?: number;
    height?: number;
    maxHeight?: number;
  };
  loadingIndicatorColor?: string;
  onErrorUrl?: string;
  onClick?: () => void;
}

export default function VideoPlayer(props: BaseVideoProps) {
  const video = React.useRef<Video>(null);

  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState<
    { width: DimensionValue; height: DimensionValue } | undefined
  >();
  const [uri, setUri] = useState(props.uri);

  useEffect(() => {
    setUri(props.uri);
  }, [props.uri]);

  const onReadyForDisplay = (ev: any) => {
    const height = ev?.target?.offsetHeight;
    const width = ev?.target?.offsetWidth;

    if (height && width) {
      const desiredWidth = props.dimensions?.width ?? percentageWidth(92);
      setSize({
        height: (desiredWidth / width) * height,
        width: desiredWidth,
      });
    }
  };

  return (
    <TouchableOpacity
      disabled={!props.onClick}
      onPress={props.onClick}
      style={[
        {
          width: size?.width,
          height: size?.height,
          maxHeight: props.dimensions?.maxHeight,
        },
        props.containerStyle,
      ]}
    >
      <Video
        ref={video}
        style={{
          width: size?.width,
          height: size?.height,
        }}
        source={{
          uri,
        }}
        videoStyle={{
          width: size?.width,
          height: size?.height,
        }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        isLooping
        onReadyForDisplay={onReadyForDisplay}
        onLoad={(ev) => setLoading(false)}
      />
      {loading && size ? (
        <View
          style={{
            height: size?.height ?? 'auto',
            width: size?.width ?? 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            position: 'absolute',
          }}
        >
          <ActivityIndicator color={props.loadingIndicatorColor ?? 'black'} />
        </View>
      ) : null}
    </TouchableOpacity>
  );
}
