import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import {
  useFocusEffect,
  useNavigation,
  useScrollToTop,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { api } from '../api/baseApi';
import { useLazyFindByUserIdFromChatControllerQuery } from '../api/chat';
import CustomContainer from '../components/Basic/CustomContainer';
import CustomView from '../components/Basic/CustomView';
import PageLoading from '../components/Basic/PageLoading';
import ChatItem from '../components/ChatItem';
import QuickChat from '../components/QuickChat';
import {
  ThemedFlatlist,
  ThemedFloatingButton,
  ThemedIcon,
  ThemedText,
  ThemedTextInput,
} from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import useTheme from '../hooks/useTheme';

const Separator = styled.View`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default function Chats() {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const ref = useRef(null);

  const [search, setSearch] = useState<string>('');

  const [fetchChats, { isLoading: chatsLoading }] =
    useLazyFindByUserIdFromChatControllerQuery();

  const chats = useAppSelector((state) => state.Chat);

  const [visibleChats, setVisibleChats] = useState(chats);

  useScrollToTop(ref);
  const tabBarHeight = useBottomTabBarHeight();

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(api.util.resetApiState());
      fetchChats();
    }, [])
  );

  const onCreateNewChat = () => {
    navigation.navigate('LoggedIn', { screen: 'CreateChat' });
  };

  useEffect(() => {
    if (search && chats) {
      const foundChats = Object.values(chats).filter((chat) =>
        chat.users[0].user.username.toLowerCase().includes(search.toLowerCase())
      );
      setVisibleChats(foundChats);
    } else {
      setVisibleChats(Object.values(chats));
    }
  }, [search, chats]);

  if (chatsLoading) {
    return <PageLoading />;
  }

  const EmptyListComponent = () => {
    return (
      <CustomContainer>
        <CustomView style={{ alignItems: 'center', justifyContent: 'center' }}>
          <ThemedText>No chats found</ThemedText>
        </CustomView>
      </CustomContainer>
    );
  };

  return (
    <>
      <ThemedFlatlist
        data={visibleChats}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={EmptyListComponent}
        ListHeaderComponent={
          <ListHeader handleSearch={handleSearch} search={search} />
        }
        ref={ref}
        contentContainerStyle={{ paddingBottom: tabBarHeight + 20 }}
        keyExtractor={(item, index) => `chat${index}${item.uuid}`}
        renderItem={({ index, item }) => (
          <ChatItem key={`chat-${index}`} chat={item} />
        )}
      />
      <ThemedFloatingButton
        onPress={onCreateNewChat}
        icon={<ThemedIcon name={'plus'} type={'FontAwesome5'} />}
      />
    </>
  );
}

const ListHeader = ({
  search,
  handleSearch,
}: {
  handleSearch: (val: string) => void;
  search: string;
}) => {
  return (
    <View style={{ marginBottom: 15 }}>
      <ThemedTextInput
        placeholder={'Search chat here..'}
        standalone
        onChange={handleSearch}
        value={search}
      />
      <View style={{ marginVertical: 20 }}>
        <ThemedText
          style={{
            fontSize: 15,
            fontFamily: 'BalsamiqSans_400Regular',
          }}
        >
          Quick Chat
        </ThemedText>
        <QuickChat />
        <View style={{ marginBottom: 20 }} />
      </View>
      <View
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <ThemedText
          style={{
            fontSize: 15,
            fontFamily: 'BalsamiqSans_400Regular',
          }}
        >
          Messages
        </ThemedText>
      </View>
    </View>
  );
};
