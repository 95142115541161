import { Dimensions } from 'react-native';

export const percentageHeight = (value: number): number => {
  return (Dimensions.get('window').height * value) / 100;
};

export const percentageWidth = (value: number): number => {
  return (Dimensions.get('window').width * value) / 100;
};

export const generateRandomUUid = (): string =>
  Math.random().toString().replace('0.', '');

export const objectToFormData = (obj: any): FormData => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      formData.append(key, JSON.stringify(obj[key]));
    } else {
      formData.append(key, obj[key]);
    }
  });

  return formData;
};
