import React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';

interface ListItemProps {
  leftComponent?: () => JSX.Element | undefined;
  rightComponent?: () => JSX.Element | undefined;
  style?: ViewStyle;
  onPress?: () => void;
  onLongPress?: () => void;
}

export const ListItem = ({
  leftComponent,
  rightComponent,
  style = {},
  onPress,
  onLongPress,
}: ListItemProps) => {
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      onLongPress={onLongPress}
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        style,
      ]}
    >
      <View style={{ maxWidth: rightComponent ? '80%' : '100%' }}>
        {leftComponent && leftComponent()}
      </View>
      <View>{rightComponent && rightComponent()}</View>
    </TouchableOpacity>
  );
};
