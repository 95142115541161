import React from 'react';
import {
  Animated,
  GestureResponderEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

type Props = {
  scrollable?: boolean;
  style?: StyleProp<ViewStyle>;
  left?: boolean;
  right?: boolean;
  body?: boolean;
  padder?: boolean;
  noBackground?: boolean;
  animated?: boolean;
  children?: any;
  containerStyle?: StyleProp<ViewStyle>;
  onStartShouldSetResponder?: (event: GestureResponderEvent) => boolean;
  testID?: string;
};

const CustomView = (props: Props) => {
  const {
    children,
    scrollable,
    style = {},
    containerStyle = {},
    left = false,
    body = false,
    right = false,
    padder = false,
    animated = false,
    onStartShouldSetResponder,
  } = props;

  if (scrollable) {
    return (
      <ScrollView
        testID={props.testID}
        contentContainerStyle={[containerStyle, padder ? styles.padder : null]}
        style={[
          styles.default,
          style,
          left ? styles.left : null,
          right ? styles.right : null,
          body ? styles.body : null,
        ]}
      >
        {children}
      </ScrollView>
    );
  } else if (animated) {
    return (
      <Animated.View
        testID={props.testID}
        onStartShouldSetResponder={onStartShouldSetResponder}
        style={[
          styles.default,
          style,
          left ? styles.left : null,
          right ? styles.right : null,
          body ? styles.body : null,
          padder ? styles.padder : null,
        ]}
      >
        {children}
      </Animated.View>
    );
  }
  return (
    <View
      testID={props.testID}
      onStartShouldSetResponder={onStartShouldSetResponder}
      style={[
        styles.default,
        style,
        left ? styles.left : null,
        right ? styles.right : null,
        body ? styles.body : null,
        padder ? styles.padder : null,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  default: {},
  left: { alignSelf: 'flex-start' },
  body: { alignItems: 'center' },
  right: { right: 5 },
  padder: { padding: 10 },
});

export default CustomView;
