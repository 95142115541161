import { RouteProp, useRoute } from '@react-navigation/core';
import React from 'react';
import { LoggedInTabParamList } from '../../types';
import { useFindOneFromPostControllerQuery } from '../api/post';
import CustomContainer from '../components/Basic/CustomContainer';
import PageLoading from '../components/Basic/PageLoading';
import Post from '../components/Post';

export default function SinglePost() {
  const {
    params: { postUuid },
  } = useRoute<RouteProp<LoggedInTabParamList, 'Post'>>();

  const { data: post, isLoading } = useFindOneFromPostControllerQuery(
    postUuid,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  if (!post || isLoading) {
    return <PageLoading />;
  }

  return (
    <CustomContainer style={{ padding: 20 }}>
      <Post post={post} />
    </CustomContainer>
  );
}
