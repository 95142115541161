import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useColorScheme } from 'react-native';
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components/native';
import Colors from '../constants/Colors';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ThemeInterface {
  currentTheme: Theme;
  changeTheme: (theme: Theme) => void;
  palette: DefaultTheme;
}

const DefaultThemeContext: ThemeInterface = {
  currentTheme: Theme.LIGHT,
  changeTheme: () => {},
  palette: Colors.light,
};

const ThemeContext = createContext<ThemeInterface>(DefaultThemeContext);

export const CustomThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT);
  const colorPalette = useMemo(() => Colors[theme], [theme]);
  const systemTheme = useColorScheme();

  useEffect(() => {
    if (systemTheme === Theme.LIGHT) {
      setTheme(Theme.LIGHT);
    }

    if (systemTheme === Theme.DARK) {
      setTheme(Theme.DARK);
    }
  }, [systemTheme]);

  return (
    <ThemeContext.Provider
      value={{
        currentTheme: theme,
        changeTheme: setTheme,
        palette: colorPalette,
      }}
    >
      <StyledThemeProvider theme={colorPalette}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default function useTheme() {
  return useContext(ThemeContext);
}
