import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { ICustomToast } from '../../hooks/usePopup';
import CustomText from './CustomText';

export default function CustomToast({
  toast,
  hideToast,
}: {
  toast?: ICustomToast;
  hideToast: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const opacityValue = useRef(new Animated.Value(200)).current;
  const durationTimeout = useRef<NodeJS.Timeout | null>(null);

  const fadeIn = useCallback(() => {
    if (toast) {
      opacityValue.setValue(100);
      Animated.timing(opacityValue, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {
        durationTimeout.current = setTimeout(() => {
          fadeOut();
        }, toast.duration || 5000);
      });
    }
  }, [opacityValue, toast]);

  const fadeOut = useCallback(() => {
    Animated.timing(opacityValue, {
      toValue: 100,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      setVisible(false);
      hideToast();
    });
  }, [opacityValue]);

  useEffect(() => {
    if (toast?.text) {
      setVisible(true);
    }
    return () => {
      if (durationTimeout.current) {
        clearTimeout(durationTimeout.current);
      }
    };
  }, [toast]);

  useEffect(() => {
    if (visible) {
      fadeIn();
    }
  }, [fadeIn, visible]);

  const dismiss = () => {
    if (durationTimeout.current) {
      clearTimeout(durationTimeout.current);
      fadeOut();
    }
  };

  if (!visible || !toast) {
    return null;
  }
  const animatedStyle = [
    styles.animatedView,
    { top: toast.position === 'top' ? 20 : undefined },
    { bottom: toast.position !== 'bottom' ? 20 : undefined },
    {
      transform: [{ translateY: opacityValue }],
    },
  ];
  const toastStyles: any[] = [styles.baseToast];
  if (toast?.type === 'success') {
    toastStyles.push(styles.success);
  }
  if (toast?.type === 'danger') {
    toastStyles.push(styles.danger);
  }

  return (
    <Animated.View style={[...animatedStyle]}>
      <View style={toastStyles}>
        <View style={styles.text}>
          <CustomText
            style={{ flex: 1, textAlign: 'left', fontSize: 15, color: 'white' }}
          >
            {toast.text}
          </CustomText>
        </View>
        {toast.buttonText ? (
          <TouchableOpacity activeOpacity={0.9} onPress={dismiss}>
            <View>
              <Text style={styles.btnText}>{toast?.buttonText}</Text>
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  animatedView: {
    position: 'absolute',
    // bottom: 20,
    left: 10,
    right: 10,
    backgroundColor: 'black',
    overflow: 'hidden',
    borderRadius: 10,
  },
  baseToast: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderRadius: 10,
    backgroundColor: 'black',
  },
  danger: { backgroundColor: '#F44336' },
  success: { backgroundColor: '#4CAF50' },
  text: { flex: 1 },
  btnText: { flex: 0, color: 'white', marginLeft: 20, marginRight: 10 },
});
