import { RouteProp, useRoute } from '@react-navigation/core';
import React, { useCallback, useEffect } from 'react';
import { LoggedInTabParamList } from '../../types';
import {
  CommentCreateRequestDto,
  useCreateFromCommentControllerMutation,
  useLazyFindByPostUuidFromCommentControllerQuery,
} from '../api/comment';
import Chat, { MessageDetailsDto } from '../components/Chat';
import { useAppSelector } from '../hooks/store';
import { objectToFormData } from '../utils';

interface ImageDetails {
  uri: string;
  name: string;
  type: string;
}

export default function Comments() {
  const {
    params: { postUuid },
  } = useRoute<RouteProp<LoggedInTabParamList, 'Comments'>>();

  const [getComments] = useLazyFindByPostUuidFromCommentControllerQuery();
  const [commentPost] = useCreateFromCommentControllerMutation();

  const post = useAppSelector((state) =>
    state.Post.find((p) => p.uuid === postUuid)
  )!;

  useEffect(() => {
    getComments(postUuid);
  }, []);

  const getPhoto = async (image: ImageDetails): Promise<Blob> => {
    const data = await fetch(image.uri);
    const blob = await data.blob();
    return blob;
  };

  const onSend = useCallback(async (message: MessageDetailsDto) => {
    const commmentData: CommentCreateRequestDto = {
      text: message.text ?? '',
      postUuid,
    };

    if (message.photo) {
      commmentData.photo = await getPhoto(message.photo as ImageDetails);
    }

    if (message.video) {
      commmentData.video = await getPhoto(message.video as ImageDetails);
    }

    // @ts-ignore
    commentPost(objectToFormData(commmentData));
  }, []);

  return <Chat messages={post.comments} onMessageSend={onSend} />;
}
