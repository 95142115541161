import FirebaseService from './firebase';
import { NotificationSubscriber } from './notification';
import SocketService from './socket.io';

export class NotificationManager {
  private static _instance?: NotificationManager;
  public socket: SocketService;
  public firebase: FirebaseService;

  private constructor() {
    this.socket = new SocketService();
    this.firebase = new FirebaseService();
  }

  public subscribe(subscriber: NotificationSubscriber) {
    this.firebase.subscribe(subscriber);
    this.socket.subscribe(subscriber);
  }

  public unsubscribe(subscriber: NotificationSubscriber) {
    this.firebase.unsubscribe(subscriber);
    this.socket.unsubscribe(subscriber);
  }

  public static getInstance(): NotificationManager {
    if (!NotificationManager._instance) {
      NotificationManager._instance = new NotificationManager();
    }

    return NotificationManager._instance;
  }
}
