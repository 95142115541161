import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';

import { AntDesign } from '@expo/vector-icons';
import CustomIcon from '../components/Basic/CustomIcon';
import ChatsBubble from '../components/ChatsBubble';
import useTheme from '../hooks/useTheme';

interface NavigationIconProps {
  route: string;
  isFocused: boolean;
}

const NavigationIcon = ({ route, isFocused }: NavigationIconProps) => {
  const { palette } = useTheme();
  const [scale] = useState(new Animated.Value(0));

  useEffect(() => {
    if (isFocused) {
      Animated.spring(scale, {
        toValue: 1.3,
        friction: 3,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.spring(scale, {
        toValue: 1,
        friction: 1,
        useNativeDriver: true,
      }).start();
    }
  }, [isFocused]);

  const renderIcon = (route: string, isFocues: boolean) => {
    let height: number = 20;
    let width: number = 20;

    switch (route) {
      case 'NewsFeed':
        return (
          <CustomIcon
            type="AntDesign"
            name={'home'}
            size={22}
            color={
              !isFocused ? palette.tabIconDefault : palette.tabIconSelected
            }
          />
        );
      case 'Chats':
        return (
          <View style={{ position: 'relative' }}>
            <AntDesign
              name={'message1'}
              size={22}
              color={
                !isFocused ? palette.tabIconDefault : palette.tabIconSelected
              }
            />
            <ChatsBubble />
          </View>
        );
      case 'FindPeople':
        return (
          <AntDesign
            testID={'searchButtonBottomNav'}
            name={'search1'}
            size={22}
            color={
              !isFocused ? palette.tabIconDefault : palette.tabIconSelected
            }
          />
        );
      case 'Profile':
        return (
          <AntDesign
            name={'user'}
            size={22}
            color={
              !isFocused ? palette.tabIconDefault : palette.tabIconSelected
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <Animated.View
      style={{
        transform: [
          {
            scale: scale.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.2],
            }),
          },
        ],
      }}
    >
      {renderIcon(route, isFocused)}
    </Animated.View>
  );
};

const styles = StyleSheet.create({});

export default NavigationIcon;
