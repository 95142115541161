import React from 'react';
import {
  Animated,
  LayoutAnimation,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import CustomText from './CustomText';

export interface CustomToggleProps {
  activeBackgroundColor?: string;
  activeBubbleColor?: string;
  inactiveBackgroundColor?: string;
  inactiveBubbleColor?: string;
  animated?: boolean;
  activated: boolean;
  onChange: (activated: boolean) => void;
  label?: string;
  labelStyle?: StyleProp<TextStyle>;
}

export default function CustomToggle({
  activeBackgroundColor,
  activeBubbleColor,
  inactiveBackgroundColor,
  inactiveBubbleColor,
  animated = true,
  activated,
  labelStyle,
  onChange = () => {},
  label,
}: CustomToggleProps) {
  const toggle = () => {
    onChange(!activated);
    if (animated) {
      LayoutAnimation.easeInEaseOut();
    }

    if (window.navigator?.vibrate) {
      window.navigator.vibrate(200);
    }
  };

  return (
    <Animated.View style={styles.container}>
      {label && <CustomText style={labelStyle}>{label}</CustomText>}
      <TouchableOpacity
        activeOpacity={0.9}
        style={[
          styles.toggleContainer,
          activated ? styles.toggleContainerActivated : undefined,
        ]}
        onPress={toggle}
      >
        <Animated.View
          style={[
            styles.bubble,
            activated
              ? { backgroundColor: activeBubbleColor || 'lightblue' }
              : { backgroundColor: inactiveBubbleColor || 'black' },
          ]}
        ></Animated.View>
      </TouchableOpacity>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toggleContainer: {
    width: 60,
    height: 35,
    backgroundColor: 'gray',
    borderRadius: 35,
    display: 'flex',
    flexDirection: 'row',
  },
  bubble: {
    width: 35,
    height: 35,
    backgroundColor: 'white',
    borderRadius: 17,
  },
  bubbleActivated: {
    // backgroundColor: "white",
  },
  toggleContainerActivated: {
    backgroundColor: 'white',
    justifyContent: 'flex-end',
  },
});
