import React from 'react';
import styled from 'styled-components/native';
import { PostDto } from '../../api/post';
import { ThemedImage, ThemedText } from '../Themed';
import VideoPlayer from '../VideoPlayer';

const Container = styled.View`
  background-color: transparent;
  padding: 5px;
`;

export default function PostBody({
  post,
  onImageClick,
}: {
  post: PostDto;
  onImageClick?: () => void;
}) {
  return (
    <Container>
      {post.photo && <ThemedImage uri={post.photo} onClick={onImageClick} />}
      {post.video && <VideoPlayer uri={post.video} />}
      <ThemedText style={{ marginVertical: 10 }}>{post.text}</ThemedText>
    </Container>
  );
}
