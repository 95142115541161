import { useFocusEffect, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import {
  NotificationDto,
  useLazyFindAllFromNotificationControllerQuery,
  useMarkAsReadFromNotificationControllerMutation,
} from '../api/notification';
import { ThemedFlatlist, ThemedText } from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import useTheme from '../hooks/useTheme';

const NotificationWrapper = styled.TouchableOpacity`
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px;
`;

export default function Notifications() {
  const [fetchNotifications] = useLazyFindAllFromNotificationControllerQuery();
  const notifications = useAppSelector((state) => state.Notification);

  useFocusEffect(
    useCallback(() => {
      fetchNotifications();
    }, [])
  );

  return (
    <ThemedFlatlist
      data={notifications}
      renderItem={(item) => <RenderNotification notification={item.item} />}
    />
  );
}

const RenderNotification = ({
  notification,
}: {
  notification: NotificationDto;
}) => {
  const { palette } = useTheme();
  const navigation = useNavigation();
  const [markAsRead] = useMarkAsReadFromNotificationControllerMutation();

  const goToPost = () => {
    markAsRead(notification.uuid);
    navigation.navigate('LoggedIn', {
      screen: 'Post',
      params: { postUuid: notification.post?.uuid! },
    });
  };

  return (
    <NotificationWrapper onPress={goToPost}>
      <ThemedText
        style={{
          maxWidth: 200,
          color: !notification.read ? palette.text : 'grey',
        }}
        numberOfLines={2}
      >
        {notification.description}
      </ThemedText>
      <ThemedText note>{moment(notification.date).fromNow()}</ThemedText>
    </NotificationWrapper>
  );
};
