import { combineReducers } from 'redux';
import { api } from '../../api/baseApi';
import AppData from './AppData';
import Auth from './Auth';
import Chat from './Chat';
import Notification from './Notification';
import Post from './Post';
import User from './User';
import Bot from './Bot';
import Follower from './Follower';

export const mainReducer = combineReducers({
  Auth: Auth,
  AppData: AppData,
  Follows: Follower,
  Post: Post,
  User: User,
  Bot: Bot,
  Chat: Chat,
  Notification: Notification,
  [api.reducerPath]: api.reducer,
});
