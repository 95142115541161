import * as Icons from '@expo/vector-icons';
import React, { ComponentProps } from 'react';

interface BaseProps {
  size?: number;
  color?: string;
  onPress?: Function;
  style?: React.CSSProperties;
}

export type CustomIconProps = BaseProps &
  (
    | {
        type: 'AntDesign';
        name: ComponentProps<typeof Icons.AntDesign>['name'];
      }
    | {
        type: 'Feather';
        name: ComponentProps<typeof Icons.Feather>['name'];
      }
    | {
        type: 'MaterialIcons';
        name: ComponentProps<typeof Icons.MaterialIcons>['name'];
      }
    | {
        type: 'EvilIcons';
        name: ComponentProps<typeof Icons.EvilIcons>['name'];
      }
    | {
        type: 'Entypo';
        name: ComponentProps<typeof Icons.Entypo>['name'];
      }
    | {
        type: 'FontAwesome';
        name: ComponentProps<typeof Icons.FontAwesome>['name'];
      }
    | {
        type: 'FontAwesome5';
        name: ComponentProps<typeof Icons.FontAwesome5>['name'];
      }
    | {
        type: 'Foundation';
        name: ComponentProps<typeof Icons.Foundation>['name'];
      }
    | {
        type: 'Ionicons';
        name: ComponentProps<typeof Icons.Ionicons>['name'];
      }
    | {
        type: 'MaterialCommunityIcons';
        name: ComponentProps<typeof Icons.MaterialCommunityIcons>['name'];
      }
    | {
        type: 'Zocial';
        name: ComponentProps<typeof Icons.Zocial>['name'];
      }
    | {
        type: 'Octicons';
        name: ComponentProps<typeof Icons.Octicons>['name'];
      }
    | {
        type: 'SimpleLineIcons';
        name: ComponentProps<typeof Icons.SimpleLineIcons>['name'];
      }
    | {
        type: 'Fontisto';
        name: ComponentProps<typeof Icons.Fontisto>['name'];
      }
  );

const CustomIcon = (props: CustomIconProps): JSX.Element => {
  const {
    type,
    name,
    color = 'black',
    size = 20,
    onPress = null,
    style = {},
  } = props;

  const Icon = Icons[type];

  return (
    <Icon
      name={name}
      size={size}
      style={style}
      color={color}
      onPress={onPress}
    />
  );
};

export default CustomIcon;
