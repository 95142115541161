import React from 'react';
import { View } from 'react-native';
import { ChatUserDetailsDto } from '../api/chat';
import CustomView from './Basic/CustomView';
import {
  ThemedView,
  ThemedFlatlist,
  ThemedImage,
  ThemedListItem,
  ThemedText,
} from './Themed';

export const ChatMembersList = ({
  members,
}: {
  members: ChatUserDetailsDto[];
}) => {
  return (
    <ThemedView style={{ flex: 1, borderRadius: 10, padding: 5 }}>
      <View
        style={{
          padding: 20,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ThemedText>Chat members</ThemedText>
      </View>
      <ThemedFlatlist
        renderItem={({ item: user }) => (
          <ThemedListItem
            leftComponent={() => (
              <>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {user.avatar && (
                    <CustomView>
                      <ThemedImage avatar uri={user.avatar} />
                    </CustomView>
                  )}
                  <ThemedText
                    numberOfLines={1}
                    style={{
                      fontSize: 15,
                      marginLeft: 15,
                    }}
                  >
                    {user.username}
                  </ThemedText>
                </View>
              </>
            )}
          />
        )}
        data={members}
      />
    </ThemedView>
  );
};
