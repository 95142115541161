import { createSlice } from '@reduxjs/toolkit';
import { userApi, UserDto } from '../../api/user';

const initialState: UserDto[] = [];

const UserSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.findLatestFromUserController.matchFulfilled,
      (state, { payload, meta }) => {
        if (meta.arg.originalArgs.lastUserUuid) {
          return [...state, ...payload];
        } else {
          return payload;
        }
      }
    );
    builder.addMatcher(
      userApi.endpoints.findLatestByGeolocationFromUserController
        .matchFulfilled,
      (state, { payload, meta }) => {
        if (meta.arg.originalArgs?.page && meta.arg.originalArgs.page > 1) {
          return [...state, ...payload];
        } else {
          return payload;
        }
      }
    );
  },
});

export const {} = UserSlice.actions;
export default UserSlice.reducer;
