import { api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFromFollowerController: build.mutation<
      CreateFromFollowerControllerApiResponse,
      CreateFromFollowerControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/follower`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    findAllFromFollowerController: build.query<
      FindAllFromFollowerControllerApiResponse,
      FindAllFromFollowerControllerApiArg
    >({
      query: () => ({ url: `/follower` }),
    }),
    findUserFollowersFromFollowerController: build.query<
      FindUserFollowersFromFollowerControllerApiResponse,
      FindUserFollowersFromFollowerControllerApiArg
    >({
      query: (queryArg) => ({ url: `/follower/${queryArg}/followers` }),
    }),
    findUserFollowingFromFollowerController: build.query<
      FindUserFollowingFromFollowerControllerApiResponse,
      FindUserFollowingFromFollowerControllerApiArg
    >({
      query: (queryArg) => ({ url: `/follower/${queryArg}/following` }),
    }),
    findAllFollowersFromFollowerController: build.query<
      FindAllFollowersFromFollowerControllerApiResponse,
      FindAllFollowersFromFollowerControllerApiArg
    >({
      query: () => ({ url: `/follower/followers` }),
    }),
    findAllFollowingFromFollowerController: build.query<
      FindAllFollowingFromFollowerControllerApiResponse,
      FindAllFollowingFromFollowerControllerApiArg
    >({
      query: () => ({ url: `/follower/following` }),
    }),
    unfollowFromFollowerController: build.mutation<
      UnfollowFromFollowerControllerApiResponse,
      UnfollowFromFollowerControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/follower/${queryArg}/remove`,
        method: 'DELETE',
      }),
    }),
    removeFollowerFromFollowerController: build.mutation<
      RemoveFollowerFromFollowerControllerApiResponse,
      RemoveFollowerFromFollowerControllerApiArg
    >({
      query: (queryArg) => ({
        url: `/follower/${queryArg}/unfollow`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as followerApi };
export type CreateFromFollowerControllerApiResponse = /** status 200  */
  | FollowerDto
  | /** status 201  */ FollowerDto;
export type CreateFromFollowerControllerApiArg = CreateFollowerRequestDto;
export type FindAllFromFollowerControllerApiResponse =
  /** status 200  */ UserFollows;
export type FindAllFromFollowerControllerApiArg = void;
export type FindUserFollowersFromFollowerControllerApiResponse =
  /** status 200  */ FollowerDto[];
export type FindUserFollowersFromFollowerControllerApiArg = string;
export type FindUserFollowingFromFollowerControllerApiResponse =
  /** status 200  */ FollowerDto[];
export type FindUserFollowingFromFollowerControllerApiArg = string;
export type FindAllFollowersFromFollowerControllerApiResponse =
  /** status 200  */ FollowerDto[];
export type FindAllFollowersFromFollowerControllerApiArg = void;
export type FindAllFollowingFromFollowerControllerApiResponse =
  /** status 200  */ FollowerDto[];
export type FindAllFollowingFromFollowerControllerApiArg = void;
export type UnfollowFromFollowerControllerApiResponse = unknown;
export type UnfollowFromFollowerControllerApiArg = string;
export type RemoveFollowerFromFollowerControllerApiResponse = unknown;
export type RemoveFollowerFromFollowerControllerApiArg = string;
export type FollowerDto = {
  username: string;
  uuid: string;
  avatar: string;
};
export type CreateFollowerRequestDto = {
  userUuid: string;
};
export type UserFollows = {
  followers: FollowerDto[];
  following: FollowerDto[];
};
export const {
  useCreateFromFollowerControllerMutation,
  useFindAllFromFollowerControllerQuery,
  useLazyFindAllFromFollowerControllerQuery,
  useFindUserFollowersFromFollowerControllerQuery,
  useLazyFindUserFollowersFromFollowerControllerQuery,
  useFindUserFollowingFromFollowerControllerQuery,
  useLazyFindUserFollowingFromFollowerControllerQuery,
  useFindAllFollowersFromFollowerControllerQuery,
  useLazyFindAllFollowersFromFollowerControllerQuery,
  useFindAllFollowingFromFollowerControllerQuery,
  useLazyFindAllFollowingFromFollowerControllerQuery,
  useUnfollowFromFollowerControllerMutation,
  useRemoveFollowerFromFollowerControllerMutation,
} = injectedRtkApi;
