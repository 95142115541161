import React, { useCallback } from 'react';
import { ThemedContainer, ThemedToggle } from '../components/Themed';
import useTheme, { Theme } from '../hooks/useTheme';

export default function Settings(props: any) {
  const { currentTheme, changeTheme } = useTheme();

  const switchTheme = useCallback(() => {
    changeTheme(currentTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK);
  }, [currentTheme]);

  return (
    <ThemedContainer>
      <ThemedToggle
        label="Dark Mode"
        activated={currentTheme === Theme.DARK}
        onChange={switchTheme}
      ></ThemedToggle>
    </ThemedContainer>
  );
}
