import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Grid } from '@giphy/react-components';
import React, { useState } from 'react';
import { GIPHY_API_KEY } from '../config';
import { usePopup } from '../hooks/usePopup';
import useTheme from '../hooks/useTheme';
import { percentageWidth } from '../utils';
import { ThemedContainer, ThemedTextInput } from './Themed';

const gf = new GiphyFetch(GIPHY_API_KEY);

export default function Giphy({
  onSelect,
}: {
  onSelect?: (gif: IGif) => void;
}) {
  const [search, setSearch] = useState('');
  const { palette } = useTheme();
  const { hidePopup } = usePopup();

  const fetchGifs = (offset: number) => {
    if (search) {
      return gf.search(search, { limit: 10, offset });
    } else {
      return gf.trending({ offset, limit: 10 });
    }
  };

  return (
    <ThemedContainer
      style={{
        backgroundColor: palette.mainContainer,
        padding: 5,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
      }}
    >
      <ThemedTextInput
        containerStyle={{ marginVertical: 10 }}
        placeholder={'Search term'}
        value={search}
        standalone
        onChange={(val: string) => setSearch(val)}
      />
      <Grid
        onGifClick={(gif, ev) => {
          ev.preventDefault();
          hidePopup();
          onSelect && onSelect(gif);
        }}
        key={search}
        width={percentageWidth(95)}
        columns={2}
        fetchGifs={(offset) => fetchGifs(offset)}
      />
    </ThemedContainer>
  );
}
