import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { useAppSelector } from '../hooks/store';
import CustomText from './Basic/CustomText';
import { ThemedIcon, ThemedText } from './Themed';

const NotificationButtonContainer = styled.TouchableOpacity`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  position: relative;
`;

const NotificationBubble = styled.View`
  position: absolute;
  background: ${(props) => props.theme.primary};
  right: 5px;
  top: 5px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationText = styled(ThemedText)`
  font-weight: bold;
  color: white;
`;

export default function Notifications() {
  const navigation = useNavigation();
  const notifications = useAppSelector((state) => state.Notification);

  const unreadNotifications = useMemo(
    () => notifications.filter((n) => !n.read),
    [notifications]
  );

  const goToNotifications = () => {
    navigation.navigate('LoggedIn', { screen: 'Notifications' });
  };

  return (
    <NotificationButtonContainer onPress={goToNotifications}>
      {unreadNotifications.length > 0 && (
        <NotificationBubble>
          <NotificationText>{unreadNotifications.length}</NotificationText>
        </NotificationBubble>
      )}
      <ThemedIcon type="FontAwesome" size={20} name={'bell'} />
    </NotificationButtonContainer>
  );
}
