import React from 'react';
import { View } from 'react-native';
import { FlatList, RefreshControl } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import CustomButton from './Basic/CustomButton';
import CustomCard from './Basic/CustomCard';
import CustomContainer from './Basic/CustomContainer';
import CustomFloatingButton from './Basic/CustomFloatingButton';
import CustomIcon from './Basic/CustomIcon';
import CustomImage from './Basic/CustomImage';
import CustomInput from './Basic/CustomInput';
import CustomText from './Basic/CustomText';
import CustomToggle from './Basic/CustomToggle';
import CustomView from './Basic/CustomView';
import { ListItem } from './Basic/ListItem';

export const ThemedFlatlist = styled(FlatList).attrs((props) => ({
  // @ts-ignore
  refreshControl: props.refreshControl ?? <RefreshControl refreshing={false} />,
  ItemSeparatorComponent: () => <View style={{ marginVertical: 5 }} />,
}))`
  padding: 5px;
` as typeof FlatList;

export const ThemedContainer = styled(CustomContainer)`
  padding: 5px;
` as typeof CustomContainer;

export const ThemedView = styled(CustomView)`
  background-color: ${(props) => props.theme.mainContainer};
` as typeof CustomView;

export const ThemedButton = styled(CustomButton).attrs((props) => {
  return {
    rounded: true,
    full: true,
    titleStyle: {
      fontFamily: props.theme.fontFamily,
      color: props.theme.text,
    },
    ...props,
  };
})`
  background-color: ${(props) => props.theme.button};
  border-color: ${(props) => (props.outline ? props.theme.button : undefined)};
` as typeof CustomButton;

export const ThemedIcon = styled(CustomIcon).attrs((props) => ({
  color: props.theme.icon,
  ...props,
}))`` as typeof CustomIcon;

export const ThemedToggle = styled(CustomToggle).attrs((props) => ({
  labelStyle: {
    color: props.theme.inputLabel,
  },
  activeBubbleColor: props.theme.primary,
}))`` as typeof CustomToggle;

export const ThemedTextInput = styled(CustomInput).attrs((props) => ({
  containerStyle: {
    backgroundColor: props.theme.inputContainer,
    padding: 10,
    borderRadius: 10,
    // @ts-ignore
    ...props.containerStyle,
  },
  placeolder: 'test',
  placeHolderTextColor: props.theme.inputPlaceholder,
  inputContainerStyle: { backgroundColor: props.theme.inputContainer },
  labelStyle: {
    color: props.theme.inputLabel,
    fontFamily: props.theme.fontFamily,
  },
  errorTextStyle: {
    fontFamily: props.theme.fontFamily,
    color: props.theme.note,
    fontSize: 12,
  },
  labelContainerStyle: { marginBottom: 10 },
  inputTextStyle: { color: props.theme.inputText },
}))`` as typeof CustomInput;

export const ThemedImage = styled(CustomImage).attrs((props) => ({
  loadingIndicatorColor: props.theme.primary,
  containerStyle: {
    borderRadius: 15,
    overflow: 'hidden',
  },
  onErrorUrl: `https://i.pravatar.cc/300?time=${new Date().getTime()}`,
  ...props,
}))`` as typeof CustomImage;

export const ThemedText = styled(CustomText)`
  color: ${(props) => (props.note ? props.theme.note : props.theme.text)};
  font-family: ${(props) => props.theme.fontFamily};
`;

export const ThemedListItem = styled(ListItem).attrs((props) => ({
  ...props,
}))`
  background: ${(props) => props.theme.listItem};
  border-radius: 10px;
  padding: 20px;
` as typeof ListItem;

export const ThemedCard = styled(CustomCard)`
  border-color: gray;
  overflow: hidden;
  background: ${(props) => props.theme.listItem};
  border-radius: 15px;
`;

export const ThemedFloatingButton = styled(CustomFloatingButton)`
  background: ${(props) => props.theme.button};
`;
