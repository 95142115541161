import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import {
  useIsFocused,
  useNavigation,
  useScrollToTop,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useRef } from 'react';
import { ListRenderItemInfo, RefreshControl } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { api } from '../api/baseApi';
import {
  MessageDto,
  useFindByUserIdFromChatControllerQuery,
} from '../api/chat';
import {
  NotificationDto,
  useFindAllFromNotificationControllerQuery,
} from '../api/notification';
import { PostDto, useLazyFindLatestFromPostControllerQuery } from '../api/post';
import {
  useFindBlockedUsersFromUserControllerQuery,
  useUpdateCoordinatesFromUserControllerMutation,
} from '../api/user';
import Post from '../components/Post';
import { ThemedFlatlist, ThemedImage, ThemedText } from '../components/Themed';
import { useAppSelector } from '../hooks/store';
import { useGeolocation } from '../hooks/useGeolocation';
import { useNotification } from '../hooks/useNotification';
import { useTabbarToggle } from '../hooks/useTabbarToggle';
import useTheme from '../hooks/useTheme';
import { addMessage } from '../store/reducers/Chat';
import { addNotification } from '../store/reducers/Notification';

const Separator = styled.View`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const NewPostContainer = styled.TouchableOpacity`
  border-color: gray;
  overflow: hidden;
  background: ${(props) => props.theme.listItem};
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
`;

const NewPostHeader = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export default function Home() {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const { handleScroll } = useTabbarToggle();

  const { refetch: fetchChats } = useFindByUserIdFromChatControllerQuery();
  const [updateCoordinates] = useUpdateCoordinatesFromUserControllerMutation();

  const { location } = useGeolocation();

  const { refetch: fetchBlockedUsers } =
    useFindBlockedUsersFromUserControllerQuery();

  const [fetchPosts, { isLoading }] = useLazyFindLatestFromPostControllerQuery({
    refetchOnFocus: true,
  });

  useFindAllFromNotificationControllerQuery();

  useEffect(() => {
    if (location) {
      updateCoordinates({ coordinates: location.coords });
    }
  }, [location]);

  const posts = useAppSelector((state) => state.Post);
  const chats = useAppSelector((state) => state.Chat);
  const { palette } = useTheme();

  const tabBarHeight = useBottomTabBarHeight();

  useEffect(() => {
    if (isFocused) {
      dispatch(api.util.resetApiState());
      fetchPosts('', false).unwrap().then(console.log);
      fetchChats();
      fetchBlockedUsers();
    }
  }, [isFocused]);

  useScrollToTop(ref);

  const handleNewMessageReceived = useCallback(
    (message: MessageDto) => {
      const chat = chats?.find((chat) => chat.uuid === message.chatUuid);
      if (!chat) {
        fetchChats();
        return;
      }

      dispatch(addMessage(message));
    },
    [chats]
  );

  const handleNewNotificationReceived = useCallback(
    (data: NotificationDto) => {
      if (data.type === 'MESSAGE') {
        handleNewMessageReceived(data.message!);
        return;
      }
      dispatch(addNotification(data));
    },
    [handleNewMessageReceived]
  );

  const refreshPosts = useCallback(async () => {
    await fetchPosts('').unwrap();
  }, [fetchPosts]);

  const loadMore = useCallback(() => {
    const lastPost = posts[posts.length - 1];
    if (lastPost) {
      fetchPosts(lastPost.uuid);
    }
  }, [posts]);

  useNotification(handleNewNotificationReceived);

  const EmptyListComponent = () => {
    return <ThemedText>No posts found</ThemedText>;
  };

  return (
    <ThemedFlatlist
      onScroll={handleScroll}
      testID={'postsList'}
      refreshing={true}
      onEndReachedThreshold={0.5}
      onEndReached={loadMore}
      refreshControl={
        <RefreshControl onRefresh={refreshPosts} refreshing={isLoading} />
      }
      ListEmptyComponent={EmptyListComponent}
      ListHeaderComponent={RenderNewPost}
      contentContainerStyle={{ paddingBottom: tabBarHeight + 20 }}
      ref={ref}
      ItemSeparatorComponent={Separator}
      keyExtractor={(item) => `post${item.uuid}`}
      renderItem={(post: ListRenderItemInfo<PostDto>) => (
        <Post post={post.item} />
      )}
      data={posts}
    />
  );
}

const RenderNewPost = () => {
  const navigation = useNavigation();
  const { avatar } = useAppSelector((state) => state.Auth);

  const showNewPost = () => {
    navigation.navigate('LoggedIn', { screen: 'NewPost' });
  };

  return (
    <>
      <NewPostContainer testID={'newPostButton'} onPress={showNewPost}>
        <NewPostHeader>
          <ThemedImage avatar uri={avatar} />
          <ThemedText note style={{ marginLeft: 10 }}>
            Write something...
          </ThemedText>
        </NewPostHeader>
      </NewPostContainer>
    </>
  );
};
